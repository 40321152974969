import { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import ModalWrapper from "./ModalWrapper";

type Props = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  wrapperId?: string;
};

const BaseModal = (props: Props) => {
  const { show, children } = props;
  const nodeRef = useRef(null);

  return (
    <ModalWrapper {...props}>
      <CSSTransition
        in={show}
        appear
        timeout={500}
        unmountOnExit
        classNames={{
          enter: "animate__animated animate__zoomInUp animate__faster",
          appear: "animate__animated animate__zoomInUp animate__faster",
          exit: "animate__animated animate__zoomOutDown animate__faster"
        }}
        nodeRef={nodeRef}
      >
        <div className="h-full m-auto flex justify-center items-center" ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </ModalWrapper>
  );
};

export default BaseModal;
