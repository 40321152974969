import { useEffect, useState } from "react";
import { SpinnerLoader } from "@/components/Loaders";
import { ConnectionLost } from "@/services/socket-io/components/ConnectionLost";
import { Message } from "@/models/chat";
import { useSocketIO, useSocketIOEvent } from "@/services/socket-io";
import { ChatAvatar } from "./ChatAvatar";
import { MessageHeader } from "./MessageHeader";
import { ChatBubble } from "./ChatBubble";
import { MessageImages } from "./MessageImages";
import { MessageButtons } from "./MessageButtons";
import { AudioMessage } from "./AudioMessage";
import styles from "../chat.module.css";

export const ChatMessages = ({
  messages,
  isLoading
}: {
  messages: Message[];
  isLoading: boolean;
}) => {
  const { isConnected } = useSocketIO();
  const [visible, setVisible] = useState(!isConnected);
  useEffect(() => {
    setVisible(!isConnected);
    // connectionLost({ accept: () => window.location.reload(), isConnected });
  }, [isConnected]);

  useSocketIOEvent("conversation:update", () => {
    // eslint-disable-next-line no-console
    console.log("New Message");
  });

  return (
    <div className={`${styles.chat_messages_container}`}>
      <ConnectionLost isConnected={visible} />
      {isLoading && <SpinnerLoader />}
      {messages
        .map((message) => {
          const createdDate = new Date(message.createdAt);
          return (
            <div
              key={message._id}
              className={`chat ${message.direction === "incoming" ? "chat-start" : "chat-end"}`}
            >
              <ChatAvatar message={message} />
              <MessageHeader>
                <p className={`${styles.message_date} py-1 px-2`}>
                  {createdDate.toLocaleDateString("en-UK")}
                </p>
              </MessageHeader>
              <ChatBubble direction={message.direction}>
                <p style={{ fontSize: 12 }}>{message.text}</p>
                {message.audio && <AudioMessage source={message.audio} />}
                {message.buttons.length > 0 && <MessageButtons buttons={message.buttons} />}
                {message.images && <MessageImages images={message.images} />}
                <div className={`${styles.message_date}`}>
                  {createdDate.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                </div>
              </ChatBubble>
            </div>
          );
        })
        /** In order to handle the scrolling from bottom to top as it's the common behavoir in chats,
         * we first reversed all the chat list components and then the css style of column-reverse
         * will handle the scrolling from bottom to top of the conversation
         * (check `chat_container` css class)
         */
        .reverse()}
    </div>
  );
};
