import { useQueryClient } from "react-query";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "@/hooks/store-hooks";
import { SocketProvider } from "@/services/socket-io";
import { Layout } from "@/layout";

const VALID_JWT_TOKEN_REGEX = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export const GuardRoute = () => {
  const jwtToken = useAppSelector((state) => state.auth.token);
  const queryClient = useQueryClient();

  if (!jwtToken || !VALID_JWT_TOKEN_REGEX.test(jwtToken)) {
    queryClient.clear();
    return <Navigate to="/auth/signin" replace />;
  }

  return (
    <SocketProvider>
      <Layout>
        <Outlet />
      </Layout>
    </SocketProvider>
  );
};

export default GuardRoute;
