import { useTab } from "../context/TabContext";
import { TabItem } from "../types";

export const TabBody = ({ tab }: { tab: TabItem }) => {
  const { activeTab } = useTab();
  return (
    <div className={`${activeTab.order === tab.order ? "block" : "hidden"}`}>
      <div className="tab-content">
        <div className="tab-pane fade show active">{tab.element}</div>
      </div>
    </div>
  );
};
