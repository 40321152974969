import styles from "../chat.module.css";

type _MessageButtonsProps = {
  buttons: string[];
};

export const MessageButtons = ({ buttons }: _MessageButtonsProps) => (
  <div className="flex flex-col space-y-4 mt-4">
    {buttons.map((buttonText) => (
      <div key={buttonText} role="button" className={`${styles.chat_button_message}`}>
        {buttonText}
      </div>
    ))}
  </div>
);
