import { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T> | any;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps<T>>;

export const Toggle = <T extends FieldValues>({
  name,
  register,
  label,
  disabled,
  ...others
}: _InputProps<T>) => {
  const id = useId();

  return (
    <div className="flex items-center">
      <label htmlFor={id} className="relative cursor-pointer">
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          className="sr-only peer"
          {...register(name)}
          {...others}
        />
        <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600" />
      </label>
      <span className="ml-3 text-sm font-medium text-gray-900">{label}</span>
    </div>
  );
};
