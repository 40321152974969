/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { exposeToast } from "@/components/Toasts";
import { User } from "@/models/auth";
import { useVerifyMe } from "../../hooks";
import { verifyEmail } from "./EmailReVerifyModal";

type Props = { user: User };

export const Alert = ({ user }: Props) => {
  const { mutate: verifyMe } = useVerifyMe();

  return (
    <div>
      <div className="p-5 bg-yellow-300 rounded-md flex flex-row space-x-3 border border-black justify-center ">
        <img className=" w-5 " src="/img/icons/warning-icon.svg" alt="" />
        <div>
          Your email address is currently unverified. Please check your inbox for verification
          email.
          <span
            className=" text-blue-600 cursor-pointer"
            onClick={() => {
              verifyEmail({
                email: user.email,
                update: ({ email }) => {
                  verifyMe(
                    { email: user.email, newEmail: email },
                    {
                      onSuccess() {
                        exposeToast({
                          type: "success",
                          message: "Verification Email sent successfully"
                        });
                      },
                      onError() {
                        exposeToast({ type: "error", message: "Email already registered" });
                      }
                    }
                  );
                }
              });
            }}
          >
            Didnt recieve an email?
          </span>
        </div>
      </div>
    </div>
  );
};
