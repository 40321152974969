import React from "react";

type ButtonVariant =
  | "btn-wide"
  | "btn-block"
  | "btn-primary"
  | "btn-outline"
  | "btn-link"
  | "btn-active"
  | "btn-square"
  | "btn-circle"
  | "btn-primary"
  | "btn-secondary"
  | "btn-accent"
  | "btn-ghost";

export type ButtonBaseProps = {
  isLoading?: boolean;
  variant: ButtonVariant;
  extendVariant?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
};

export type ButtonProps<T extends React.ElementType> = React.PropsWithChildren<ButtonBaseProps> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonBaseProps>;

export const Button = <T extends React.ElementType = "button">({
  variant,
  extendVariant,
  isLoading = false,
  children,
  ...others
}: ButtonProps<T>) => (
  <button
    type="button"
    className={`btn ${variant} ${variant !== "btn-outline" ? "text-white" : ""} ${extendVariant} ${
      isLoading ? "loading" : ""
    }`}
    {...others}
  >
    {children}
  </button>
);
