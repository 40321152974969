import "chart.js/auto";
import { SocialPlatforms } from "@/models/constants";
import { StackedBarChart, PlatformsDoughnutChart, TrendChart } from "./components/Charts";
import { useMetrics } from "./hooks";

export const Dashboard = () => {
  const { metrics, isLoading } = useMetrics("year");

  return (
    <div className="px-10 ">
      <div className="flex space-x-10 ">
        <TrendChart
          title="Active users"
          isLoading={isLoading}
          data={metrics.map((metric) => metric.monthlyActiveUsers.total)}
        />
        <TrendChart
          title="Conversations"
          isLoading={isLoading}
          data={metrics.map((metric) => metric.activeConversations.total)}
        />
        <TrendChart
          title="Orders"
          isLoading={isLoading}
          data={metrics.map((metric) => metric.newOrders.total)}
        />
        <TrendChart
          title="Sales"
          isLoading={isLoading}
          data={metrics.map((metric) => metric.sales.total)}
        />
      </div>
      <div className="flex justify-between sm:justify-start space-x-0 sm:space-x-10 ">
        <StackedBarChart
          title="Sales by social platform (1,000 SDG)"
          days={metrics.map((m) => m.day)}
          isLoading={isLoading}
          data={Array.from(Object.values(SocialPlatforms), (platform) => {
            const sales = metrics.map((t) => t.sales);
            return {
              platform,
              values: sales.map((s) => Math.round(s[platform] / 1000))
            };
          })}
        />
        <PlatformsDoughnutChart
          title="Completed orders"
          isLoading={isLoading}
          data={Array.from(Object.values(SocialPlatforms), (platform) => {
            if (metrics.length > 0) {
              const trend = metrics[metrics.length - 1].confirmedOrders;
              return {
                platform,
                value: trend[platform]
              };
            }
            return {
              platform,
              value: 0
            };
          })}
        />
      </div>

      <div className="flex space-x-10 ">
        <StackedBarChart
          title="Active Conversations by platform"
          days={metrics.map((m) => m.day)}
          isLoading={isLoading}
          data={Array.from(Object.values(SocialPlatforms), (platform) => {
            const conversations = metrics.map((m) => m.activeConversations);
            return {
              platform,
              values: conversations.map((c) => c[platform])
            };
          })}
        />
        <PlatformsDoughnutChart
          title="Active users"
          isLoading={isLoading}
          data={Array.from(Object.values(SocialPlatforms), (platform) => {
            if (metrics.length > 0) {
              const trend = metrics[metrics.length - 1].activeUsers;
              return {
                platform,
                value: trend[platform]
              };
            }
            return {
              platform,
              value: 0
            };
          })}
        />
      </div>
    </div>
  );
};
