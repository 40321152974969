/* eslint-disable  */
import React, { useRef } from "react";
import { exposeToast } from "../Toasts";
import { FileValidator } from "./validators/FileValidator";
import { imageFileValidator } from "./validators/chatFilesValidators";

const ACCEPTED_IMAGE_FILE_TYPES = ["image/png", "image/jpeg", "image/gif"];

type _ChatAttachmentsProps = {
  onImagesChanged: (images: File[]) => void;
};

export const ChatAttachments = ({ onImagesChanged }: _ChatAttachmentsProps) => {
  const uploadInputRef = useRef<null | HTMLInputElement>(null);

  const onUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = [...e.target.files!];

    /* eslint-disable  */
    const fileValidator = uploadedFiles.map((file) =>
      new FileValidator(file).chain((file) =>
        imageFileValidator(file, {
          mimeTypes: ACCEPTED_IMAGE_FILE_TYPES,
          onFail: () => exposeToast({ type: "error", message: "hghghgh" })
        })
      )
    );

    if (fileValidator.includes(null)) {
      e.target.value = "";
      return;
    }

    onImagesChanged(uploadedFiles);

    // clear-out the cached file value so we can upload the same file again
    e.target.value = "";
  };

  return (
    <div>
      <input
        className="hidden"
        ref={uploadInputRef}
        type="file"
        onChange={onUploadFile}
        accept="image/*"
        multiple
      />
      <img
        role="button"
        className="h-6 w-6"
        src="/img/icons/paperclip-solid.svg"
        alt="send_icon"
        onClick={() => {
          uploadInputRef.current?.click();
        }}
      />
    </div>
  );
};
