import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { CatalogNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const CatalogNodeForm = () => {
  const { register } = useFormContext<CatalogNodeData>();

  return (
    <NodeFormWrapper description="This node shows the user all of your product catalog.">
      <TextField name="text" register={register} label="Message" />
      <TextField name="textAR" register={register} label="الرسالة" isRtl />
    </NodeFormWrapper>
  );
};
