import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactPortal } from "@/components/ReactPortal";

type Props = {
  children: React.ReactElement;
  wrapperId?: string;
  show: boolean;
};

export const ToastWrapper = ({ show, children, wrapperId }: Props) => {
  const nodeRef = useRef(null);

  return (
    <ReactPortal wrapperId={wrapperId || "react-wrapper-portal"}>
      <CSSTransition
        in={show}
        appear
        unmountOnExit
        timeout={1000}
        classNames={{
          enter: "animate__animated animate__bounceInDown",
          appear: "animate__animated animate__bounceInDown",
          exit: "animate__animated animate__bounceOutUp"
        }}
        nodeRef={nodeRef}
      >
        <div className="fixed top-0 right-0 left-0 z-[900000000000000]" ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};
