import { NodeProps, Position, useReactFlow } from "react-flow-renderer";
import { UserInputNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { UserInputNodeForm } from "../DialogForms";
import { UserInputFormValidationObject } from "../DialogForms/validators";

export const UserInputNodeTypes = {
  name: "User name",
  phone: "Phone number",
  email: "E-mail",
  location: "User location",
  custom: "Custom"
};

const DragElement = <BaseNode title="Ask User" color="#444655" isDragElement />;

const NodeComponent = ({ data, ...rest }: NodeProps<UserInputNodeData>) => {
  const reactFlow = useReactFlow();
  const { questionType } = data;
  return (
    <>
      <BaseHandle
        type="target"
        position={Position.Top}
        isValidConnection={({ source }) => {
          const node = reactFlow.getNode(source!);
          return node?.type !== "choices";
        }}
      />
      <BaseNode
        title={`Ask for ${UserInputNodeTypes[questionType]}`}
        color="#444655"
        icon="edit-pen.svg"
        data={data}
        form={<UserInputNodeForm />}
        validationObject={UserInputFormValidationObject}
        {...rest}
      />
      <BaseHandle type="source" position={Position.Bottom} />
    </>
  );
};

export const UserInputNode: NodeType = {
  type: "userInput",
  component: NodeComponent,
  dragElement: DragElement
};
