import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import type { CustomAxiosRequestConfig } from "axios-auth-refresh/dist/utils";
import { authActions } from "@/store/reducers/auth.reducer";
import { store } from "../../store/root";
import { HttpClient } from "./HttpClient";
import { AppConfig } from "../config";

export class HttpClientFactory {
  // eslint-disable-next-line no-empty-function
  constructor(private configs: AppConfig) {}

  create(): HttpClient {
    const instance = axios.create({
      baseURL: this.configs.platformUri
    });

    instance.interceptors.request.use((config) => {
      const {
        auth: { token }
      } = store.getState();

      if (token && config.headers) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.set("Access-Control-Allow-Origin", "*");
      }

      return config;
    });

    createAuthRefreshInterceptor(instance, async () => {
      // @ts-ignore
      const customAxiosRequestConfig: CustomAxiosRequestConfig = {
        skipAuthRefresh: true,
        withCredentials: true
      };

      try {
        const { data } = await instance.post<{ token: string }>(
          "/auth/token",
          undefined,
          customAxiosRequestConfig
        );
        store.dispatch(authActions.updateToken(data.token));
      } catch (error) {
        // @ts-ignore
        if (error.response?.status !== 401) throw error;

        store.dispatch(authActions.signOut());
        window.location.href = "/auth/signin";
      }
    });

    return instance;
  }
}
