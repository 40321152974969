import React, { PropsWithChildren } from "react";

type _RemoveSentenceProps = {
  handleRemove: React.MouseEventHandler<HTMLDivElement>;
};

export const SetSentenceWrapper = ({
  handleRemove,
  children
}: PropsWithChildren<_RemoveSentenceProps>) => (
  <div className="flex items-center w-full space-x-4">
    <div className="grow">{children}</div>
    {/* eslint-disable-next-line */}
    <div className="cursor-pointer p-2 rounded-md hover:bg-red-100" onClick={handleRemove}>
      <img className="w-5" src="/img/icons/circle-minus-solid.svg" alt="delete-sentence-icon" />
    </div>
  </div>
);
