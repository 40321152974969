import { showImagesCarousel } from "@/components/Carousel";

type _MessageImagesProps = {
  images: string[];
};

export const MessageImages = ({ images }: _MessageImagesProps) => (
  // eslint-disable-next-line
  <div
    role="button"
    onClick={() => {
      showImagesCarousel({ images });
    }}
  >
    {images.map((image) => (
      <img
        key={image}
        className="flex justify-center my-2 w-64 rounded-lg object-cover"
        src={image}
        alt="img-chat"
      />
    ))}
  </div>
);
