export const AnimatedCustomers = () => (
  <div className=" w-full flex flex-row flex-wrap  p-10">
    {Array.from([1, 2, 3, 4, 5]).map((n) => (
      <div key={n} className=" max-w-96 pl-5 pt-5">
        <div className=" flex flex-col space-y-3 items-center p-10">
          <div className="skeleton w-[42px] h-[42px] rounded-full" />
          <div className="skeleton w-24 h-3" />
          <div className="skeleton w-32 h-3" />
          <div className="skeleton w-32 h-3" />
          <div className="skeleton w-24 h-3" />
        </div>
      </div>
    ))}
  </div>
);
