import { useState } from "react";
import { TextFieldWithIcon } from "@/components/Forms";
import { Card } from "@/components/Cards";
import { Button } from "@/components/Buttons";
import { exposeToast } from "@/components/Toasts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useResetRequest } from "./hooks/useVerify";
import { EmailValidation } from "./utils/EmailValidation";

export const ResetRequest = () => {
  const [success, setSuccess] = useState(false);
  const { mutate: request, isLoading } = useResetRequest();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>({
    defaultValues: { email: "" },
    resolver: yupResolver(EmailValidation())
  });

  const onSubmit = (data: { email: string }) => {
    request(data, {
      onSuccess() {
        setSuccess(true);
      },
      onError() {
        exposeToast({ type: "error", message: "Email Not Recognized!" });
      }
    });

    //  request()
  };
  return (
    <div className=" flex  gap-5 items-center justify-center p-32 max-w-52">
      <Card>
        <div className=" flex flex-col items-center p-8 space-y-6">
          <div className="w-32">
            <img src="/img/enigma.png" alt="enigma-ai" />
          </div>
          {!success ? (
            <>
              <div className=" w-full">
                <TextFieldWithIcon
                  name="email"
                  icon="envelope.svg"
                  label="Email"
                  placeholder="user@example.com"
                  register={register}
                  error={errors.email?.message}
                />
              </div>
              <Button
                isLoading={isLoading}
                variant="btn-block"
                onClick={() => handleSubmit(onSubmit)()}
              >
                SUBMIT
              </Button>
            </>
          ) : (
            <div className="text-center">
              <h1>Check your Inbox</h1>
              We sent a link to your email address
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
