/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { Order } from "@/models/orders";
import { Table, TableItem } from "@/components/Tables";

type CategoryEditeProps = {
  show: boolean;
  orders: Order[];
  click: (id: string) => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const Orders = ({ orders, show, click, onClose, ...rest }: CategoryEditeProps) => {
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };
  return (
    <BaseDialog
      title="Customer Orders"
      show={visible}
      body={
        <div className="p-10">
          <Table
            headers={[
              "Status",
              "Customer Name",
              "Platform",
              "delivery",
              "Total(SDG)",
              "Payment Method"
            ]}
          >
            {orders?.map((order, index) => (
              <tr
                className={`border-b border-gray-200 w-full hover:bg-gray-100 ${
                  index % 2 === 0 ? "bg-gray-50" : ""
                } cursor-pointer`}
                onClick={() => {
                  click(order._id);
                  close();
                }}
                key={order._id}
              >
                <TableItem>
                  <span
                    className={`  py-1 px-3 rounded-full text-xs ${
                      order.status === "new"
                        ? " text-blue-500 bg-blue-200"
                        : order.status === "confirmed"
                        ? "text-green-600 bg-green-200"
                        : " text-red-500 bg-red-200"
                    } `}
                  >
                    {order.status}
                  </span>
                </TableItem>
                <TableItem>{order.customer.name}</TableItem>
                <TableItem>
                  <div className=" ml-3">
                    {order?.platform === "whatsapp" ? (
                      <img className=" w-7" src="/img/icons/whatsapp-icon.svg" alt="whatsapp" />
                    ) : order?.platform === "facebook" ? (
                      <img
                        className="w-7   rounded-md"
                        src="/img/icons/facebook-blue-icon.svg"
                        alt="facebook"
                      />
                    ) : order.platform === "instagram" ? (
                      <img className="w-7" src="/img/icons/instagram-icon.svg" alt="instagram" />
                    ) : (
                      <img className="w-7" src="/img/order-icons/cart.svg" alt="manual" />
                    )}
                  </div>
                </TableItem>

                <TableItem>{order.delivery ? order.delivery.name : "Not Enabled"}</TableItem>

                <TableItem>
                  <div className=" ml-3">{order.totalPrice}</div>
                </TableItem>

                <TableItem>{order.paymentMethod}</TableItem>
              </tr>
            ))}
          </Table>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const orders = ({
  orders,
  cancel,
  onClick
}: {
  orders: Order[];
  cancel?: () => void;
  onClick: (id: string) => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "category-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <Orders
          show
          orders={orders}
          click={(id) => {
            onClick(id);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
