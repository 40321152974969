/* eslint-disable */
import * as uuid from "uuid";
import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Node,
  ReactFlowInstance,
  addEdge,
  useEdgesState,
  useNodesState
} from "react-flow-renderer";
import { FlowElementType, LoadingNodeData } from "@/models/flow";
import { useAppSelector } from "@/hooks";
import { BreadCrumb } from "@/components/BreadCrumb";
import { NodeTypes } from "./Nodes";
import { useAddNewEdge, useAddNewNode, useUpdateNodePosition } from "../hooks/flow-hooks";
import { ConnectionLine } from "./ConnectionLine";
import { Panel } from "./Panel";
import styles from "./flow.module.css";

type _BotFlowMapProps = {};

export const FlowMap = ({}: _BotFlowMapProps) => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();

  const { nodes: storeNodes, edges: storeEdges } = useAppSelector((state) => state.flow);
  const [nodes, setNodes, onNodesChange] = useNodesState(storeNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(storeEdges);

  const { mutate: addNewNode, isLoading: isAddingNode } = useAddNewNode();
  const { mutate: addNewEdge, isLoading: isAddingEdge } = useAddNewEdge();
  const { mutate: updateNodePosition, isLoading: isUpdatingPosition } = useUpdateNodePosition();

  const isLoading = isAddingNode || isAddingEdge || isUpdatingPosition;

  useEffect(() => {
    setNodes(storeNodes);
    setEdges(storeEdges.map((edge) => ({ ...edge, type: "simplebezier", animated: true })));
  }, [setNodes, storeNodes, setEdges, storeEdges]);

  const onInit = (instance: ReactFlowInstance) => setReactFlowInstance(instance);

  const onConnect = useCallback(
    async (connection: Connection) => {
      const { source, target } = connection;
      if (source && target) {
        setEdges((state) => addEdge({ ...connection, animated: true }, state));
        addNewEdge({ source, target });
      }
    },
    [setEdges]
  );

  const onNodeDragStop = useCallback(
    async (moved: Node) => {
      setNodes((state) => {
        const index = state.findIndex((node) => node.id === moved.id);
        if (index !== -1) {
          const nextState = Array.from(state);
          nextState.splice(index, 1, {
            ...nextState[index],
            position: moved.position
          });
          return nextState;
        }
        return state;
      });
      updateNodePosition(moved);
    },
    [setNodes]
  );

  const onDrop = useCallback(
    async (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow") as FlowElementType;
      if (typeof type === "undefined" || !type || !reactFlowInstance || !reactFlowBounds) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top
      });

      const node: Node<LoadingNodeData> = {
        id: uuid.v4(),
        type: "loading",
        position,
        data: { type }
      };
      setNodes((state) => [...state, node]);
      addNewNode({ type, position });
    },
    [reactFlowInstance]
  );
  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // eslint-disable-next-line
    event.dataTransfer.dropEffect = "move";
  }, []);

  return (
    <div className={`${styles.flow_container}`} ref={reactFlowWrapper}>
      <BreadCrumb
        items={[
          {
            text: "Bot Flow",
            isActive: true
          }
        ]}
      />
      <ReactFlow
        nodeTypes={NodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        connectionLineComponent={ConnectionLine}
        onInit={onInit}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onNodeDragStop={(_e, moved) => onNodeDragStop(moved)}
        onConnect={onConnect}
        nodesDraggable={!isLoading}
        fitView
      >
        <Background variant={BackgroundVariant.Dots} gap={24} size={0.5} />
        <Controls showInteractive />
        <Panel />
      </ReactFlow>
    </div>
  );
};
