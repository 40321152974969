import React, { useEffect, useState } from "react";
import { TabItem } from "../types";

interface TabContextType {
  handleTabChange: (order: number) => void;
  items: TabItem[];
  activeTab: TabItem;
}

const TabContext = React.createContext<TabContextType>({} as TabContextType);

export const TabProvider = ({ children, items }: { children: JSX.Element; items: TabItem[] }) => {
  const [selectedTab, toggleSelectedTab] = useState<TabItem>(
    items.find((item) => item.order === 1)!
  );

  useEffect(() => {
    const newItem = items.find((item) => item.order === selectedTab.order)!;
    toggleSelectedTab(newItem);
  }, [items]);

  const handleTabChange = (order: number) => {
    if (selectedTab.order === order) return;
    const newSelectedTab = items.find((item) => item.order === order)!;
    toggleSelectedTab(newSelectedTab);
  };
  const value = React.useMemo(
    () => ({ items, handleTabChange, activeTab: selectedTab }),
    [selectedTab]
  );

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

export const useTab = () => {
  const c = React.useContext(TabContext);
  if (c === undefined) {
    throw new Error("useTab must be inside tabContext provider");
  }
  return c;
};
