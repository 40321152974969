import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { MessageNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const MessageNodeForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<MessageNodeData>();

  return (
    <NodeFormWrapper description="Sends a customized message to the customer.">
      <TextField
        name="label"
        register={register}
        label="Button Title"
        error={errors.label?.message}
      />
      <TextField
        isRtl
        name="labelAR"
        register={register}
        label="اسم الزر"
        error={errors.labelAR?.message}
      />
      <TextField name="text" register={register} label="Message" error={errors.text?.message} />
      <TextField
        isRtl
        name="textAR"
        register={register}
        label="الرسالة"
        error={errors.textAR?.message}
      />
    </NodeFormWrapper>
  );
};
