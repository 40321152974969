import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import {
  IFlowElement,
  IFlowElements,
  FlowElement,
  RootElement,
  normalizeElement,
  INodeElement,
  INodePosition,
  IEdgeElement,
  INodesDataTypes,
  MediaNodeTypes
} from "@/models/flow";
import { IFlowElementApi } from "./IFlowElementApi";

@injectable()
export class FlowElementApi implements IFlowElementApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/element";

  async getAllElements(): Promise<FlowElement[]> {
    const {
      data: { elements }
    } = await this.client.get<IFlowElements>(this.baseUrl);
    return elements.map((e) => normalizeElement(e));
  }

  async getRoot(): Promise<RootElement[]> {
    throw new Error("Method not implemented.");
  }

  async getElementById(id: string): Promise<FlowElement> {
    const { data } = await this.client.get<IFlowElement>(`${this.baseUrl}/${id}`);
    return normalizeElement(data);
  }

  async addNode({ data, type, position }: INodeElement): Promise<INodeElement> {
    const { data: result } = await this.client.post<IFlowElement>(this.baseUrl, {
      data,
      type,
      position
    });
    return normalizeElement(result);
  }

  async addEdge({ type = "edge", source, target }: IEdgeElement): Promise<IEdgeElement> {
    const { data: result } = await this.client.post<IFlowElement>(this.baseUrl, {
      type,
      source,
      target
    });
    return normalizeElement(result);
  }

  async updateNodePosition(nodeId: string, position: INodePosition): Promise<INodeElement> {
    const { data } = await this.client.patch<IFlowElement>(`${this.baseUrl}/${nodeId}`, {
      position
    });
    return normalizeElement(data);
  }

  async deleteElement(elementId: string): Promise<void> {
    await this.client.delete(`${this.baseUrl}/${elementId}`);
  }

  async disconnectNode(elementId: string): Promise<IEdgeElement[]> {
    const { data } = await this.client.post<IFlowElement[]>(
      `${this.baseUrl}/${elementId}/disconnect`
    );
    return data.map((e) => normalizeElement(e));
  }

  async updateElement(
    elementId: string,
    { exampleSetData, exampleSetMethod, ...updates }: INodesDataTypes
  ): Promise<INodeElement> {
    const { data } = await this.client.patch<IFlowElement>(`${this.baseUrl}/${elementId}`, {
      exampleSetData,
      exampleSetMethod,
      data: { id: elementId, ...updates }
    });
    return normalizeElement(data);
  }

  async updateElementMedia(
    elementId: string,
    mediaType: MediaNodeTypes,
    media: File
  ): Promise<INodeElement> {
    const data = new FormData();
    data.append("mediaType", mediaType);
    data.append("media", media, media.name);

    const { data: updatedElement } = await this.client.patch<INodeElement>(
      `${this.baseUrl}/${elementId}/media`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );

    return updatedElement;
  }
}
