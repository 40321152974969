export enum SERVICES_TYPES {
  CONFIGURATIONS = "CONFIGURATIONS",
  HTTP_CLIENT = "HTTP_CLIENT",
  STORAGE = "STORAGE",
  STORE = "STORE",
  AUTH = "AUTH",
  USER = "USER",
  METRIC = "METRIC",
  NOTIFICATIONS = "NOTIFICATIONS",
  FLOW_ELEMENT = "FLOW_ELEMENT",
  EXAMPLE_SET = "EXAMPLE_SET",
  CONVERSATIONS = "CONVERSATIONS",
  MESSAGES = "MESSAGES",
  CATEGORIES = "CATEGORIES",
  ORDERS = "ORDERS",
  PRODUCTS = "PRODUCTS",
  VERIFY = "VERIFY",
  CUSTOMERS = "CUSTOMERS"
}
