export const ChatWarningToast = () => (
  <div
    className="alert alert-warning shadow-lg w-auto bg-[#FFBD5B] h-[50px]"
    style={{
      margin: "20px 30px 0px 30px"
    }}
  >
    <div>
      <img className="w-6" src="/img/icons/warning-icon.svg" alt="toast-warning" />
      <span>Can`t send due to 24 hours time limit, please inform client to contact you.</span>
    </div>
  </div>
);
