import { Customer } from "@/models/customers";
import React from "react";

type Props = {
  customer: Customer;
};

export const ContactSections = ({ customer }: Props) => (
  <div className="flex flex-col items-center ">
    <div className="flex flex-row space-x-3 py-3 text-xs text-gray-500">
      <span className="flex flex-row   ">
        <img className="w-5" src="/img/order-icons/phone.svg" alt="." />
        {customer.phone ? customer.phone : "Not Available"}
      </span>
      <span className="flex flex-row ">
        <img className="w-5" src="/img/icons/dollar.svg" alt="." />
        {customer.paymentMethod ? customer.paymentMethod : "Not Available"}
      </span>{" "}
    </div>
    <div className="text-[10px] flex-col flex items-center space-y-1 text-gray-500">
      <span className="flex flex-row  flex-wrap items-center ">
        <img className="w-5" src="/img/order-icons/email.svg" alt="." />
        <div className=" flex flex-wrap ">{customer.email ? customer.email : "Not Available"}</div>
      </span>
      <span className="flex flex-row  flex-wrap items-center ">
        <img className="w-5" src="/img/icons/office-building.svg" alt="." />
        {customer.location ? customer.location : "Not Available"}
      </span>
    </div>
  </div>
);
