import { IConversationsApi, IMessagesApi } from "@/api/chat";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { MessageReplayPayload } from "@/models/chat";
import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { useChat } from "../context";

export const useConversations = (query: string) => {
  const conversationsApi = useInjection<IConversationsApi>(SERVICES_TYPES.CONVERSATIONS);

  return useInfiniteQuery(
    ["conversation", query],
    ({ pageParam = 0 }) => conversationsApi.getConversations(pageParam, query),
    {
      getNextPageParam: (lastPage, pages) => {
        // TODO: (Asim) double check page size and why its not implemented in the backend
        if (lastPage.length < 10) return undefined;
        return pages.length * 10;
      }
    }
  );
};

export const useConversation = (chatId: string) => {
  const conversationsApi = useInjection<IConversationsApi>(SERVICES_TYPES.CONVERSATIONS);

  return useQuery(`conversation-${chatId}`, () => conversationsApi.getConversationById(chatId), {});
};

export const useMessages = (conversationId: string = "") => {
  const messagesApi = useInjection<IMessagesApi>(SERVICES_TYPES.MESSAGES);

  return useQuery(`conversation-messages-${conversationId}`, () =>
    messagesApi.getMessages(conversationId)
  );
};

export const useMessageReplay = () => {
  const messagesApi = useInjection<IMessagesApi>(SERVICES_TYPES.MESSAGES);
  const { chat } = useChat();

  return useMutation<any, any, MessageReplayPayload>("message-replay", (payload) =>
    messagesApi.sendReplay(chat?._id ?? "", payload)
  );
};

export const useConversationToggle = () => {
  const conversationsApi = useInjection<IConversationsApi>(SERVICES_TYPES.CONVERSATIONS);
  const { chat } = useChat();

  return useMutation("conversation-toggle", () =>
    conversationsApi.toggleConversationStatus(chat?._id ?? "")
  );
};
