import React, { useRef, RefObject } from "react";
import { useOutsideAlerter } from "@/hooks/useOutsideAlerter";

interface LayoutContextType {
  /** This state value shows if the sidebar is expanded or not */
  isExpanded: boolean;
  sidebarRef: RefObject<HTMLDivElement>;
  handleSidebarExpansion: (expand?: boolean) => void;
}

const LayoutContext = React.createContext<LayoutContextType>({} as LayoutContextType);

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const { show, setShow } = useOutsideAlerter(sidebarRef, false);

  const handleSidebarExpansion = (expand?: boolean) => setShow(expand ?? !show);

  //   /// CHANGE LANGUAGE
  //   const lang = useAppSelector((state) => state.lang);

  //   useEffect(() => {
  //     const html = document.getElementsByTagName("html")[0] as HTMLElement;
  //     const dir = lang === "en" ? "ltr" : "rtl";
  //     html.setAttribute("dir", dir);
  //   }, [lang]);

  const value = React.useMemo(
    () => ({
      isExpanded: show,
      sidebarRef,
      handleSidebarExpansion
    }),
    [show]
  );

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayout = () => {
  const c = React.useContext(LayoutContext);
  if (c === undefined) {
    throw new Error("useLayout must be inside layoutContext provider");
  }
  return c;
};
