import * as yup from "yup";

export const AddProductValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    arabicName: yup
      .string()
      .required()
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    desc: yup.string().required().min(5, "Minimum length 5 chars"),
    arabicDesc: yup.string().required().min(5, "Minimum length 5 chars"),
    price: yup.number().required().min(10, "Must be more than 10 SDG"),
    url: yup.string().url("Invalid url")
  });
