import { useRef } from "react";
import { useAppSelector, useOutsideAlerter } from "@/hooks";
import { PlatformItem } from "./PlatformItem";
import {
  useFacebook,
  useFacebookDisconnect,
  useInstagram,
  useInstagramDisconnect,
  useWhatsapp
} from "../../hooks/platforms-hooks";
import styles from "./platforms-menu.module.css";

export const PlatformsMenu = () => {
  const menuRef = useRef(null);
  const { show, setShow } = useOutsideAlerter<HTMLDivElement>(menuRef, false);

  const { facebook, instagram, whatsapp } = useAppSelector((state) => state.auth.user!);

  const { connect: connectToFacebook } = useFacebook();
  const { connect: connectToInstagram } = useInstagram();
  const { connect: connectToWhatsapp } = useWhatsapp();
  const { mutate: disconnectFacebook } = useFacebookDisconnect();
  const { mutate: disconnectInstagram } = useInstagramDisconnect();
  return (
    <div ref={menuRef}>
      {/* eslint-disable-next-line */}
      <div className="cursor-pointer" onClick={() => setShow(!show)}>
        <img className="w-7" src="/img/icons/social-media-icon.svg" alt="social_pages" />
      </div>
      <div className={`${show ? "block" : "hidden"} ${styles.platfroms_menu_container}`}>
        <PlatformItem
          item={{
            name: facebook ? facebook.name : "Link page",
            isConnected: !!facebook
          }}
          color="#3b5998"
          iconName="facebook-white-icon.svg"
          onClick={() => {
            if (facebook) disconnectFacebook();
            else connectToFacebook();
          }}
        />
        <PlatformItem
          item={{
            name: instagram ? instagram.username : "Link page",
            isConnected: !!instagram
          }}
          color="#ff826f"
          iconName="instagram-white-icon.svg"
          onClick={() => {
            if (instagram) disconnectInstagram();
            else connectToInstagram();
          }}
        />
        <PlatformItem
          item={{
            name: whatsapp ? whatsapp.id : "Link number",
            isConnected: !!whatsapp
          }}
          color="#22c197"
          iconName="whatsapp-white-icon.svg"
          onClick={() => {
            connectToWhatsapp();
          }}
        />
      </div>
    </div>
  );
};
