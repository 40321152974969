import { useForm } from "react-hook-form";
import { Toggle } from "@/components/Forms";
import { useEffect } from "react";
import { useConversationToggle } from "@/pages/LiveChat/hooks/chat-hooks";
import { Conversation } from "@/models/chat";

export const BotStatusSwitcher = ({ chat }: { chat: Conversation }) => {
  const { register, setValue } = useForm<{ botIsPaused: boolean }>({
    defaultValues: { botIsPaused: chat.paused }
  });

  const { mutate: toggleBotStatus, data } = useConversationToggle();

  if (data && data._id === chat._id) setValue("botIsPaused", data.paused);

  useEffect(() => {
    setValue("botIsPaused", chat.paused);
  }, [chat]);

  return (
    <div className="m-3">
      <Toggle
        name="botIsPaused"
        register={register}
        label="Pause Bot"
        onClick={() => {
          toggleBotStatus();
        }}
      />
    </div>
  );
};
