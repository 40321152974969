import { RefObject, useEffect, useRef, useState } from "react";
import { AudioMessagePlayer } from "./AudioMessagePlayer";

export const AudioMessage = ({ source }: { source: string }) => {
  const audioRef = useRef() as RefObject<HTMLAudioElement>;
  const [value, setValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [isPlaying]);

  return (
    <>
      {/* eslint-disable */}
      <audio
        className="hidden"
        ref={audioRef}
        src={source}
        onTimeUpdate={(e) => {
          setMaxValue(e.currentTarget.duration);
          setValue(e.currentTarget.currentTime);
        }}
        onEnded={() => {
          audioRef.current!.currentTime = 0;
          setIsPlaying(false);
        }}
      />
      <AudioMessagePlayer
        maxValue={maxValue}
        value={value}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        audio={audioRef}
      />
    </>
  );
};
