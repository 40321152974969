import { BreadCrumb } from "@/components/BreadCrumb";
import { Chat } from "./components/Chat";
import { ConversationsList } from "./components/Conversations";
import { ChatProvider } from "./context";
import { Customer } from "./components/Customer";

export const LiveChat = () => (
  <ChatProvider>
    <BreadCrumb
      items={[
        {
          text: "Live Chat",
          isActive: true
        }
      ]}
    />
    <div className="flex justify-between px-5 pt-5">
      <ConversationsList />
      <Chat />
      <Customer />
    </div>
  </ChatProvider>
);
