import { Message } from "@/models/chat";

export const ChatAvatar = ({ message: { isBotMessage } }: { message: Message }) => (
  <div className="chat-image avatar">
    <div className="w-10 rounded-full">
      <img
        src={
          isBotMessage
            ? "/img/chat-icons/robot-avatar.svg"
            : "/img/chat-icons/circle-user-solid.svg"
        }
        alt="chat-avatar"
      />
    </div>
  </div>
);
