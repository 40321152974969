import { useRef } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useOutsideAlerter, useRevokeToken } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/hooks/store-hooks";
import { authActions } from "@/store/reducers/auth.reducer";
import { AvatarPlaceHolder } from "@/components/AvatarPlaceHolder";
import { ProfileMenuItem } from "./ProfileMenuItem";
import styles from "./profile-menu.module.css";

export const ProfileMenu = () => {
  const profileRef = useRef(null);
  const { show, setShow } = useOutsideAlerter<HTMLDivElement>(profileRef, false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  const queryClient = useQueryClient();
  const { mutate: signOut } = useRevokeToken();

  return (
    <div ref={profileRef!}>
      {/* eslint-disable-next-line */}
      <div role="button" onClick={() => setShow(!show)}>
        <AvatarPlaceHolder username={user?.name ?? ""} width={42} />
      </div>

      <div className={`${show ? "block" : "hidden"} ${styles.profile_menu_container}`}>
        <div className="px-4 py-3 text-sm text-gray-900">
          <div>{user?.name}</div>
          <div className="font-bold truncate">{user?.email}</div>
        </div>
        <ProfileMenuItem
          text="Settings"
          icon="gear-solid.svg"
          onClick={() => {
            setShow(!show);
            navigate("/configurations");
          }}
        />

        <ProfileMenuItem
          text="Sign out"
          icon="power-off.svg"
          onClick={() => {
            setShow(!show);
            signOut();
            dispatch(authActions.signOut());
            queryClient.clear();
          }}
        />
      </div>
    </div>
  );
};
