import { generateHSLColor } from "./utils/color-generator";

type Props = {
  username: string;
  /** width in pixels */
  width: number;
};

export const AvatarPlaceHolder = ({ username, width = 40 }: Props) => {
  const avatarColor = generateHSLColor(username?.trim());
  return (
    <div className="avatar placeholder">
      <div
        className="bg-neutral-focus text-neutral-content rounded-full"
        style={{
          backgroundColor: `${avatarColor}`,
          width
        }}
      >
        <span
          className="text-white font-semibold uppercase"
          style={{
            fontSize: 15,
            fontWeight: 700
          }}
        >
          {username?.trim()[0]}
        </span>
      </div>
    </div>
  );
};
