import { NodeProps, Position } from "react-flow-renderer";
import { DeliveryNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { DeliveryNodeForm } from "../DialogForms";
import { DeliveryFormValidationObject } from "../DialogForms/validators";

const DragElement = <BaseNode title="Delivery" color="#388582" isDragElement />;

const NodeComponent = ({ ...rest }: NodeProps<DeliveryNodeData>) => (
  <>
    <BaseHandle type="target" position={Position.Top} />
    <BaseNode
      title="Delivery"
      color="#388582"
      icon="truck-solid.svg"
      form={<DeliveryNodeForm />}
      validationObject={DeliveryFormValidationObject}
      {...rest}
    />
    <BaseHandle type="source" position={Position.Bottom} />
  </>
);

export const DeliveryNode: NodeType = {
  type: "delivery",
  component: NodeComponent,
  dragElement: DragElement
};
