import { BreadCrumb } from "@/components/BreadCrumb";
import { useParams } from "react-router-dom";
import {
  PaymentCard,
  OrderActions,
  CustomerCard,
  CheckoutCard,
  SummeryCard,
  AnimatedOrderDetails,
  OrderHeader
} from "./components";
import { useOrderById } from "./hooks/order-hooks";

export const OrderDetails = () => {
  const { orderId } = useParams();
  const { isLoading, data: order } = useOrderById(orderId);
  if (isLoading) return <AnimatedOrderDetails />;
  return (
    <div className=" px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
      <BreadCrumb
        items={[
          { text: "Orders", isActive: false, path: "/orders" },
          { text: order ? `Order#${order._id.slice(0, 7)}` : "", isActive: true }
        ]}
      />
      <div className="flex justify-start item-start space-y-2 flex-col ">
        <OrderActions order={order!} />
      </div>
      <OrderHeader order={order!} />
      <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
        <div className="  flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
          <CheckoutCard orderProducts={order?.products!} />
          <SummeryCard order={order!} />
        </div>

        <div className="bg-gray-50 w-full xl:w-96 flex justify-between  md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col shadow-lg">
          <div className="flex  flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 ">
            <CustomerCard customer={order?.customer!} />
            <PaymentCard order={order!} />
          </div>
        </div>
      </div>
    </div>
  );
};
