import * as yup from "yup";

export const ManualOrderValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required("required")
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    phone: yup
      .string()
      .required()
      .min(9, "Minimum length 9 digits")
      .max(13, "Maximum length 13 digits"),
    location: yup
      .string()
      .required()
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    paymentMethod: yup.string().required()
  });
