import { useFormContext } from "react-hook-form";
import { SelectField, TextField } from "@/components/Forms";
import { PaymentMethodTypes, PaymentMethods, PaymentNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const PaymentNodeForm = () => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<PaymentNodeData>();
  const method = watch("method");

  return (
    <NodeFormWrapper description=" This guides your customer with through the payment process.">
      <SelectField
        name="method"
        register={register}
        label="Payment Method"
        options={Object.entries(PaymentMethods).map(([value, label]) => ({
          value,
          label
        }))}
        error={errors.method?.message}
      />
      {method !== PaymentMethodTypes.Cash && (
        <>
          <TextField
            name="mbokAccount"
            register={register}
            label="mBoK Account No."
            error={errors.mbokAccount?.message}
          />
          <TextField
            name="mbokName"
            register={register}
            label="mBoK Account Name"
            error={errors.mbokName?.message}
          />
          <TextField
            isRtl
            name="mbokNameAR"
            register={register}
            label="اسم حساب بنكك"
            error={errors.mbokNameAR?.message}
          />
          <TextField
            name="contactPhone"
            register={register}
            label="Your Contact Number"
            error={errors.contactPhone?.message}
          />
        </>
      )}
    </NodeFormWrapper>
  );
};
