import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserCredentials } from "@/models/auth/Credentials";
import { TextFieldWithIcon, PasswordField } from "@/components/Forms";
import { AuthFormWrapper } from "./AuthFormWrapper";
import { useSignIn } from "../hooks/auth-hooks";
import { LoginFormValidationSchema } from "../utils/validationSchema";
import { AuthWrapper } from "./AuthWrapper";

export const SignInForm = () => {
  const { isLoading, mutate: signIn } = useSignIn();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserCredentials>({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(LoginFormValidationSchema())
  });

  const onSignIn = (values: UserCredentials) => {
    signIn(values);
  };

  return (
    <AuthWrapper>
      <AuthFormWrapper
        title="Welcome Back!"
        submitText="Sign In"
        isLoading={isLoading}
        onSubmit={() => handleSubmit(onSignIn)()}
        linkDesc="Don’t have an account ?"
        linkText="Sign up"
        link="/auth/signup"
      >
        <TextFieldWithIcon
          name="email"
          icon="envelope.svg"
          label="Email"
          placeholder="user@example.com"
          register={register}
          error={errors.email?.message}
        />
        <PasswordField
          name="password"
          label="Password"
          placeholder="••••••••"
          register={register}
          error={errors.password?.message}
        />
      </AuthFormWrapper>
    </AuthWrapper>
  );
};
