/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from "@/components/Buttons";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { exposeToast } from "@/components/Toasts";
import { read, utils } from "xlsx";

type Imported = {
  _id: string;
  name: string;
  price: number;
  arabicName: string;
  desc: string;
  arabicDesc: string;
};
type CategoryEditeProps = {
  show: boolean;
  onUpdate: (data: Imported[]) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const DropFileModal = ({
  show,
  onClose,
  onUpdate,
  onCancel,
  ...rest
}: CategoryEditeProps) => {
  const [visible, setVisible] = useState(show);
  const [imported, setImported] = useState<any>();
  const [file, setFile] = useState<any>();

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  return (
    <BaseDialog
      title="Drop File Here "
      show={visible}
      body={
        <div>
          <div className="relative h-fit flex items-center justify-center bg-gray-500 bg-no-repeat bg-cover">
            <div className="absolute bg-black opacity-60 inset-0 z-0" />
            <div className="sm:max-w-lg w-full p-10 bg-white z-10">
              <div className="text-center">
                <h2 className="mt-5 text-3xl font-bold text-gray-900">File Upload!</h2>
                <p className="mt-2 text-sm text-gray-400">Upload xlsx to update products.</p>
              </div>
              <form className="mt-8 space-y-3" action="#" method="POST">
                <div className="grid grid-cols-1 space-y-2">
                  <label className="text-sm font-bold text-gray-500 tracking-wide">
                    Attach Document
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <label className="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                      <div className="h-full w-full text-center flex flex-col items-center justify-center">
                        {!file ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-10 h-10 text-blue-400 group-hover:text-blue-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                        ) : (
                          file.name
                        )}

                        <p className="pointer-none text-gray-500 ">
                          <span className="text-sm">Drag and drop</span> files here <br /> or select
                          a file from your computer
                        </p>
                      </div>
                      <input
                        type="file"
                        accept=".xlsx"
                        className="hidden"
                        onChange={(e) => {
                          const files = e.target.files![0];
                          setFile(files!);
                          if (files) {
                            const reader = new FileReader();
                            reader.onload = (files) => {
                              const data = files?.target?.result;
                              const workbook = read(data, { type: "array" });
                              const productsWB = workbook.SheetNames[0];
                              const productsWS = workbook.Sheets[productsWB];
                              const json = utils.sheet_to_json(productsWS);
                              setImported(json);
                            };
                            reader.readAsArrayBuffer(files);
                          }
                        }}
                      />
                    </label>
                  </div>
                </div>
                <p className="text-sm text-gray-300">
                  <span>File type: xlsx</span>
                </p>
              </form>
            </div>
          </div>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <Button
            variant="btn-wide"
            //  isLoading={isLoading}
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => {
              if (imported) {
                const validProds = imported.every(
                  (obj: any) =>
                    typeof obj._id === "string" &&
                    obj._id.length === 24 &&
                    typeof obj.name === "string" &&
                    obj.name.length <= 20 &&
                    typeof obj.desc === "string" &&
                    typeof obj.arabicName === "string" &&
                    obj.arabicName.length <= 20 &&
                    typeof obj.arabicDesc === "string" &&
                    typeof obj.price === "number" &&
                    obj.price >= 20
                );

                if (!validProds) exposeToast({ type: "error", message: "Invalid File" });
                else onUpdate(imported);
              } else {
                exposeToast({ type: "info", message: "Select Files To Import" });
              }
            }}
          >
            <p className="font-semibold ">Import</p>
          </Button>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const dropFile = ({
  update,
  cancel
}: {
  update: (data: Imported[]) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "category-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <DropFileModal
          show
          onUpdate={(data: any) => {
            update(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
