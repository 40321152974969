/* eslint-disable no-nested-ternary */
import { Order } from "@/models/orders";

type Props = {
  order: Order;
};

export const OrderStatus = ({ order }: Props) => (
  <div className="w-24 items-center flex">
    <div
      className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded  last:mr-0 mr-1${
        order.status === "new"
          ? "text-blue-600 bg-blue-200"
          : order.status === "confirmed"
          ? "text-teal-600 bg-teal-200"
          : "text-red-600 bg-red-200"
      } `}
    >
      {order.status}
    </div>
  </div>
);
