/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Button } from "@/components/Buttons";
import { Product } from "@/models/Products";
import { utils, writeFile } from "xlsx";
import { useUpdateProdWoImg } from "../hooks";
import { dropFile } from "./DropFile";

type Props = {
  products: Product[];
  onChange: () => void;
};
export const ImportExportXLSX = ({ products, onChange }: Props) => {
  const { mutate: updateThroughXLSX } = useUpdateProdWoImg();

  return (
    <div className="dropdown  dropdown-end">
      <Button tabIndex={0} variant="btn-block">
        <span>
          <img className="w-8" src="/img/product-icons/file-csv-solid.svg" alt="." />
        </span>{" "}
      </Button>
      <div
        tabIndex={0}
        className="flex dropdown-content  items-center card card-compact   w-44 p-2 text-primary-content bg-white "
      >
        <div className="flex flex-col justify-center space-y-3">
          <Button
            variant="btn-accent"
            onClick={() => {
              const fileName = "My-Products.xlsx";
              const prods = products?.map((p) => ({
                _id: p._id,
                name: p.name,
                arabicName: p.arabicName,
                desc: p.desc,
                arabicDesc: p.arabicDesc,
                price: p.price
              }));
              const wsProducts = utils.json_to_sheet(prods!);
              const wb = utils.book_new();
              utils.book_append_sheet(wb, wsProducts, "products");
              writeFile(wb, fileName);
            }}
          >
            xlsx Export
          </Button>
          <Button
            variant="btn-active"
            onClick={() => {
              dropFile({
                update: async (imported) => {
                  await Promise.all(
                    imported.map(({ _id, ...rest }) =>
                      updateThroughXLSX({ prod: rest, productId: _id })
                    )
                  ).finally(() => onChange());
                }
              });
            }}
          >
            xlsx Import
          </Button>
        </div>
      </div>
    </div>
  );
};
