import { Conversation } from "@/models/chat";
import React, { useEffect, useState } from "react";
import { useReactMediaRecorder, ReactMediaRecorderRenderProps } from "react-media-recorder";
import { useMessageReplay } from "../hooks/chat-hooks";
import { getBase64FromFile } from "../utils/getBase64FromFile";

type MediaRecorder = ReactMediaRecorderRenderProps;
type RecorderTimer = {
  minutes: string;
  seconds: string;
};

interface ChatContextType {
  /** the current opened chat */
  chat?: Conversation;
  handleChatChange: (conversation: Conversation) => void;
  showProfile: boolean;
  toggleCustomerProfile: (show?: boolean) => void;

  recorder: MediaRecorder;
  timer: RecorderTimer;
  resetRecordTimer: () => void;
}

const ChatContext = React.createContext<ChatContextType>({} as ChatContextType);

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [chat, setChat] = useState<Conversation>();
  const [timer, setTimer] = useState<RecorderTimer>({ seconds: "00", minutes: "00" });
  const [counter, setCounter] = useState(0);

  const { mutate } = useMessageReplay();

  const recorder = useReactMediaRecorder({
    audio: true,
    video: false,
    blobPropertyBag: {
      type: "audio/mp4"
    },
    onStop: async () => {
      const { mediaBlobUrl } = recorder;
      if (mediaBlobUrl) {
        const audio = await getBase64FromFile(mediaBlobUrl);
        mutate({
          audio,
          images: [],
          text: "",
          sender: { name: "MTN" }
        });
      }
    }
  });

  useEffect(() => {
    // eslint-disable-next-line
    let intervalId: NodeJS.Timer;

    if (recorder.status === "recording") {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : `${secondCounter}`;
        const computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : `${minuteCounter}`;

        setTimer({ seconds: computedSecond, minutes: computedMinute });

        setCounter((counter) => counter + 1);
      }, 650);
    }

    return () => clearInterval(intervalId);
  }, [recorder, counter]);

  const resetRecordTimer = () => {
    setCounter(0);
    setTimer({ seconds: "00", minutes: "00" });
  };

  const handleChatChange = (conversation: Conversation) => {
    setChat(conversation);
  };

  const toggleCustomerProfile = (show: boolean = showProfile) => {
    setShowProfile(!show);
  };

  const value = React.useMemo<ChatContextType>(
    () => ({
      chat,
      handleChatChange,
      recorder,
      timer,
      resetRecordTimer,
      showProfile,
      toggleCustomerProfile
    }),
    [chat, showProfile, recorder]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChat = () => {
  const c = React.useContext(ChatContext);
  if (c === undefined) {
    throw new Error("useChat must be inside chatContext provider");
  }
  return c;
};
