import { useQuery } from "react-query";
import { IMetricApi } from "@/api/metric";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { DailyMetrics, TrendInterval, Trends } from "@/models/metric";

export const useMetrics = (interval: TrendInterval) => {
  let metrics: Array<DailyMetrics> = [];
  const metricApi = useInjection<IMetricApi>(SERVICES_TYPES.METRIC);
  const { data, isLoading } = useQuery<Trends>("trends", () => metricApi.getTrends(), {
    staleTime: 20000
  });
  if (data) {
    const { trends } = data;
    metrics = trends[interval];
  }

  return { metrics, isLoading };
};
