import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { imagesPreviewDialog } from "@/components/Modals";
import { FileUploader, TextArea, TextField } from "@/components/Forms";
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";
import { Product, ProductData } from "@/models/Products";
import { useAddProduct } from "../hooks";
import { AddProductValidationSchema } from "../utils";

type props = {
  parent?: string;
  update: (newProd: Product) => void;
};
export const AddNewProduct = ({ parent, update }: props) => {
  const { isLoading: isSubmitting, mutate: submit } = useAddProduct();
  const [images, setImages] = useState<[]>([]);
  const [variants, setVarients] = useState<Array<string>>([]);
  const onImageChange = async (event: any) => {
    const { files } = event.target;
    imagesPreviewDialog({
      images: Array.from(files).map((image: any) => URL.createObjectURL(image)),
      accept: async () => {
        const imageUrls: any = await Promise.all(
          Array.from(files).map(
            (image: any) =>
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (upload: any) => resolve(upload.target.result);
                reader.readAsDataURL(image);
              })
          )
        );
        setImages(imageUrls);
      }
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ProductData>({
    defaultValues: {
      name: "",
      arabicName: "",
      desc: "",
      arabicDesc: "",
      price: 0,
      url: ""
    },
    resolver: yupResolver(AddProductValidationSchema())
  });

  const onSubmit = (values: ProductData) => {
    const product: ProductData = {
      ...values,
      images,
      categories: [parent!],
      variants
    };
    submit(product, {
      onSuccess(data) {
        setImages([]);
        update(data);
        reset();
        setVarients([]);
      }
    });
  };
  const addTag = () => (e: any) => {
    const tag: string = e.target.value;
    setVarients(variants.concat([tag]));
  };
  const onInvalidForm = () => {};

  return (
    <div className="flex justify-center ">
      <Card>
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <TextField
            name="name"
            label="Product Name"
            placeholder="Product Name"
            register={register}
            error={errors.name?.message}
          />
          <TextField
            name="arabicName"
            label="إسم المنتج"
            placeholder="إسم المنتج"
            register={register}
            error={errors.arabicName?.message}
            isRtl
          />
          <TextArea
            name="desc"
            rows={2}
            placeholder="Description"
            label="Description"
            register={register}
            error={errors.desc?.message}
          />
          <TextArea
            name="arabicDesc"
            rows={2}
            placeholder="وصف المنتج"
            label="وصف المنتج"
            register={register}
            isRtl
            error={errors.arabicDesc?.message}
          />
          <TextField
            name="price"
            label="Price"
            type="number"
            placeholder="Price"
            register={register}
            error={errors.price?.message}
          />
          <TextField
            name="url"
            label="URL"
            placeholder="Product url"
            register={register}
            error={errors.url?.message}
          />
          <TextField
            onKeyUp={addTag()}
            name="variants"
            placeholder="Add Some Tags"
            register={register}
          />
          {variants.map((varient) => (
            <div
              key={varient}
              className="bg-blue-100 inline-flex items-center text-sm rounded  mr-1 overflow-hidden"
            >
              <span className="ml-2 mr-1 leading-relaxed truncate max-w-xs px-1">{varient}</span>
              <button
                onClick={() => {
                  setVarients(
                    variants.filter((v) => variants.indexOf(v) !== variants.indexOf(varient))
                  );
                }}
                type="button"
                className="w-6 h-8 inline-block align-middle text-gray-500 bg-blue-200 focus:outline-none"
              >
                <svg
                  className="w-6 h-6 fill-current mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                  />
                </svg>
              </button>
            </div>
          ))}

          <FileUploader label="Select Images" onChange={onImageChange} accept="image/*" />
          <div className="flex justify-between">
            <Button
              variant="btn-block"
              isLoading={isSubmitting}
              onClick={() => handleSubmit(onSubmit, onInvalidForm)()}
            >
              Add New Product
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
