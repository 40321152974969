import { User } from "@/models/auth";
import React from "react";

type Props = {
  user: User;
};

export const AccountDetails = ({ user }: Props) => (
  <div className="flex text-sm flex-col py-8 space-y-5">
    <div className="flex flex-row space-x-3  p-1">
      <div className="text-xs text-gray-500">Name :</div>
      <div>{user?.name}</div>
    </div>
    <div className=" flex flex-row space-x-3   p-1">
      <div className="text-xs text-gray-500">Email :</div>
      <div>{user?.email}</div>
    </div>
  </div>
);
