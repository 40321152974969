import { ICustomersApi } from "@/api/customer";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { useMutation, useQuery } from "react-query";

export const useCustomer = (query: string, skip: number) => {
  const CustomersApi = useInjection<ICustomersApi>(SERVICES_TYPES.CUSTOMERS);
  return useQuery(["customers", skip, query], () => CustomersApi.getAllCustomers(skip, query));
};

export const useUpdateCustomer = () => {
  const CustomersApi = useInjection<ICustomersApi>(SERVICES_TYPES.CUSTOMERS);
  return useMutation((data: { customerId: string; updates: any }) =>
    CustomersApi.updateCustomer(data)
  );
};
