export const generateHSLColor = (
  char: string = "",
  saturation: number = 80,
  ligthness: number = 60
) => {
  let hash = 0;
  for (let i = 0; i < char.length; i++) {
    // eslint-disable-next-line
    hash = char.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hue = hash % 360;

  return `hsl(${hue},${saturation}%,${ligthness}%)`;
};
