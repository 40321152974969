import { OrderCustomer } from "@/models/orders";
import React from "react";

type Props = {
  customer: OrderCustomer;
};

export const CustomerCard = ({ customer }: Props) => (
  <div className="flex flex-col justify-start items-start flex-shrink-0">
    <h3 className="text-xl font-semibold leading-5 text-gray-800">Customer</h3>
    <div className=" pt-5 w-full">
      <div className="flex space-x-4 py-4 border-b border-gray-200 w-full">
        <img src="/img/order-icons/customer.svg" alt="." />
        <p className="cursor-pointer text-sm leading-5 text-gray-800">{customer.name}</p>
        {/* <p className="text-sm leading-5 text-gray-600">10 Previous Orders</p> */}
      </div>
      <div className="flex space-x-4 py-4 border-b border-gray-200 w-full">
        <img src="/img/order-icons/email.svg" alt="." />
        <p className="cursor-pointer text-sm leading-5 text-gray-800">{customer.email}</p>
      </div>
      <div className="flex  space-x-4 py-4 border-b border-gray-200 w-full">
        <img src="/img/order-icons/phone.svg" alt="." />
        <p className="cursor-pointer text-sm leading-5 text-gray-800">{customer.phone}</p>
      </div>
      <div className="flex  space-x-4 py-4 border-b border-gray-200 w-full">
        <img className="w-8" src="/img/icons/office-building.svg" alt="." />
        <p className="cursor-pointer text-sm leading-5 text-gray-800">{customer.location}</p>
      </div>
    </div>
  </div>
);
