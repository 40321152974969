import { useEffect } from "react";
import { SocketIOEvent } from "./SocketIOEvents";
import { useSocketIO } from "./context/SocketContext";

type SocketEventCallback = (...args: any[]) => void;

export const useSocketIOEvent = (event: SocketIOEvent, callback: SocketEventCallback) => {
  const { socket, isConnected } = useSocketIO();

  // eslint-disable-next-line
  useEffect(() => {
    if (isConnected && event != null) {
      socket?.on(event, callback);
      return () => {
        socket?.off(event, callback);
      };
    }
  }, [socket, isConnected, event]);
};
