import React, { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/hooks";

export const AuthWrapper = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/", { replace: true });
  }, []);

  return (
    <div className="relative h-screen">
      <img
        className="h-full w-full object-center object-cover"
        src="/img/BackgroundAuth.png"
        alt="robot"
      />
      <div className="absolute inset-0 flex items-center">
        <div className="w-full ml-40">{children}</div>
        <img className="absolute w-40 bottom-0 right-0" src="/img/enigma.png" alt="enigma-logo" />
      </div>
    </div>
  );
};
