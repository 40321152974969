/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from "@/components/Buttons";
import { TextField } from "@/components/Forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useForm } from "react-hook-form";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import * as yup from "yup";

type ProductEditeProps = {
  show: boolean;
  email: string;
  onUpdate: (data: any) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const VerifyEmail = ({
  show,
  email,
  onClose,
  onUpdate,
  onCancel,
  ...rest
}: ProductEditeProps) => {
  const [visible, setVisible] = useState(show);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>({
    defaultValues: {
      email
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().required("Please enter your email").email("Invalid email")
      })
    )
  });

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  const onSubmit = (data: { email: string }) => {
    onUpdate(data);
  };
  return (
    <BaseDialog
      title="Check Your Email"
      show={visible}
      body={
        <div className=" p-10">
          <div className="sm:w-96 w-72">
            <TextField
              name="email"
              label="Email"
              placeholder=" Enter Your Email"
              register={register}
              error={errors.email?.message}
            />
          </div>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <Button
            variant="btn-wide"
            //  isLoading={isLoading}
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => handleSubmit(onSubmit)()}
          >
            <p className="font-semibold ">Submit</p>
          </Button>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const verifyEmail = ({
  email,
  update,
  cancel
}: {
  email: string;
  update: (data: any) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "product-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <VerifyEmail
          show
          email={email}
          onUpdate={(data: any) => {
            update(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
