import { UserNodes } from "../Nodes";
import styles from "./panel.module.css";

export const Panel = () => (
  <div
    className={`${styles.panel_container} space-y-3 animate__animated animate__fadeInRight animate__delay-1s`}
  >
    {UserNodes.map(({ type, dragElement }) => (
      // eslint-disable-next-line
      <div
        key={type}
        role="button"
        draggable
        onDragStart={(event) => {
          event.dataTransfer.setData("application/reactflow", type);
          // eslint-disable-next-line
          event.dataTransfer.effectAllowed = "move";
        }}
      >
        {dragElement}
      </div>
    ))}
  </div>
);
