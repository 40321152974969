import * as yup from "yup";
import { PaymentMethods } from "@/models/flow";
import { UserInputNodeTypes } from "../../Nodes/UserInputNode";

const TitleSchema = yup.string().max(20, `Titles have maximum length of ${20} characters`);

const MessageSchema = yup.string().max(1000, `Messages have maximum length of ${1000} characters`);

export const MessageFormValidationObject = {
  label: TitleSchema,
  labelAR: TitleSchema,
  text: MessageSchema,
  textAR: MessageSchema
};

export const DeliveryFormValidationObject = {
  text: MessageSchema.required("English message is required"),
  textAR: MessageSchema.required("Arabic message is required")
};

export const ChoicesFormValidationObject = {
  text: MessageSchema,
  textAR: MessageSchema,
  is_main_menu: yup.boolean().required(),
  saveChoice: yup.boolean().required(),
  fieldName: yup.string().when("saveChoice", {
    is: true,
    then: yup.string().trim().required("Please enter how do you want to save it")
  })
};

export const UserInputFormValidationObject = {
  questionText: MessageSchema,
  questionTextAR: MessageSchema,
  questionType: yup
    .string()
    .oneOf(Object.keys(UserInputNodeTypes), "Invalid input type")
    .required(),
  fieldName: yup.string().when("questionType", {
    is: "custom",
    then: yup.string().trim().required()
  }),
  answerType: yup
    .string()
    .when("questionType", {
      is: (q: string) => q !== "custom",
      then: yup.string().oneOf(["text"], "When quesion type isn't custom, Answer can only be text")
    })
    .when("questionType", {
      is: "custom",
      then: yup.string().oneOf(["text", "image", "PDF"], "Answer must be either image or text")
    })
};

export const PaymentFormValidationObject = {
  method: yup.string().required().oneOf(Object.keys(PaymentMethods), "Invalid payment method"),
  mbokAccount: yup.string().when("method", {
    is: "mbokDirect",
    then: yup
      .string()
      .required("MBOK account number is required")
      .trim()
      .matches(/^\d*$/, "Should contain only numbers")
  }),
  mbokName: yup
    .string()
    .trim()
    .when("method", {
      is: "mbokDirect",
      then: yup.string().required("MBOK account name is required")
    }),
  mbokNameAR: yup
    .string()
    .trim()
    .when("method", {
      is: "mbokDirect",
      then: yup.string().required("MBOK arabic account name is required")
    }),
  contactPhone: yup
    .string()
    .trim()
    .when("method", {
      is: "mbokDirect",
      then: yup.string().required("Phone number is required")
    })
};

export const MediaFormValidationObject = {
  label: TitleSchema,
  labelAR: TitleSchema,
  mediaType: yup
    .string()
    .oneOf(["image", "sticker", "video", "audio", "file"], "Invalid media type")
    .required(),
  media: yup
    .mixed()
    .required("A media file must be uploaded")
    .test({
      name: "size-limit",
      test: (value) => value && value.size <= 5 * 1024 * 1024, // 5 MB
      message: "File too big. Max size is 5 MB."
    })
};
