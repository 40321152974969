/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";
import { TextField } from "@/components/Forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useForm } from "react-hook-form";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { Customer, CustomerData } from "@/models/customers";
import { CustomerValidationSchema } from "../utils/CustomerValidation";

type Props = {
  show: boolean;
  customer: Customer;
  onUpdate: (data: CustomerData) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const CustomerEditeModal = ({
  customer,
  show,
  onClose,
  onUpdate,
  onCancel,
  ...rest
}: Props) => {
  const [visible, setVisible] = useState(show);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CustomerData>({
    defaultValues: {
      name: customer.name,
      phone: customer.phone,
      location: customer.location,
      email: customer.email
    },
    resolver: yupResolver(CustomerValidationSchema())
  });

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  const onSubmit = (values: CustomerData) => {
    onUpdate(values);
  };
  return (
    <BaseDialog
      title={`Edite ${customer.name} `}
      show={visible}
      body={
        <div className="w-96">
          <Card>
            <div className="p-6 space-y-4  w-full">
              <TextField
                name="name"
                label="Customer Name"
                placeholder="Name"
                register={register}
                error={errors.name?.message}
              />
              <TextField
                name="email"
                label="Email"
                placeholder="Email"
                register={register}
                error={errors.email?.message}
              />
              <TextField
                name="phone"
                label="Phone Number"
                placeholder="Phone"
                register={register}
                error={errors.phone?.message}
              />
              <TextField
                name="location"
                label="Address"
                placeholder="Address"
                register={register}
                error={errors.location?.message}
              />
            </div>
          </Card>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <Button
            variant="btn-wide"
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => handleSubmit(onSubmit)()}
          >
            <p className="font-semibold ">Submit</p>
          </Button>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const customerEdite = ({
  customer,
  update,
  cancel
}: {
  customer: Customer;
  update: (data: CustomerData) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "product-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <CustomerEditeModal
          show
          customer={customer}
          onUpdate={(data: any) => {
            update(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
