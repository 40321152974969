/* eslint-disable no-param-reassign */
import { IEdgeElement, INodeElement } from "@/models/flow";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge, Node } from "react-flow-renderer";

export interface FlowState {
  nodes: Node[];
  edges: Edge[];
}

const initialState: FlowState = {
  nodes: [],
  edges: []
};

const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    setNodes(state, actions: PayloadAction<INodeElement[]>) {
      state.nodes = actions.payload;
    },
    setEdges(state, actions: PayloadAction<IEdgeElement[]>) {
      state.edges = actions.payload;
    },
    addNode(state, actions: PayloadAction<INodeElement>) {
      const { nodes } = state;
      state.nodes = [...nodes, actions.payload];
    },
    addEdge(state, actions: PayloadAction<IEdgeElement>) {
      const { edges } = state;
      state.edges = [...edges, actions.payload];
    },
    updateElement(state, action: PayloadAction<INodeElement>) {
      const { nodes } = state;
      const updated = action.payload;
      const index = nodes.findIndex((node) => node.id === updated.id);
      if (index !== -1) {
        const nextState = Array.from(nodes);
        nextState.splice(index, 1, updated);
        state.nodes = nextState;
      }
    },
    removeNode(state, action: PayloadAction<string>) {
      const filterNodes = state.nodes.filter((oldState) => oldState.id !== action.payload);
      state.nodes = filterNodes;
    },
    removedEdges(state, action: PayloadAction<IEdgeElement[]>) {
      const removedEdges = action.payload;
      const removedEdgesIds = new Set(Array.from(removedEdges, (edge) => edge.id));
      const filteredEdges = state.edges.filter((edge) => !removedEdgesIds.has(edge.id));
      state.edges = filteredEdges;
    }
  }
});
export const flowActions = flowSlice.actions;

export const flowReducer = flowSlice.reducer;
