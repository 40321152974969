import { ICustomersApi } from "@/api/customer";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { useMutation, useQuery } from "react-query";

export const useCustomer = (customerId: string) => {
  const CustomersApi = useInjection<ICustomersApi>(SERVICES_TYPES.CUSTOMERS);
  return useQuery(`customer-${customerId}`, () => CustomersApi.getCustomerById(customerId));
};

export const useNoteSticker = () => {
  const customersApi = useInjection<ICustomersApi>(SERVICES_TYPES.CUSTOMERS);
  return useMutation<any, any, any>((data: { customerId: string; note: string }) =>
    customersApi.stickNote(data)
  );
};

export const useDeleteNote = () => {
  const customersApi = useInjection<ICustomersApi>(SERVICES_TYPES.CUSTOMERS);
  return useMutation<any, any, any>((data: { customerId: string; noteId: string }) =>
    customersApi.deleteNote(data)
  );
};
