import { NodeProps, Position } from "react-flow-renderer";
import { CategoryNodeData } from "@/models/flow";
import { useCategories, useCategoryById } from "@/pages/Catalogs/hooks/useCategories";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { CategoryNodeForm } from "../DialogForms";

const DragElement = <BaseNode title="Category" color="#A24BAB" isDragElement />;

const NodeComponent = ({ data: { categoryId }, ...rest }: NodeProps<CategoryNodeData>) => {
  const { data } = useCategories();
  const { data: category } = useCategoryById(categoryId);

  return (
    <>
      <BaseHandle type="target" position={Position.Top} />
      <BaseNode
        title={category ? category.name : "Category"}
        color="#A24BAB"
        icon="shapes-solid.svg"
        form={<CategoryNodeForm categories={data ?? []} />}
        {...rest}
      />
      <BaseHandle type="source" position={Position.Bottom} />
    </>
  );
};

export const CategoryNode: NodeType = {
  type: "category",
  component: NodeComponent,
  dragElement: DragElement
};
