import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { AssistantData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const AssistantNodeForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<AssistantData>();

  return (
    <NodeFormWrapper description="This node turns on Assistant.">
      <TextField
        name="label"
        register={register}
        label="Button Title"
        error={errors.label?.message}
      />
      <TextField
        isRtl
        name="labelAR"
        register={register}
        label="اسم الزر"
        error={errors.labelAR?.message}
      />
      <TextField name="text" register={register} label="Message" />
      <TextField name="textAR" register={register} label="الرسالة" isRtl />
    </NodeFormWrapper>
  );
};
