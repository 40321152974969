import { useLayout } from "@/layout/context/LayoutContext";
import { useLocation, useNavigate } from "react-router-dom";

type _SidebarItem = {
  text: string;
  icon: string;
  path?: string;
  onClick?: Function;
};

export const SidebarItem = ({ text, icon, path, onClick }: _SidebarItem) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { handleSidebarExpansion } = useLayout();
  return (
    // eslint-disable-next-line
    <div
      className={`${pathname === path ? "sidebar_active_item" : ""} sidebar_item`}
      onClick={() => {
        if (onClick) onClick();
        if (path) navigate(path);
        handleSidebarExpansion(false);
      }}
    >
      <img className="w-6 h-6" src={`/img/sidebar-icons/${icon}`} alt={text} />
      <span className="font-semibold text-calm-blue">{text}</span>
    </div>
  );
};
