/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { User } from "@/models/auth";
import { useState } from "react";
import { OrganizationDetails, OrganizationDetailsForms } from "./index";

type Props = {
  user: User;
};

export const Organization = ({ user }: Props) => {
  const [edit, setEdit] = useState(false);

  return (
    <div>
      <div className="flex flex-col mt-20">
        <div className="flex flex-row">
          <p
            style={{
              fontSize: 20,
              fontWeight: 500,
              color: "#044CAC"
            }}
          >
            Organization
          </p>
          <div className="ml-auto">
            <img onClick={() => setEdit(!edit)} src="img/icons/edit.svg" alt="" />
          </div>
        </div>
      </div>
      {edit ? <OrganizationDetailsForms user={user!} /> : <OrganizationDetails user={user!} />}
    </div>
  );
};
