import React, { useEffect, useState } from "react";

type by = "" | "price" | "ordersCount" | "income";

type Props = {
  onChange: (query: string, by: by) => void;
};

export const Filter = ({ onChange }: Props) => {
  const [query, setQuery] = useState("");
  const [by, setBy] = useState<by>("");
  useEffect(() => {
    onChange(query, by);
  }, [query, by]);
  return (
    <div className="container sm:flex-row flex-col flex sm:-8  py-5 px-4">
      <div className=" flex sm:flex-row flex-col  mx-auto ">
        <div className="flex flex-row mb-1 sm:mb-0">
          <div className="relative">
            <select
              onChange={(e) => {
                const v: any = e.target.value;
                setBy(v);
              }}
              className="h-full rounded-l border block appearance-none w-full bg-white text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="">Filter by</option>
              <option value="price">Price</option>
              <option value="income">Income</option>
              <option value="ordersCount">Ordered</option>
            </select>
          </div>
        </div>
        <div className=" relative">
          <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
            </svg>
          </span>
          <input
            onChange={(e) => {
              const v = e.target.value;
              setQuery(v);
            }}
            placeholder="Search By Name"
            className="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
};
