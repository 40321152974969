import BaseModal from "@/components/Modals/BaseModal";
import React from "react";

type Props = {
  isConnected: boolean;
};

export const ConnectionLost = ({ isConnected }: Props) => (
  <BaseModal onClose={() => {}} show={isConnected} wrapperId="connection-lost">
    <div className="bg-white rounded-lg shadow ">
      <div className="p-[24px]">
        <div className="flex w-[300px] items-center">
          <img className="w-14 mx-6 text-center" src="/img/icons/connection.svg" alt="lost" />
          <p className="text-md">Connection Lost</p>
        </div>
      </div>
      <div className="flex justify-end items-center p-[15px] space-x-2">
        {/* eslint-disable-next-line */}
        <div
          role="button"
          className="text-white bg-primary-700 hover:bg-primary-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
          onClick={() => window.location.reload()}
        >
          REFRESH
        </div>
      </div>
    </div>
  </BaseModal>
);
