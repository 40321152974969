import * as yup from "yup";

export const AccountSettingsFormValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email()
  });
export const ResetPasswordFormValidationSchema = () =>
  yup.object().shape({
    oldPassword: yup.string().required(),
    password: yup
      .string()
      .required()
      .min(8, { key: "minimumPasswordLength" })
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).*$/, { key: "invalidPassword" }),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], { key: "passwordsNotMatched" })
  });
