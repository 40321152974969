import { Customer } from "@/models/customers";
import { TextArea } from "@/components/Forms";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNoteSticker } from "@/pages/LiveChat/hooks/useCustomer";
import { SingleNote } from "./SingleNote";

type Props = {
  customer: Customer;
  onUpdate: () => void;
};
type Note = {
  note: string;
};
export const Notes = ({ customer, onUpdate }: Props) => {
  const { mutate: sticker } = useNoteSticker();

  const [stick, setStick] = useState(false);
  const { register, handleSubmit, reset } = useForm<Note>({
    defaultValues: {
      note: ""
    }
  });

  const stickNote = (data: Note) => {
    if (data.note) {
      const note = {
        ...data,
        customerId: customer?._id
      };
      sticker(note, {
        onSuccess() {
          onUpdate();
          reset();
          setStick(false);
        }
      });
    }
  };
  return (
    <div className="flex flex-col py-5">
      <div className="flex justify-between items-center w-full">
        <h3 className="flex items-center justify-between font-medium text-[#324B4A] py-3 cursor-pointer">
          Notes
        </h3>
        {/* eslint-disable-next-line */}
        <div className="flex cursor-pointer" onClick={() => setStick(!stick)}>
          {!stick && <img className="w-6" src="/img/chat-icons/plus.svg" alt="plus" />}
        </div>
      </div>
      <div className={`pt-1 w-full  ${stick ? "block" : "hidden"}`}>
        <TextArea register={register} name="note" rows={3} />
        <div className="flex justify-end py-2 space-x-2">
          {/* eslint-disable-next-line */}
          <div
            className="py-1 px-2 hover:bg-red-100 text-[12px] rounded-full cursor-pointer"
            onClick={() => setStick(false)}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="py-1 px-2 hover:bg-primary-300 rounded-full text-[12px] cursor-pointer"
            onClick={() => handleSubmit(stickNote)()}
          >
            <p className="text-primary-700 font-medium">Save</p>
          </div>
        </div>
      </div>
      {customer?.notes.map((note) => (
        <div key={note._id} className="divide-y-2">
          <SingleNote customerId={customer._id} note={note} onUpdate={onUpdate} />
        </div>
      ))}
    </div>
  );
};
