import { useFormContext } from "react-hook-form";
import { Checkbox, TextField } from "@/components/Forms";
import { ChoicesNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const ChoicesNodeForm = () => {
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<ChoicesNodeData>();
  const isSaved = watch("saveChoice");

  return (
    <NodeFormWrapper description="Use this to ask the user to choose a branch.">
      <TextField name="text" register={register} label="Message" error={errors.text?.message} />
      <TextField
        isRtl
        name="textAR"
        register={register}
        label="الرسالة"
        error={errors.textAR?.message}
      />
      <Checkbox name="is_main_menu" register={register} label="Main Menu" />
      <Checkbox name="saveChoice" register={register} label="Save user choice" />
      {isSaved && (
        <TextField
          name="fieldName"
          register={register}
          label="Save as"
          error={errors.fieldName?.message}
        />
      )}
    </NodeFormWrapper>
  );
};
