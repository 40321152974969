import { ReactFlowProvider } from "react-flow-renderer";
import { useFlowElements } from "./hooks/flow-hooks";
import { FlowMap } from "./components";

export const BotFlow = () => {
  useFlowElements();

  return (
    <ReactFlowProvider>
      <FlowMap />
    </ReactFlowProvider>
  );
};
