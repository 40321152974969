import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { CategoryData, Category, Categories } from "@/models/categories";
import { ICategoriesApi } from "./ICategoriesApi";

@injectable()
export class CategoriesApi implements ICategoriesApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/category";

  // addCategory: (category: CategoryData) => Promise<Category>;

  async getAllCategories(): Promise<Category[]> {
    const { data } = await this.client.get<Categories>(this.baseUrl);
    return data.categories;
  }

  async getRootCategories(): Promise<Category[]> {
    const fullUrl = `${this.baseUrl}/roots`;
    const { data } = await this.client.get<Categories>(fullUrl);
    return data.categories;
  }

  async getCategoryById(categoryId: string): Promise<Category> {
    const fullUrl = `${this.baseUrl}/${categoryId}`;
    const { data } = await this.client.get<Category>(fullUrl);
    return data;
  }

  async deleteCategory(categoryId: string): Promise<Category> {
    const fullUrl = `${this.baseUrl}/${categoryId}`;
    const { data } = await this.client.delete<Category>(fullUrl);
    return data;
  }

  async hideCategory(categoryId: string): Promise<Category> {
    const fullUrl = `${this.baseUrl}/hide/${categoryId}`;
    const { data } = await this.client.put<Category>(fullUrl);
    return data;
  }

  async addCategory(category: CategoryData): Promise<Category> {
    const { data } = await this.client.post<Category>(this.baseUrl, category);
    return data;
  }

  async editCategoryWoImg({
    changes,
    categoryId
  }: {
    changes: any;
    categoryId: string;
  }): Promise<Category> {
    const fullUrl = `${this.baseUrl}/edit/${categoryId}`;
    const { data } = await this.client.post<Category>(fullUrl, changes);
    return data;
  }

  async updateCategory({
    cat,
    categoryId
  }: {
    cat: CategoryData;
    categoryId: string;
  }): Promise<Category> {
    const fullUrl = `${this.baseUrl}/${categoryId}`;
    const { data } = await this.client.put<Category>(fullUrl, cat);
    return data;
  }
}
