import { IProductsApi } from "@/api/Products";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { Product, ProductData } from "@/models/Products";
import { useMutation, useQuery } from "react-query";

export const useProducts = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useQuery("products", () => ProductsApi.getAllProducts());
};

export const useCategoryProducts = (categoryId: string = "") => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useQuery(`cat-products-${categoryId}`, () => ProductsApi.getCategoryProducts(categoryId));
};
export const useRootProducts = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useQuery("root-products", () => ProductsApi.getRootProducts());
};

export const useProductsById = (productId: string) => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useQuery(`product${productId}`, () => ProductsApi.getProductById(productId));
};

export const useDeleteProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("deleted-prod", (productId: string) => ProductsApi.deleteProduct(productId));
};

export const useHideProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("hidden-prod", (productId: string) => ProductsApi.hideProduct(productId));
};

export const useAddProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation<any, any, ProductData>("new-product", (product: ProductData) =>
    ProductsApi.addProduct(product)
  );
};

export const useUpdateProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("u-product", (data: { prod: Product; productId: string }) =>
    ProductsApi.updateProduct(data)
  );
};
