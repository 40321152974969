import { RefObject } from "react";
import styles from "./audio-player.module.css";

type _AudioPlayerProps = {
  audio: RefObject<HTMLAudioElement>;
  isPlaying: boolean;
  setIsPlaying: Function;
  value: number;
  maxValue: number;
};

export const AudioMessagePlayer = ({
  audio,
  isPlaying,
  setIsPlaying,
  value,
  maxValue
}: _AudioPlayerProps) => (
  <div className="flex items-center space-x-2">
    {/* eslint-disable */}
    <img
      role="button"
      className="w-7"
      src={`/img/chat-icons/${!isPlaying ? "circle-play-solid" : "circle-pause-solid"}.svg`}
      alt="play_button"
      onClick={() => setIsPlaying(!isPlaying)}
    />
    <input
      type="range"
      className={`${styles.audio_msg_progress}`}
      max={maxValue}
      value={value}
      onChange={(e) => {
        audio.current!.currentTime = Number(e.currentTarget.value);
      }}
    />
  </div>
);
