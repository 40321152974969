/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Order } from "@/models/orders";
import { useUpdateOrder } from "../../hooks/order-hooks";
import styles from "./note.module.css";

type Props = {
  order: Order;
};

export const OrderNote = ({ order }: Props) => {
  const { mutate: addNote } = useUpdateOrder();

  const noted = (notes: string) => {
    addNote({ orderId: order._id, updates: { notes } });
  };
  return (
    <div className={styles.card}>
      <div className={styles.note}>
        <h2 className={styles.title}>Note</h2>
        <textarea
          defaultValue={order.notes}
          className=" bg-transparent  outline-0   w-32"
          rows={4}
          onChange={(e) => noted(e.target.value)}
        />
      </div>
    </div>
  );
};
