import { ReactPortal } from "@/components/ReactPortal";

type Props = {
  show: boolean;
  children: React.ReactElement;
  wrapperId?: string;
};

const ModalWrapper = ({ wrapperId, show, children }: Props) => (
  <ReactPortal wrapperId={wrapperId || "react-wrapper-portal"}>
    <div
      className={`fixed top-0 left-0 right-0 z-[9999999999999] bg-[#0000002e] ${
        show ? "block" : "hidden"
      } p-4 md:inset-0 h-modal md:h-full`}
    >
      {children}
    </div>
  </ReactPortal>
);

export default ModalWrapper;
