import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { imagesPreviewDialog } from "@/components/Modals";
import { FileUploader, TextArea, TextField } from "@/components/Forms";
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";
import { Category, CategoryData } from "@/models/categories";
import { useAddCategory } from "../hooks/useCategories";
import { AddCategoryValidationSchema } from "../utils/AddCategoryValidationSchema";

type NewCatProps = {
  parent?: string;
  update: (newCat: Category) => void;
};

export const AddNewCategory = ({ parent, update }: NewCatProps) => {
  const { isLoading: isSubmitting, mutate: submit } = useAddCategory();
  const [images, setImages] = useState<[]>([]);
  const onImageChange = async (event: any) => {
    const { files } = event.target;
    imagesPreviewDialog({
      images: Array.from(files).map((image: any) => URL.createObjectURL(image)),
      accept: async () => {
        const imageUrls: any = await Promise.all(
          Array.from(files).map(
            (image: any) =>
              new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (upload: any) => resolve(upload.target.result);
                reader.readAsDataURL(image);
              })
          )
        );
        setImages(imageUrls);
      }
    });
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CategoryData>({
    defaultValues: {
      name: "",
      desc: "",
      arabicDesc: "",
      arabicName: ""
    },
    resolver: yupResolver(AddCategoryValidationSchema())
  });

  const onSubmit = (values: CategoryData) => {
    const category: CategoryData = {
      ...values,
      parent,
      images
    };
    submit(category, {
      onSuccess(data) {
        setImages([]);
        update(data);
        reset();
      }
    });
  };

  const onInvalidForm = () => {};

  return (
    <div className="flex justify-center ">
      <Card>
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <TextField
            name="name"
            label="Category Name"
            placeholder="Category Name"
            register={register}
            error={errors.name?.message}
          />
          <TextField
            name="arabicName"
            label="إسم الصنف"
            placeholder="أدخل إسم الصنف"
            register={register}
            isRtl
            error={errors.arabicName?.message}
          />
          <TextArea
            rows={3}
            name="desc"
            label="Description"
            placeholder="Description"
            register={register}
            error={errors.desc?.message}
          />
          <TextArea
            name="arabicDesc"
            rows={3}
            label="وصف الصنف"
            placeholder="أدخل وصف الصنف"
            register={register}
            isRtl
            error={errors.arabicDesc?.message}
          />
          <FileUploader label="Select Images" onChange={onImageChange} accept="image/*" />
          <div className="flex justify-between">
            <Button
              variant="btn-block"
              isLoading={isSubmitting}
              onClick={() => handleSubmit(onSubmit, onInvalidForm)()}
            >
              Add New Category
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
