import { Button } from "@/components/Buttons";
import React, { PropsWithChildren } from "react";

type _Props = {
  onSubmit: React.MouseEventHandler<HTMLElement>;
};

export const SettingsFormWrapper = ({ onSubmit, children }: PropsWithChildren<_Props>) => (
  <div className="py-8  space-y-5">
    {children}
    <div className="flex justify-center mt-3">
      <Button variant="btn-wide" onClick={onSubmit}>
        Update
      </Button>
    </div>
  </div>
);
