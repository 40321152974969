import { NodeProps, Position } from "react-flow-renderer";
import { MessageNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { MessageNodeForm } from "../DialogForms";
import { MessageFormValidationObject } from "../DialogForms/validators";

const DragElement = <BaseNode title="Message" color="#ff826f" isDragElement />;

const NodeComponent = ({ data, ...rest }: NodeProps<MessageNodeData>) => {
  const { label } = data;
  return (
    <>
      <BaseHandle type="target" position={Position.Top} />
      <BaseNode
        title={label}
        color="#ff826f"
        icon="chat-dots.svg"
        data={data}
        form={<MessageNodeForm />}
        validationObject={MessageFormValidationObject}
        {...rest}
      />
      <BaseHandle type="source" position={Position.Bottom} />
    </>
  );
};

export const MessageNode: NodeType = {
  type: "message",
  component: NodeComponent,
  dragElement: DragElement
};
