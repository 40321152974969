/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import i18next from "i18next";

const initialState = "ar";

const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    changeLanguage(state, action: PayloadAction<string>) {
      const { payload } = action;
      i18next.changeLanguage(payload);
      state = payload;
      return state;
    }
  }
});
export const langActions = langSlice.actions;

export const langReducer = langSlice.reducer;
