import { Button } from "@/components/Buttons";

export const Broadcasting = () => (
  <div className=" flex flex-row px-10">
    <div className="flex flex-row ml-auto space-x-2">
      <div className="tooltip text-white  tooltip-info" data-tip="Broadcast Coming soon">
        <Button disabled variant="btn-accent">
          <span>
            <img className=" w-7" src="img/icons/message.svg" alt="m" />
          </span>
        </Button>
      </div>
      <div
        className="tooltip text-white tooltip-info tooltip-left"
        data-tip="Customers Reward Coming soon"
      >
        <Button disabled variant="btn-accent">
          <span>
            <img className=" w-7" src="img/icons/reward.svg" alt="m" />
          </span>
        </Button>
      </div>
    </div>{" "}
  </div>
);
