import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { Product, ProductData, Products } from "@/models/Products";
import { IProductsApi } from "./IproductsApi";

@injectable()
export class ProductsApi implements IProductsApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/product";

  async getAllProducts(): Promise<Product[]> {
    const { data } = await this.client.get<Products>(this.baseUrl);
    return data.products;
  }

  async getCategoryProducts(categoryId: string): Promise<Product[]> {
    const fullUrl = `${this.baseUrl}/cat/${categoryId}`;
    const { data } = await this.client.get<Product[]>(fullUrl);
    return data;
  }

  async getRootProducts(): Promise<Product[]> {
    const fullUrl = `${this.baseUrl}/roots`;
    const { data } = await this.client.get<Products>(fullUrl);
    return data.products;
  }

  async getProductById(productId: string): Promise<Product> {
    const fullUrl = `${this.baseUrl}/${productId}`;
    const { data } = await this.client.get<Product>(fullUrl);
    return data;
  }

  async deleteProduct(productId: string): Promise<Product> {
    const fullUrl = `${this.baseUrl}/${productId}`;
    const { data } = await this.client.delete<Product>(fullUrl);
    return data;
  }

  async hideProduct(productId: string): Promise<Product> {
    const fullUrl = `${this.baseUrl}/hide/${productId}`;
    const { data } = await this.client.put<Product>(fullUrl);
    return data;
  }

  async addProduct(product: ProductData): Promise<Product> {
    const { data } = await this.client.post<Product>(this.baseUrl, product);
    return data;
  }

  async updateProduct({ prod, productId }: { prod: Product; productId: string }): Promise<Product> {
    const fullUrl = `${this.baseUrl}/${productId}`;
    const { data } = await this.client.put<Product>(fullUrl, prod);
    return data;
  }

  async updateProdWoImg({
    prod,
    productId
  }: {
    prod: Product;
    productId: string;
  }): Promise<Product> {
    const fullUrl = `${this.baseUrl}/edit/${productId}`;
    const { data } = await this.client.put<Product>(fullUrl, prod);
    return data;
  }
}
