import { NodeProps, Position, useReactFlow } from "react-flow-renderer";
import { ChoicesNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { ChoicesNodeForm } from "../DialogForms";
import { ChoicesFormValidationObject } from "../DialogForms/validators";

const DragElement = <BaseNode title="Choices" color="#FFBD5B" isDragElement />;

const NodeComponent = ({ ...rest }: NodeProps<ChoicesNodeData>) => {
  const flow = useReactFlow();
  return (
    <>
      <BaseHandle type="target" position={Position.Top} />
      <BaseNode
        title="Multiple Choices"
        color="#FFBD5B"
        icon="menu-icon.svg"
        form={<ChoicesNodeForm />}
        validationObject={ChoicesFormValidationObject}
        {...rest}
      />
      <BaseHandle
        type="source"
        position={Position.Bottom}
        isValidConnection={({ target }) => {
          const node = flow.getNode(target!);
          return node?.type !== "userInput";
        }}
      />
    </>
  );
};

export const ChoicesNode: NodeType = {
  type: "choices",
  component: NodeComponent,
  dragElement: DragElement
};
