type Props = {
  nOfOrders: number;
};

export const CustomerStatus = ({ nOfOrders }: Props) => (
  <div className="">
    {nOfOrders === 0 && (
      <div className="tooltip" data-tip="No Orders Yet">
        <div className=" bg-yellow-300 text-yellow-700 p-2 text-[12px] rounded-full">Browsing</div>
      </div>
    )}
    {nOfOrders === 1 && (
      <div className="tooltip" data-tip="Made his first order">
        <div className=" bg-green-300 text-green-700 p-2 text-[12px] rounded-full">First Order</div>
      </div>
    )}
    {nOfOrders! > 1 && nOfOrders! <= 3 && (
      <div className="tooltip" data-tip="More than one order">
        <div className=" bg-blue-300 text-blue-700 p-2 text-[12px] rounded-full">Active</div>
      </div>
    )}
    {nOfOrders! > 3 && (
      <div className="tooltip" data-tip="More than four orders">
        <div className=" bg-purple-300 text-purple-700 p-2 text-[12px] rounded-full">
          Very Active
        </div>
      </div>
    )}
  </div>
);
