import React, { PropsWithChildren, useState } from "react";

type _Props = {
  text: string;
};

export const CollapsableCustomerSection = ({ text, children }: PropsWithChildren<_Props>) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div>
      {/* eslint-disable-next-line */}
      <div
        className="flex items-center justify-between font-medium text-[#324B4A] py-3  cursor-pointer"
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        <p>{text}</p>
        <svg
          className="w-5"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: collapsed ? "rotateX(0deg)" : "rotateX(180deg)"
          }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50 0C77.57 0 100 22.43 100 50C100 77.57 77.57 100 50 100C22.43 100 0 77.57 0 50C0 22.43 22.43 0 50 0ZM50 7.5C26.565 7.5 7.5 26.565 7.5 50C7.5 73.435 26.565 92.5 50 92.5C73.435 92.5 92.5 73.435 92.5 50C92.5 26.565 73.435 7.5 50 7.5ZM70.005 40.1295C71.47 41.5895 71.475 43.9645 70.015 45.4345L52.655 62.8645C52.095 63.4325 51.3686 63.7989 50.591 63.9227L50 63.9695C49.005 63.9695 48.045 63.5745 47.345 62.8645L29.99 45.4345C28.525 43.9645 28.535 41.5895 30 40.1295C31.47 38.6695 33.845 38.6695 35.305 40.1395L50 54.9095L64.7 40.1395C66.16 38.6695 68.535 38.6695 70.005 40.1295Z"
            fill="#324B4A"
          />
        </svg>
      </div>
      {!collapsed && children}
    </div>
  );
};
