import { useId } from "react";
import { useDropzone } from "react-dropzone";
import { FormErrorMessage } from "../FormErrorMessage";

type _UploadDropzoneProps = {
  onDrop: (acceptedFiles: File[]) => void;
  allowedFiles: string;
  maxSize: number;
  multiple?: boolean;
  error?: string;
};

export const UploadDropzone = ({
  onDrop,
  allowedFiles,
  maxSize,
  multiple,
  error
}: _UploadDropzoneProps) => {
  const id = useId();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { [allowedFiles]: [] },
    multiple,
    maxSize,
    noClick: true,
    onDrop
  });

  return (
    <div>
      <div className="flex items-center justify-center w-full" {...getRootProps()}>
        <label
          htmlFor={id}
          className={`flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 ${
            isDragActive ? "bg-green-100" : ""
          } ${error ? "bg-red-100" : ""}`}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className={`w-10 h-10 mb-3 text-gray-400 ${isDragActive ? "text-green-800" : ""} ${
                error ? "text-red-800" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <p
              className={`mb-2 px-4 text-sm text-gray-500 text-center ${
                isDragActive ? "text-green-800" : ""
              } ${error ? "text-red-800" : ""}`}
            >
              <span className="font-semibold"> Drag {multiple ? "files " : "a file "} here </span>
              or click to upload <br /> {multiple ? "files " : "a file "} from you device
            </p>
          </div>
          <input id={id} type="file" className="hidden" {...getInputProps()} />
        </label>
      </div>
      <FormErrorMessage error={error} />
    </div>
  );
};
