import { useFormContext } from "react-hook-form";
import { TextArea, TextField } from "@/components/Forms";
import { AssistantData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const AssistantPromptForm = () => {
  const { register } = useFormContext<AssistantData>();

  return (
    <NodeFormWrapper description="This node turns on assistant.">
      <TextArea rows={6} name="prompt" register={register} label="Prompt" />
      <TextField name="apiLink" register={register} label="API link" />
      <TextField name="apiToken" register={register} label="API token" />
    </NodeFormWrapper>
  );
};
