import { TextField } from "@/components/Forms";
import { WAPAFlowData } from "@/models/flow";
import { useFormContext } from "react-hook-form";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const WAPAFlowFormAr = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<WAPAFlowData>();

  return (
    <NodeFormWrapper description="This node send WhatsApp flows to the user">
      <TextField
        name="screenIdAr"
        register={register}
        label="First screen Id"
        error={errors.screenIdAr?.message}
      />
      <TextField
        name="flowIdAr"
        register={register}
        label="Flow Id"
        error={errors.flowIdAr?.message}
      />
      <TextField
        isRtl
        name="ctaButtonlabelAr"
        register={register}
        label="عنوان زر الفتح"
        error={errors.ctaButtonlabelAr?.message}
      />
      <TextField
        isRtl
        name="messageBodyAr"
        register={register}
        label="الرسالة المرفقة"
        error={errors.messageBodyAr?.message}
      />
      <TextField
        isRtl
        name="messageFooterAr"
        register={register}
        label="ذيل الرسالة المرفقة"
        error={errors.messageFooterAr?.message}
      />
    </NodeFormWrapper>
  );
};
