import { BreadCrumb } from "@/components/BreadCrumb";
import { TabController } from "@/components/Tab/components";
import { Category } from "@/models/categories";
import { Product } from "@/models/Products";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AddNewCategory, CategoryCard, ProductCard, AddNewProduct } from "./components";
import { AnimatedCatalog } from "./components/AnimatedCatalog";
import { useCategoryProducts, useCategoryById, useDeleteCategory, useDeleteProduct } from "./hooks";

type params = {
  parentId: string;
};
export const CategoryPage = () => {
  const { parentId } = useParams<params>();
  const { isLoading: loadingCats, data: cat, refetch: refetchCats } = useCategoryById(parentId!);
  const {
    isLoading: loadingProds,
    data: prods,
    refetch: refetchProds
  } = useCategoryProducts(parentId!);
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const { mutate: deleteCategory } = useDeleteCategory();
  const { mutate: deleteProduct } = useDeleteProduct();

  useEffect(() => {
    setCategories(cat?.children!);
    setProducts(prods!);
  }, [cat, prods]);

  useEffect(() => {
    refetchCats();
    refetchProds();
  }, [parentId]);

  const handleNewProd = () => (newProd: Product) => {
    setProducts(products.concat([newProd]));
  };

  const handleNewCat = () => (newCat: Category) => {
    setCategories(categories.concat([newCat]));
  };

  const handleDeleteCategory = () => (categoryId: string) => {
    deleteCategory(categoryId, {
      onSuccess() {
        setCategories(categories.filter((cat) => cat._id !== categoryId));
      }
    });
  };

  const handleDeleteProduct = () => (prodId: string) => {
    deleteProduct(prodId, {
      onSuccess() {
        setProducts(products?.filter((prod) => prod._id !== prodId));
      }
    });
  };

  if (loadingCats || loadingProds) return <AnimatedCatalog />;

  if (categories?.length)
    return (
      <>
        <BreadCrumb
          items={[
            {
              text: "Catalog",
              isActive: false,
              path: "/catalog"
            },
            {
              text: cat?.name!,
              isActive: true
            }
          ]}
        />
        <div className="flex flex-1 flex-wrap  gap-10 ">
          <div className="flex flex-1 flex-wrap gap-5  w-4/5 mt-10">
            {categories?.map((cat: Category) => (
              <div key={cat._id}>
                <CategoryCard
                  category={cat}
                  onDelete={handleDeleteCategory()}
                  update={() => refetchCats()}
                />
              </div>
            ))}
          </div>
          <div className="w-80">
            <AddNewCategory parent={parentId} update={handleNewCat()} />
          </div>
        </div>
      </>
    );
  if (products?.length)
    return (
      <>
        <BreadCrumb
          items={[
            {
              text: "Catalog",
              isActive: false,
              path: "/catalog"
            },
            {
              text: cat?.name!,
              isActive: true
            }
          ]}
        />
        <div className="flex flex-1 flex-wrap  gap-10 ">
          <div className="flex flex-1 flex-wrap gap-5  w-4/5 mt-10">
            {products?.map((prod: Product) => (
              <div className="" key={prod._id}>
                <ProductCard
                  product={prod}
                  onDelete={handleDeleteProduct()}
                  update={() => refetchProds()}
                />
              </div>
            ))}
          </div>
          <div className="w-80 sm:-mt-16">
            <AddNewProduct parent={parentId} update={handleNewProd()} />
          </div>
        </div>
      </>
    );
  return (
    <>
      <BreadCrumb
        items={[
          {
            text: "Catalog",
            isActive: false,
            path: "/catalog"
          },
          {
            text: cat?.name!,
            isActive: true
          }
        ]}
      />
      <section className="text-gray-700 body-font overflow-hidden">
        <div className="container px-5 pb-24 mx-auto mt-10">
          <div className="lg:w-4/5 mx-auto flex flex-wrap place-content-center">
            <TabController
              items={[
                {
                  label: "Add Products",
                  order: 1,
                  element: <AddNewProduct parent={parentId} update={handleNewProd()} />
                },
                {
                  label: "Add Sub Categories",
                  order: 2,
                  element: <AddNewCategory parent={parentId} update={handleNewCat()} />
                }
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
};
