import { useState } from "react";
import { createRoot } from "react-dom/client";
import { CarouselWrapper } from "./components/CarouselWrapper";

type _CarouselProps = {
  images: string[];
  show: boolean;
  wrapperId?: string;
  onClose?: () => void;
};

export const Carousel = ({ images, show, onClose, ...rest }: _CarouselProps) => {
  const [visible, setVisible] = useState(show);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideAnimation, setSlideAnimation] = useState("animate__slideInRight");
  return (
    <CarouselWrapper show={visible} {...rest}>
      <div className="flex justify-center items-center">
        {images.map((img, index) => (
          <img
            key={img}
            src={img}
            className={`h-[500px] px-48 mt-[120px] object-contain  animate__animated ${slideAnimation} ${
              index === activeIndex ? "block" : "hidden"
            } -translate-x-1/2`}
            alt={`img_${index}`}
          />
        ))}
      </div>
      <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
        {images.map((img, index) => (
          // eslint-disable-next-line
          <button
            key={img}
            type="button"
            className={`w-3 h-3 rounded-full ${activeIndex === index ? "bg-white" : "bg-gray-400"}`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
      <button
        type="button"
        className={`absolute top-0 left-[64px] flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none ${
          activeIndex === 0 ? "hidden" : "block"
        }`}
        onClick={() => {
          setSlideAnimation("animate__slideInLeft");
          setActiveIndex((prevIndex) => prevIndex - 1);
        }}
      >
        <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-hover:bg-white/50">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className={`absolute top-0 right-[64px] flex items-center justify-center h-full px-4 group focus:outline-none ${
          activeIndex === images.length - 1 ? "hidden" : "block"
        }`}
        onClick={() => {
          setSlideAnimation("animate__slideInRight");
          setActiveIndex((prevIndex) => prevIndex + 1);
        }}
      >
        <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 group-hover:bg-white/50">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
      {/* eslint-disable-next-line  */}
      <img
        // eslint-disable-next-line
        role="button"
        className="absolute top-0 right-0 w-[80px] p-[30px] hover:bg-[#80808057]"
        src="/img/icons/xmark-white.svg"
        alt="close_icon"
        onClick={() => {
          // eslint-disable-next-line
          onClose && onClose();
          setVisible(false);
        }}
      />
    </CarouselWrapper>
  );
};

export const showImagesCarousel = ({ images }: { images: string[]; cancel?: () => void }) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "images-carousel-view";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <Carousel
          show
          images={images}
          onClose={() => {
            // eslint-disable-next-line
            close && close();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
