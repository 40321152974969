import { AvatarPlaceHolder } from "@/components/AvatarPlaceHolder";
import { Conversation } from "@/models/chat";
import { useChat } from "@/pages/LiveChat/context";
import { toTimeSince } from "@/pages/LiveChat/utils/date-format";
import styles from "./converstations.module.css";

export const ConversationCard = ({ converstation }: { converstation: Conversation }) => {
  const { chat, handleChatChange, toggleCustomerProfile } = useChat();
  const username = converstation.user.name ?? "";

  return (
    // eslint-disable-next-line
    <div
      role="button"
      className={`relative flex items-center space-x-4 ${styles.conversation_card} ${
        chat?._id === converstation._id ? "bg-[#ececec]" : ""
      }`}
      onClick={() => {
        handleChatChange(converstation);
        toggleCustomerProfile(true);
      }}
    >
      <AvatarPlaceHolder username={username} width={42} />
      {converstation.paused && (
        <img
          className="absolute top-2 left-6  w-4"
          src="/img/chat-icons/pause-circle.svg"
          alt="bot-paused-icon"
        />
      )}
      <div className="font-medium w-full">
        <div className="flex justify-between items-center">
          <div>{converstation.user.name}</div>
          <img
            className="w-4"
            src={`/img/icons/${converstation.platform}.svg`}
            alt={converstation.platform}
          />
        </div>
        <div className="flex justify-between items-center text-[8px] my-1">
          <div className="mr-2 w-[130px] truncate">{converstation.lastMessage?.text ?? ""}</div>
          <div className="text-gray-500">
            {toTimeSince(new Date(converstation.updatedAt || converstation.createdAt))}
          </div>
        </div>
      </div>
    </div>
  );
};
