import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { TrendInterval, Trends } from "@/models/metric";
import { IMetricApi } from "./IMetricApi";

@injectable()
export class MetricApi implements IMetricApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/metric";

  async getTrends(interval?: TrendInterval): Promise<Trends> {
    const fullUrl = `${this.baseUrl}/trends`;
    const { data } = await this.client.get<Trends>(fullUrl, { params: { interval } });
    return data;
  }
}
