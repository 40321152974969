import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { useAppDispatch } from "@/hooks/store-hooks";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { exposeToast } from "@/components/Toasts";
import { UserCredentials, SignedInUser } from "@/models/auth";
import { IAuthApi } from "@/api/auth";
import { authActions } from "@/store/reducers/auth.reducer";

export const useSignIn = () => {
  const authApi = useInjection<IAuthApi>(SERVICES_TYPES.AUTH);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation<SignedInUser, any, UserCredentials>(
    "auth-signin",
    (credentials) => authApi.signIn(credentials),
    {
      onSuccess(data) {
        // TODO: (Asim) complete successful signing process
        dispatch(authActions.authenticate(data));
        navigate("/", { replace: true });
      },
      onError() {
        // TODO: (Asim) display proper message depending on error type this only display 401 errors
        exposeToast({ type: "error", message: "Invaild email or password" });
      }
      // TODO: (Asim) Show proper message to the user
    }
  );
};

export const useCheckRegistration = () => {
  const authApi = useInjection<IAuthApi>(SERVICES_TYPES.AUTH);

  return useMutation<boolean, string, string>(
    "check-user",
    (email) => authApi.checkRegistration(email),
    {
      onSuccess(userExists) {
        if (userExists) exposeToast({ type: "error", message: "Email already exists" });
      }
    }
  );
};

export const useSignUp = () => {
  const authApi = useInjection<IAuthApi>(SERVICES_TYPES.AUTH);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useMutation<any, any, UserCredentials>(
    "auth-signup",
    (credentials) => authApi.signUp(credentials),
    {
      onSuccess(data) {
        // TODO: (Asim) complete successful signing process
        dispatch(authActions.authenticate(data));
        navigate("/", { replace: true });
      }
    }
  );
};
