export const AnimatedOrderDetails = () => (
  /*   <div className="flex items-center space-x-4 hover:bg-transparent">
    <div className="skeleton w-[42px] h-[42px] rounded-full" />
    <div>
      <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
    </div>
  </div> */
  <div className="py-14 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
    <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
      <div className="  flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
        <div className=" shadow-lg flex flex-col h-fit justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
          <div className="flex-row flex py-8">
            <div className="skeleton w-[42px] h-[42px] rounded-full" />
            <div className="mx-20 my-2">
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
            </div>
          </div>
          <div className="flex-row flex py-8">
            <div className="skeleton w-[42px] h-[42px] rounded-full" />
            <div className="mx-20 my-2">
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
            </div>
          </div>
          <div className="flex-row flex py-8">
            <div className="skeleton w-[42px] h-[42px] rounded-full" />
            <div className="mx-20 my-2">
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
            </div>
          </div>
        </div>
        <div className=" shadow-lg flex flex-col h-fit justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
          <div>
            <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
            <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
          </div>
        </div>
      </div>

      <div className="bg-gray-50 w-full xl:w-96 flex justify-between  md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col shadow-lg">
        <div className="flex  flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 ">
          <div className="flex flex-col justify-start items-start flex-shrink-0">
            <div className="skeleton w-[42px] h-[42px] rounded-full" />
            <div>
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
            </div>
          </div>
          <div className="flex flex-col justify-start items-start flex-shrink-0 pt-40">
            <div>
              <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
              <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  </div>
);
