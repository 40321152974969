import { Link } from "react-router-dom";
import { BreadCrumbItem } from "./BreadCrumbItem";

type _BreadCrumbItem = {
  text: string;
  path?: string;
  isActive: boolean;
};

type _BreadCrumbProps = {
  items: Array<_BreadCrumbItem>;
};

export const BreadCrumb = ({ items }: _BreadCrumbProps) => (
  <div className="">
    <ul className="flex justify-start items-center">
      <li>
        <Link to="/" className="flex items-center space-x-3">
          <img className="w-4" src="/img/icons/house-icon.svg" alt="house-icon" />

          <BreadCrumbItem>Main Menu</BreadCrumbItem>
          <img className="h-3 pr-3" src="/img/icons/right-arrow.svg" alt="house-icon" />
        </Link>
      </li>
      {items.map((item) => (
        <li key={item.text}>
          {!item.isActive ? (
            <Link to={item.path ?? "#"} className="flex items-center space-x-3">
              <BreadCrumbItem>{item.text}</BreadCrumbItem>
              {!item.isActive && (
                <img className="h-3 pr-3" src="/img/icons/right-arrow.svg" alt="house-icon" />
              )}
            </Link>
          ) : (
            <BreadCrumbItem isActive>{item.text}</BreadCrumbItem>
          )}
        </li>
      ))}
    </ul>
  </div>
);
