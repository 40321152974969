import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { Conversation, Conversations } from "@/models/chat";
import { HttpClient } from "@/services/client";
import { IConversationsApi } from "./IConversationsApi";

@injectable()
export class ConversationsApi implements IConversationsApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/conversation";

  async getConversations(skip: number = 0, query: string = ""): Promise<Conversation[]> {
    const {
      data: { conversations }
    } = await this.client.get<Conversations>(this.baseUrl, { params: { skip, query } });

    return conversations;
  }

  async getConversationById(id: string): Promise<Conversation> {
    const fullUrl = `${this.baseUrl}/${id}`;
    const { data } = await this.client.get<Conversation>(fullUrl);
    return data;
  }

  async toggleConversationStatus(id: string): Promise<Conversation> {
    const fullUrl = `${this.baseUrl}/toggle/${id}`;
    const { data } = await this.client.put<Conversation>(fullUrl);
    return data;
  }
}
