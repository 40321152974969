import { useMutation } from "react-query";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { useAppDispatch } from "@/hooks";
import { useFacebookSDK } from "@/services/facebook-sdk";
import { authActions } from "@/store/reducers/auth.reducer";
import { IUserApi } from "@/api/user";
import {
  FacebookAuthResponse,
  FacebookUser,
  InstagramUser,
  WabaAuthResponse,
  WhatsappUser
} from "@/models/auth";
import { AppConfig } from "@/services/config";
import { exposeToast } from "@/components/Toasts";

/* eslint-disable-next-line */
import { connected } from "process";

export const useFacebook = () => {
  const dispatch = useAppDispatch();
  const { facebookSDK } = useFacebookSDK();
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  const { mutate: connectToFacebook } = useMutation<FacebookUser, any, FacebookAuthResponse>(
    "facebook-auth",
    (values) => userApi.connectFacebook(values)
  );
  const connect = async () => {
    // eslint-disable-next-line
    const { status, authResponse }: fb.StatusResponse = await new Promise((resolve) => {
      facebookSDK?.login((res) => resolve(res), {
        scope: [
          "email",
          "public_profile",
          "pages_show_list",
          "pages_manage_metadata",
          "pages_messaging"
        ].join(","),
        return_scopes: true,
        enable_profile_selector: true
      });
    });

    if (status === "connected" && authResponse) {
      connectToFacebook(authResponse, {
        onSuccess(data) {
          dispatch(authActions.updateFacebookUser(data));
        }
      });
    }
  };

  return { connect };
};
export const useInstagram = () => {
  const dispatch = useAppDispatch();
  const { facebookSDK } = useFacebookSDK();
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  const { mutate: connectToInstagram } = useMutation<InstagramUser, any, FacebookAuthResponse>(
    "instagram-auth",
    (values) => userApi.connectInstagram(values)
  );
  const connect = async () => {
    // eslint-disable-next-line
    const { status, authResponse }: fb.StatusResponse = await new Promise((resolve) => {
      facebookSDK?.login((res) => resolve(res), {
        scope: [
          "email",
          "public_profile",
          "pages_show_list",
          "pages_messaging",
          "pages_manage_metadata",
          "instagram_basic",
          "instagram_manage_messages"
        ].join(","),
        return_scopes: true,
        enable_profile_selector: true
      });
    });
    if (status === "connected" && authResponse) {
      connectToInstagram(authResponse, {
        onSuccess(data) {
          dispatch(authActions.updateInstagramUser(data));
        }
      });
    }
  };

  return { connect };
};

export const useWhatsapp = () => {
  const dispatch = useAppDispatch();
  const { facebookSDK } = useFacebookSDK();
  const { wabaConfigID } = useInjection<AppConfig>(SERVICES_TYPES.CONFIGURATIONS);
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  const { mutate: connectToWhatsapp } = useMutation<WhatsappUser, any, WabaAuthResponse>(
    "whatsapp-auth",
    (values) => userApi.connectWhatsapp(values)
  );
  const connect = async () => {
    const wabaAuth: WabaAuthResponse | null = {
      payload: undefined,
      fbAuthResponse: undefined
    };
    // eslint-disable-next-line
    let payload: fb.WabaSignupDataPayload | null | undefined = null;
    // eslint-disable-next-line
    const { status, authResponse }: fb.StatusResponse = await new Promise(() => {
      const sessionInfoListener = (event: { origin: string; data: string }) => {
        if (event.origin !== "https://www.facebook.com") return;
        try {
          payload = JSON.parse(event.data);
        } catch {
          /* empty */
        }
      };
      window.addEventListener("message", sessionInfoListener);

      facebookSDK?.login(
        (response) => {
          if (response.authResponse) {
            // eslint-disable-next-line
            console.log(response);
            wabaAuth.fbAuthResponse = response.authResponse;
            wabaAuth.payload = payload;

            // eslint-disable-next-line
            console.log(response.status, response.authResponse, payload);
            if (response.status === "connected" && response.authResponse && payload) {
              // eslint-disable-next-line
              connectToWhatsapp(
                {
                  payload,
                  fbAuthResponse: response.authResponse
                },
                {
                  onSuccess(data) {
                    dispatch(authActions.updateWhatsappUser(data));
                  },
                  onError(err) {
                    // eslint-disable-next-line
                    console.log(err);
                  }
                }
              );
            }
            window.removeEventListener("message", sessionInfoListener);
          } else {
            exposeToast({
              type: "warning",
              message: "Whatsapp login stopped midway."
            });
          }
        },
        {
          scope: [
            "email",
            "public_profile",
            "pages_show_list",
            "pages_messaging",
            "pages_manage_metadata",
            "business_management",
            "whatsapp_business_messaging",
            "whatsapp_business_management"
          ].join(","),
          return_scopes: true,
          enable_profile_selector: true,
          config_id: wabaConfigID,
          response_type: "code",
          override_default_response_type: true,
          extras: {
            sessionInfoVersion: 2
          }
        }
      );
    });
  };

  return { connect };
};

export const useFacebookDisconnect = () => {
  const dispatch = useAppDispatch();

  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation("disconnectFa", () => userApi.disconnectFacebook(), {
    onSuccess() {
      dispatch(authActions.updateFacebookUser(null));
    }
  });
};

export const useInstagramDisconnect = () => {
  const dispatch = useAppDispatch();

  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation("disconnectIns", () => userApi.disconnectInstagram(), {
    onSuccess() {
      dispatch(authActions.updateInstagramUser(null));
    }
  });
};
