import { useSocketIOEvent } from "@/services/socket-io";
import { useParams } from "react-router-dom";
import { AllChatsButton, ChatHeader, ChatInput, ChatMessages } from "./components/Chat";
import { useConversation, useMessages } from "./hooks/chat-hooks";
import { ChatProvider, useChat } from "./context";
import { Customer } from "./components/Customer";

const SingleChatPage = () => {
  const { chatId } = useParams();
  const { handleChatChange, showProfile } = useChat();

  const { data: chat } = useConversation(chatId ?? "");
  const { data: messages, isLoading, refetch } = useMessages(chatId ?? "");
  useSocketIOEvent("message:new", () => {
    refetch();
  });

  if (chat) handleChatChange(chat);

  return (
    <div style={{ width: showProfile ? "calc(100% - 270px)" : "100%" }}>
      {chat && <ChatHeader chat={chat} />}
      <ChatMessages messages={messages ?? []} isLoading={isLoading} />
      <ChatInput chat={chat} />
    </div>
  );
};

// eslint-disable-next-line
export default () => (
  <ChatProvider>
    <AllChatsButton />
    <div className="flex justify-between  px-5 pt-5">
      <SingleChatPage />
      <Customer />
    </div>
  </ChatProvider>
);
