import { Notification } from "@/models/notifications";
import styles from "./notifications.module.css";

const formatNotifcationDateString = (date: string) => {
  const todayDate = new Date();
  const notificaitonDate = new Date(date);

  const isToday = todayDate.toDateString() === notificaitonDate.toDateString();

  if (isToday)
    return `Today, ${notificaitonDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit"
    })}`;
  return `${notificaitonDate.toLocaleDateString("en-UK")} - ${notificaitonDate.toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit"
    }
  )}`;
};

const resolveIconName = (body: string) => {
  let iconName = "";
  if (body.includes("facebook")) {
    iconName = "facebook";
  } else if (body.includes("instagram")) {
    iconName = "instagram";
  } else if (body.includes("whatsapp")) {
    iconName = "whatsapp";
  } else if (body.includes("waiting")) {
    iconName = "truck";
  } else {
    iconName = "globe-solid";
  }
  return iconName;
};

export const NotificationCard = ({
  notification,
  onNotificationClicked
}: {
  notification: Notification;
  onNotificationClicked: Function;
}) => {
  const iconName = resolveIconName(notification.body);

  return (
    // eslint-disable-next-line
    <div
      role="button"
      className={`my-3 p-4 ${styles.notification_card}`}
      onClick={() => onNotificationClicked()}
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <img className="w-4" src="/img/icons/calendar-days-solid.svg" alt="notificaiton-date" />
          <p className="text-xs px-2">{formatNotifcationDateString(notification.createdAt)}</p>
        </div>

        {!notification.read && (
          <span className="badge badge-sm bg-[#A10E35] border-[#A10E35] text-white">New</span>
        )}
      </div>
      <div className="font-medium">
        <div className="flex justify-start items-center my-2">
          <img className="w-4" src={`/img/icons/${iconName}.svg`} alt={iconName} />
          <div className="text-sm m-2" style={{ wordBreak: "break-all" }}>
            {notification.body}
          </div>
        </div>
        <div className="text-sm">{notification.title}</div>
      </div>
    </div>
  );
};
