/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Activity } from "@/models/customers";
import parseISO from "date-fns/parseISO";
import { activities } from "./Activities";

type Props = {
  activity: Activity[];
};

export const CustomerActivities = ({ activity }: Props) => (
  <div className="ml-5 text-xs flex flex-col text-center">
    <span> {activity[activity.length - 1].activity}</span>
    <span className=" text-[10px] text-gray-500">
      {parseISO(activity[activity.length - 1].time).toLocaleString()}
    </span>
    <span
      className=" text-blue-700 text-center cursor-pointer"
      onClick={() => activities({ activities: activity })}
    >
      View all Activites
    </span>
  </div>
);
