import { IVerifyApi } from "@/api/account-verification";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { useMutation, useQuery } from "react-query";

export const useVerifyUser = (key: string) => {
  const verifyApi = useInjection<IVerifyApi>(SERVICES_TYPES.VERIFY);
  return useQuery(["user", key], () => verifyApi.verifyUser(key));
};

export const usePasswordReset = () => {
  const verifyApi = useInjection<IVerifyApi>(SERVICES_TYPES.VERIFY);
  return useMutation("user-reset", (data: { password: string; key: string }) =>
    verifyApi.resetPassword(data)
  );
};
export const useResetRequest = () => {
  const verifyApi = useInjection<IVerifyApi>(SERVICES_TYPES.VERIFY);
  return useMutation("user-reset", (data: { email: string }) => verifyApi.resetRequest(data));
};
