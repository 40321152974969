/* eslint-disable no-nested-ternary */
import { AvatarPlaceHolder } from "@/components/AvatarPlaceHolder";
import { Order } from "@/models/orders";
import React from "react";

type Props = {
  order: Order;
};

export const CustomerItem = ({ order }: Props) => (
  <div className="flex space-x-3 w-56">
    <div className="">
      <AvatarPlaceHolder username={order.customer.name} width={56} />
    </div>
    <div>
      {" "}
      <div>{order.customer.name.trim()}</div>
      <div>
        <div className=" ml-3">
          {order?.platform === "whatsapp" ? (
            <img className=" w-7" src="/img/icons/whatsapp.svg" alt="whatsapp" />
          ) : order?.platform === "facebook" ? (
            <img className="w-7   rounded-md" src="/img/icons/facebook.svg" alt="facebook" />
          ) : order.platform === "instagram" ? (
            <img className="w-7" src="/img/icons/instagram.svg" alt="instagram" />
          ) : (
            <img className="w-7" src="/img/order-icons/shop.svg" alt="manual" />
          )}
        </div>
      </div>
    </div>
  </div>
);
