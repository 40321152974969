import { Link } from "react-router-dom";
import styles from "../chat.module.css";

export const AllChatsButton = () => (
  <Link
    to="/live-chat"
    className={`${styles.all_chats_button} flex justify-start items-center space-x-2`}
  >
    <p>See all chats</p>
    <img className="w-3 h-5" src="/img/chat-icons/angle-right-solid.svg" alt="angel_right" />
  </Link>
);
