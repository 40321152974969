import { Order } from "@/models/orders";
import React from "react";

type Props = {
  order: Order;
};

export const SummeryCard = ({ order }: Props) => (
  <div className="shadow-lg flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
    <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
      <h3 className="text-xl font-semibold leading-5 text-gray-800">Summary</h3>
      <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
        <div className="flex justify-between  w-full">
          <p className="text-base leading-4 text-gray-800">Subtotal</p>
          <p className="text-base leading-4 text-gray-600">
            {order.delivery
              ? Number(order?.totalPrice) - Number(order?.delivery.price)
              : Number(order?.totalPrice)}{" "}
            (SDG)
          </p>
        </div>
        {/*         <div className="flex justify-between items-center w-full">
          <p className="text-base leading-4 text-gray-800">
            Discount{" "}
            <span className="bg-gray-200 p-1 text-xs font-medium leading-3  text-gray-800">
              STUDENT
            </span>
          </p>
          <p className="text-base leading-4 text-gray-600">-$28.00 (50%)</p>
        </div> */}
        {order.delivery && (
          <div className="flex justify-between items-center w-full">
            <p className="text-base leading-4 text-gray-800">Delivery</p>
            <p className="text-base leading-4 text-gray-600">{order.delivery.price} (SDG)</p>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center w-full">
        <p className="text-base font-semibold leading-4 text-gray-800">Total</p>
        <p className="text-base font-semibold leading-4 text-gray-600">
          {Number(order?.totalPrice)} (SDG)
        </p>
      </div>
    </div>
  </div>
);
