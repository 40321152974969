import { Conversation } from "@/models/chat";

export const checkIfAllowedToSend = (chat?: Conversation) => {
  if (!chat || !chat.lastMessage) return false;

  const {
    lastMessage: { createdAt }
  } = chat;

  if (createdAt) {
    const createdDate = new Date(createdAt);
    const todayDate = new Date();
    const msBetweenDates = todayDate.getTime() - createdDate.getTime();
    // 👇️ convert ms to hours                  min  sec   ms
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
    return hoursBetweenDates < 24;
  }
  return false;
};
