import { INotificationsApi } from "@/api/notifications";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { NotificationChannel } from "@/models/notifications";
import { useInfiniteQuery, useMutation } from "react-query";

export const useNotifications = (channel: NotificationChannel = "general", skip: number = 0) => {
  const notificationApi = useInjection<INotificationsApi>(SERVICES_TYPES.NOTIFICATIONS);

  return useInfiniteQuery(
    [`notifications-${channel}-${skip}`],
    () => notificationApi.getNotifications(channel, skip),
    {
      getNextPageParam: (lastPage, pages) => {
        // TODO: (Asim) double check page size and why its not implemented in the backend
        if (lastPage.length < 10) return undefined;
        return pages.length * 10;
      }
    }
  );
};

export const useMarkNotificationAsRead = (channel: NotificationChannel = "general") => {
  const notificationApi = useInjection<INotificationsApi>(SERVICES_TYPES.NOTIFICATIONS);

  const { refetch } = useNotifications(channel, 0);

  return useMutation<any, any, string>(
    "read-single-notification",
    (id) => notificationApi.markNotificationAsRead(id),
    {
      onSuccess() {
        refetch();
      }
    }
  );
};

export const useMarkChannelAsRead = (channel: NotificationChannel = "general") => {
  const notificationApi = useInjection<INotificationsApi>(SERVICES_TYPES.NOTIFICATIONS);

  const { refetch } = useNotifications(channel, 0);

  return useMutation<any, any, NotificationChannel>(
    "read-notification-channel",
    (channel) => notificationApi.markChannelAsRead(channel),
    {
      onSuccess() {
        refetch();
      }
    }
  );
};
