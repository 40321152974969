import { FileMaybe } from "./FileValidator";

type FileValidatorFuncConfig = {
  onFail?: () => any;
};

export const imageFileValidator = (
  file: FileMaybe,
  config: FileValidatorFuncConfig & { mimeTypes: string[] }
) => {
  if (file) {
    if (config.mimeTypes.includes(file.type)) return file;
  }

  if (config?.onFail) config.onFail();

  return null;
};
