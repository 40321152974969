import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { UploadDropzone, SelectField, TextField } from "@/components/Forms";
import { MediaNodeData, MediaNodeTypes } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

const getFileFromUrl = async ({ filename, url }: MediaNodeData) => {
  const response = await fetch(url);
  const data = await response.blob();
  const file = new File([data], filename, { type: data.type });
  return file;
};

export const MediaNodeForm = () => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<MediaNodeData>();

  const mediaType = watch("mediaType");
  const media = watch("media");
  const url = watch("url");

  const onDrop = (files: File[]) => {
    if (files.length > 0) {
      setValue("media", files[0]);
      setValue("url", "");
    }
  };

  useEffect(() => {
    const values = getValues();
    if (!media && url) {
      const getFile = async () => {
        const mediaFile = await getFileFromUrl(values);
        setValue("media", mediaFile);
      };
      getFile();
    }
  }, [media, url]);

  const allowedFiles = {
    image: "image/*",
    sticker: "image/*",
    video: "video/*",
    audio: "audio/*",
    file: undefined
  }[mediaType] as string;

  return (
    <NodeFormWrapper description="Sends a media attachment to your users.">
      <SelectField
        name="mediaType"
        register={register}
        label="Media Type"
        options={Object.entries(MediaNodeTypes).map(([label, value]) => ({
          value,
          label
        }))}
        error={errors.mediaType?.message}
      />
      <UploadDropzone
        allowedFiles={allowedFiles}
        // Max Size allowed is 5 MB
        maxSize={5 * 1024 * 1024}
        onDrop={onDrop}
        error={errors.media?.message}
      />
      {media && (
        <div className="flex space-x-2 items-center w-full">
          <div className="bg-yellow-100 text-yellow-800 text-xs font-medium px-[20px] py-[10px] rounded-full">
            {media.name}
          </div>
          {/* eslint-disable-next-line */}
          <div
            className="hover:bg-red-100 hover:rounded-md p-[8px] cursor-pointer"
            onClick={() => {
              setValue("media", undefined);
              setValue("filename", "");
              setValue("url", "");
            }}
          >
            <img className="w-4" src="/img/flow-icons/trash-can-red.svg" alt="trash_node" />
          </div>
        </div>
      )}
      <TextField
        name="label"
        register={register}
        label="Button Title"
        error={errors.label?.message}
      />
      <TextField
        isRtl
        name="labelAR"
        register={register}
        label="اسم الزر"
        error={errors.labelAR?.message}
      />
    </NodeFormWrapper>
  );
};
