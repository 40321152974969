import { useChat } from "@/pages/LiveChat/context";

export const ChatMicButton = () => {
  const {
    recorder: { startRecording, status, stopRecording },
    resetRecordTimer
  } = useChat();

  const isRecording = status === "recording" || status === "paused";

  return (
    // eslint-disable-next-line
    <div
      role="button"
      className="bg-[#044cac] rounded-full p-[9px]"
      onClick={() => {
        if (isRecording) {
          stopRecording();
          resetRecordTimer();
        } else startRecording();
      }}
    >
      <img
        className="w-6"
        src={`/img/chat-icons/${isRecording ? "arrow-up-upload" : "microphone-solid"}.svg`}
        alt="send_icon"
      />
    </div>
  );
};
