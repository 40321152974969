import { useQueryClient } from "react-query";
import { useSocketIOEvent } from "@/services/socket-io";
import { useChat } from "@/pages/LiveChat/context";
import { useMessages } from "@/pages/LiveChat/hooks/chat-hooks";
import { ChatMessages, ChatHeader, ChatInput } from "./components";
import styles from "./chat.module.css";

export const Chat = () => {
  const { chat, showProfile } = useChat();
  const client = useQueryClient();
  const { data: messages, isLoading, refetch } = useMessages(chat?._id);
  useSocketIOEvent("message:new", () => {
    refetch();
    client.refetchQueries({ queryKey: "conversations" });
  });

  if (!chat)
    return (
      <div className={`${styles.empty_chat_message} font-medium`}>
        Select a chat to start messaging
      </div>
    );

  return (
    <div
      className={`${styles.chat_container}`}
      style={{ width: showProfile ? "calc(100% - 550px)" : "calc(100% - 300px)" }}
    >
      <ChatHeader chat={chat} />
      <ChatMessages messages={messages ?? []} isLoading={isLoading} />
      <ChatInput chat={chat} />
    </div>
  );
};
