import { IFlowElementApi } from "@/api/flow-element";
import { exposeToast } from "@/components/Toasts";
import { useAppDispatch } from "@/hooks";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import {
  FlowElementTypes,
  IEdgeElement,
  INodeElement,
  INodesDataTypes,
  MediaNodeTypes
} from "@/models/flow";
import { flowActions } from "@/store/reducers/flow.reducer";
import { Node } from "react-flow-renderer";
import { useMutation, useQuery } from "react-query";

export const useFlowElements = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();
  const result = useQuery("flow-element", () => flowElementApi.getAllElements(), {
    onSuccess(data) {
      const nodes = data.filter(
        (element) => element.type !== FlowElementTypes.Edge
      ) as INodeElement[];
      const edges = data.filter(
        (element) => element.type === FlowElementTypes.Edge
      ) as IEdgeElement[];
      dispatch(flowActions.setNodes(nodes));
      dispatch(flowActions.setEdges(edges));
    }
  });

  return { ...result };
};

export const useAddNewNode = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<INodeElement, any, Partial<INodeElement>>(
    "add-new-node",
    (element) => flowElementApi.addNode(element),
    {
      onSuccess(data) {
        dispatch(flowActions.addNode(data));
      }
    }
  );
};

export const useAddNewEdge = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<IEdgeElement, any, Partial<IEdgeElement>>(
    "add-new-edge",
    (element) => flowElementApi.addEdge(element),
    {
      onSuccess(data) {
        dispatch(flowActions.addEdge(data));
      }
    }
  );
};

export const useUpdateNodePosition = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<INodeElement, any, Node>(
    "update-node-position",
    (moved) => flowElementApi.updateNodePosition(moved.id!, moved.position),
    {
      onSuccess(data) {
        dispatch(flowActions.updateElement(data));
      }
    }
  );
};

export const useDeleteElement = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<void, any, string>(
    "remove-node",
    (nodeId) => flowElementApi.deleteElement(nodeId),
    {
      onSuccess(_data, nodeId) {
        dispatch(flowActions.removeNode(nodeId));
      }
    }
  );
};

export const useDisconnectNode = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<IEdgeElement[], any, string>(
    "disconnect-node",
    (nodeId) => flowElementApi.disconnectNode(nodeId),
    {
      onSuccess(removedEdges) {
        if (removedEdges.length > 0) dispatch(flowActions.removedEdges(removedEdges));
      }
    }
  );
};

export const useUpdateElement = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<INodeElement, any, { nodeId: string; data: INodesDataTypes }>(
    "update-element",
    ({ nodeId, data }) => flowElementApi.updateElement(nodeId, data),
    {
      onSuccess(updatedNode) {
        dispatch(flowActions.updateElement(updatedNode));
        exposeToast({ type: "success", message: "Node has been updated successfully" });
      },
      onError() {
        exposeToast({ type: "error", message: "Some error happened. Please try again" });
      }
    }
  );
};

export const useUpdateElementMedia = () => {
  const flowElementApi = useInjection<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT);
  const dispatch = useAppDispatch();

  return useMutation<INodeElement, any, { nodeId: string; type: MediaNodeTypes; media: File }>(
    "update-media-element",
    ({ nodeId, type, media }) => flowElementApi.updateElementMedia(nodeId, type, media),
    {
      onSuccess(updatedNode) {
        dispatch(flowActions.updateElement(updatedNode));
      }
    }
  );
};
