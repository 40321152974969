import { NotificationChannel } from "@/models/notifications";
import { useMarkChannelAsRead } from "../../hooks/notifications-hooks";
import styles from "./notifications.module.css";

export const NotificationTitle = ({
  channel,
  unreadCount
}: {
  channel: NotificationChannel;
  unreadCount?: number;
}) => {
  const { mutate: readChannel } = useMarkChannelAsRead(channel);

  return (
    <div className={`flex justify-between ${styles.notification_title}`}>
      <div className="text-[20px] font-bold text-[#324B4A]">
        {channel === "general" ? "Notifications" : "Inbox"}
      </div>
      {/* eslint-disable-next-line */}
      <button
        role="button"
        className="btn btn-sm btn-error text-[12px]"
        disabled={unreadCount === 0}
        onClick={() => {
          readChannel(channel);
        }}
      >
        Clear All
      </button>
    </div>
  );
};
