/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useForm } from "react-hook-form";
import { useAppSelector } from "@/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { Product } from "@/models/Products";
import { exposeToast } from "@/components/Toasts";
import { imagesPreviewDialog } from "@/components/Modals";
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";
import { useState } from "react";
import { useAllProducts } from "@/pages/Products/hooks";
import { FileUploader, Select, TextFieldWithIcon } from "@/components/Forms";
import { useDeleveryAreas } from "@/pages/Configurations/hooks/useDeliveryConfig";
import { NewOrder } from "@/models/orders";
import { ManualOrderValidationSchema } from "../../utils/ValidationSchema";
import { selectItemsModal } from "./SelectItemsModal";
import { useAddOrder } from "../../hooks/order-hooks";

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}
export const ManualOrder = () => {
  const [animate, setAnimate] = useState(false);
  const { data: prods } = useAllProducts();
  const { mutate: submitOrder, isLoading: isSubmitting } = useAddOrder();
  const user = useAppSelector((state) => state.auth.user);
  const { data: deliverAreas, isLoading } = useDeleveryAreas();
  const [show, setShow] = useState(false);
  const [images, setImages] = useState<[]>([]);
  const [checkout, setCheckout] = useState<{ product: Product; amount: number }[]>([]);
  const paymentMethods: string[] = ["cash", "mbokDirect", "mbokGateway"];
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<NewOrder>({
    defaultValues: {
      name: "",
      phone: "",
      location: "",
      paymentMethod: "",
      delivery: ""
    },
    resolver: yupResolver(ManualOrderValidationSchema())
  });

  const onSubmit = async (data: any) => {
    if (checkout.length <= 0) {
      exposeToast({ type: "error", message: "Please Select Products" });
      setAnimate(true);
      await timeout(2000).then(() => setAnimate(false));
    } else {
      let deliveryAreaId = null;
      if (deliverAreas!.length > 0 && user?.configOptions.deliveryEnabled)
        deliveryAreaId = deliverAreas?.filter((d) => d.name === data.delivery)[0]._id || null;
      const products = Array.from(checkout.map((c) => ({ id: c.product._id, amount: c.amount })));
      const order = {
        ownerId: user?._id,
        platform: "Manual",
        customer: {
          id: data.phone,
          name: data.name,
          phone: data.phone,
          location: data.location
        },
        paymentMethod: data.paymentMethod,
        products,
        deliveryAreaId,
        mbokImages: images
      };
      submitOrder(order, {
        onSuccess() {
          reset();
          setCheckout([]);
          setImages([]);
          setShow(false);
        }
      });
    }
  };
  if (isLoading) return <div>....</div>;
  return (
    <div className="dropdown dropdown-left">
      <div
        role="button"
        className="cursor-pointer  flex space-x-2 flex-row text-white bg-primary-700 rounded-md p-3 items-center w-20"
        onClick={() => setShow(!show)}
        tabIndex={0}
      >
        <span>Add</span>
        <img className=" w-5" src="/img/sidebar-icons/cart-shopping-solid.svg" alt="." />
      </div>
      {/*       <Button onClick={() => setShow(!show)} tabIndex={0} variant="btn-block" className="btn m-1">
        <span>
          <img className=" w-5" src="/img/sidebar-icons/cart-shopping-solid.svg" alt="." />
        </span>
      </Button> */}
      <div
        tabIndex={0}
        className={`flex  ${
          show ? "block" : "hidden"
        } card card-compact w-fit sm:w-80 p-2 text-primary-content absolute right-1 z-[30000000000] `}
      >
        <div className="flex justify-center">
          <Card>
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <TextFieldWithIcon
                name="name"
                icon="user.svg"
                label="Customer Name"
                placeholder="Customer Name"
                register={register}
                error={errors.name?.message}
              />
              <TextFieldWithIcon
                name="phone"
                icon="phone.svg"
                label="Phone"
                placeholder="+249 "
                register={register}
                error={errors.phone?.message}
              />
              <TextFieldWithIcon
                name="location"
                icon="map.svg"
                label="Customer Location"
                placeholder="eg. khartoum"
                register={register}
                error={errors.location?.message}
              />
              {user?.configOptions.deliveryEnabled && deliverAreas!.length > 0 && (
                <Select
                  name="delivery"
                  label="delivery area"
                  placeholder="Select delivery area"
                  register={register}
                  options={deliverAreas!.map((d) => d.name)}
                  error={errors.delivery?.message}
                />
              )}
              <Select
                name="paymentMethod"
                label="Payment Method"
                placeholder="Select Payment Method"
                register={register}
                options={paymentMethods}
                error={errors.paymentMethod?.message}
              />
              <FileUploader
                onChange={(e: any) => {
                  const { files } = e.target;
                  imagesPreviewDialog({
                    images: Array.from(files).map((image: any) => URL.createObjectURL(image)),
                    accept: async () => {
                      const imageUrls: any = await Promise.all(
                        Array.from(files).map(
                          (image: any) =>
                            new Promise((resolve) => {
                              const reader = new FileReader();
                              reader.onload = (upload: any) => resolve(upload.target.result);
                              reader.readAsDataURL(image);
                            })
                        )
                      );
                      setImages(imageUrls);
                    }
                  });
                }}
                accept="image/*"
                label="Upload Receipt"
              />
              <div className="flex">
                <Button
                  variant="btn-accent"
                  onClick={() => {
                    selectItemsModal({
                      products: prods!,
                      checkout,
                      accept: (selected) => {
                        setCheckout(selected);
                      }
                    });
                  }}
                >
                  Select Items
                </Button>
                <div
                  onClick={() => {
                    selectItemsModal({
                      products: prods!,
                      checkout,
                      accept: (selected) => {
                        setCheckout(selected);
                      }
                    });
                  }}
                  className={`ml-16 mt-2 cursor-pointer  hover:bg-blue-100 hover:rounded-md ${
                    animate ? " animate-bounce" : ""
                  }`}
                >
                  <span className="absolute ml-5 text-sm  bg-red-800 rounded-full px-1 text-white  w-5 h-5 text-center items-center">
                    {checkout ? checkout.length : 0}
                  </span>
                  <img className=" w-8 " src="/img/order-icons/cart.svg" alt="." />
                </div>
              </div>

              <div className="flex justify-between">
                <Button
                  isLoading={isSubmitting}
                  variant="btn-wide"
                  onClick={() => handleSubmit(onSubmit)()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
