import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldWithIcon } from "@/components/Forms";
import { SettingsFormWrapper } from "@/components/Settings";
import { User, UserCredentials } from "@/models/auth";
import { AccountSettingsFormValidationSchema } from "./utils/validationSchema";
import { useUpdateMe } from "../../hooks/useUpdateMe";

type Props = {
  user: User;
};
export const AccountSettingsForm = ({ user }: Props) => {
  const { mutate: updateUser } = useUpdateMe();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserCredentials>({
    defaultValues: {
      name: user?.name,
      email: user?.email
    },
    resolver: yupResolver(AccountSettingsFormValidationSchema())
  });

  const onUpdate = (data: UserCredentials) => {
    updateUser(data);
  };
  return (
    <SettingsFormWrapper onSubmit={() => handleSubmit(onUpdate)()}>
      <TextFieldWithIcon
        name="name"
        icon="user.svg"
        label="Name"
        placeholder="user@example.com"
        register={register}
        error={errors.name?.message}
      />
      <TextFieldWithIcon
        name="email"
        icon="envelope.svg"
        label="Email"
        placeholder="user@example.com"
        register={register}
        error={errors.email?.message}
      />
    </SettingsFormWrapper>
  );
};
