import { Doughnut } from "react-chartjs-2";
import { SocialPlatforms } from "@/models/constants";
import styles from "./charts.module.css";

type _DoughnutChartProps = { platform: SocialPlatforms; value: number };

export const PlatformsDoughnutChart = ({
  title,
  isLoading,
  data
}: {
  title: string;
  isLoading?: boolean;
  data: Array<_DoughnutChartProps>;
}) => (
  <div className={` w-[450px] ${styles.chart_card_container}`}>
    <h1 className="py-5 text-xl font-semibold">{title}</h1>
    {isLoading && (
      <div className="flex m-[100px]">
        <div className="spinner-loader m-auto w-14 h-14" />
      </div>
    )}
    {!isLoading && (
      <Doughnut
        data={{
          labels: Object.values(SocialPlatforms),
          datasets: [
            {
              data: data.map((d) => d.value),
              borderColor: ["rgb(59, 89, 162)", "rgb(255, 130, 111)", "rgb(34, 193, 151)"],
              backgroundColor: [
                "rgba(59, 89, 162, 0.7)",
                "rgba(255, 130, 111, 0.7)",
                "rgba(34, 193, 151, 0.7)"
              ],
              borderWidth: 2
            }
          ]
        }}
        options={{
          plugins: { legend: { position: "right" } },
          radius: 120,
          responsive: true
        }}
      />
    )}
  </div>
);
