import { Order } from "@/models/orders";
import { Calender } from "./Calender";

type Props = {
  order: Order;
  onChange: () => void;
};

export const DeliveryItem = ({ order, onChange }: Props) => (
  <div className="flex  items-center w-56 justify-center">
    {order.delivery ? (
      <div className="flex flex-col items-center">
        <div className="flex flex-row items-center space-x-2">
          <img className="w-10" src="/img/icons/truck.svg" alt="a" />
          <div>{order.delivery.name}</div>
        </div>
        <Calender onChange={() => onChange()} order={order} />
      </div>
    ) : (
      <div className=" flex flex-col items-center">
        <img className="w-10" src="/img/icons/truck.svg" alt="a" /> <div>Not Enabled</div>
      </div>
    )}
  </div>
);
