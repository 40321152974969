import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { ToastWrapper } from "./components";

const TOAST_TIMEOUT = 5000;

export enum ToastTypes {
  Error = "error",
  Info = "info",
  Warning = "warning",
  Success = "success"
}

export type ToastType = `${ToastTypes}`;

type _ToastProps = {
  show: boolean;
  type: ToastType;
  message: string;
  wrapperId?: string;
  onClose?: () => void;
};

export const Toast = ({ show, type, message, onClose, ...rest }: _ToastProps) => {
  let icon = "";
  let classes = "";

  const [visible, setVisible] = useState(show);

  switch (type) {
    case "error":
      icon = "red-close.svg";
      classes = "alert-error";
      break;
    case "success":
      classes = "alert-success";
      icon = "check-icon.svg";
      break;
    case "warning":
      classes = "alert-warning";
      icon = "warning-icon.svg";
      break;
    case "info":
      classes = "alert-info";
      icon = "info-icon.svg";
      break;
    default:
      break;
  }

  useEffect(() => {
    if (show && !visible) setVisible(true);

    setTimeout(() => {
      // eslint-disable-next-line
      onClose && onClose();
      setVisible(false);
    }, TOAST_TIMEOUT);
  }, [show]);

  return (
    <ToastWrapper show={visible} {...rest}>
      <div className={`alert ${classes} shadow-lg`}>
        <div>
          {icon && <img className="w-6" src={`/img/icons/${icon}`} alt={`toast - ${type}`} />}
          <span>{message}</span>
        </div>
      </div>
    </ToastWrapper>
  );
};

export const exposeToast = ({ type, message }: Omit<_ToastProps, "show">) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = `${type}--toast`;

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    root.render(
      <Toast
        show
        type={type}
        message={message}
        wrapperId={wrapper.id}
        onClose={() => {
          resolve(false);
        }}
      />
    );
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
