import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";

export type SelectOption = {
  label: string;
  value: string;
};

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  defaultOption?: SelectOption;
  required?: boolean;
  disabled?: boolean;
  error?: string;
};

type _SelectProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"select">, keyof _BaseProps<T>>;

export const SelectField = <T extends FieldValues>({
  label,
  name,
  register,
  disabled = false,
  error,
  options = [],
  defaultOption = { label: "Select an option", value: "" },
  ...rest
}: _SelectProps<T>) => {
  const id = useId();
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <select
        id={id}
        className={`bg-white border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800
        ${disabled ? "bg-gray-100 cursor-not-allowed" : ""} 
        ${
          error
            ? "bg-red-50  border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
            : ""
        }
        `}
        {...register(name)}
        disabled={disabled}
        defaultValue={defaultOption.value}
        {...rest}
      >
        <option disabled value={defaultOption.value}>
          {defaultOption.label}
        </option>
        {options.map((option: SelectOption) => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FormErrorMessage error={error} />
    </div>
  );
};
