import * as yup from "yup";

export const AddCategoryValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    arabicName: yup
      .string()
      .required()
      .max(20, "Maximum length 20 chars")
      .min(5, "Minimum length 5 chars"),
    desc: yup.string().required().min(5, "Minimum length 5 chars"),
    arabicDesc: yup.string().required().min(5, "Minimum length 5 chars")
  });
