import { Table, TableItem } from "@/components/Tables";

export const AnimatedProductsTable = () => (
  <Table headers={["Name", "Price", "Income", "Ordered", "Hide/Show", "Actions"]}>
    {Array.from([1, 2, 3, 4, 5, 6, 7]).map((n, index) => (
      <tr
        className={`border-b border-gray-200  hover:bg-gray-100 ${
          index % 2 === 0 ? "bg-gray-50" : ""
        } cursor-pointer`}
        key={n}
      >
        <TableItem>
          <div className="skeleton w-[42px] h-[42px] rounded-full" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <div className="skeleton w-[42px] h-[42px] rounded-full" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
      </tr>
    ))}
  </Table>
);
