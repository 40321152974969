/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

type _BaseProps = {
  label?: string;
  onChange: any;
  accept: string;
  // error?: ValidationError | string;
};

type _InputProps = _BaseProps & Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps>;

export const FileUploader = ({ label, onChange, accept }: _InputProps) => (
  <div>
    <label
      className="lock mb-2 text-sm font-medium text-black dark:text-black"
      htmlFor="multiple_files"
    >
      {label}
    </label>
    <input
      aria-placeholder="ds"
      id="multiple_files"
      onChange={onChange}
      type="file"
      accept={accept}
      multiple
      className="file-input file-input-bordered  w-full max-w-xs"
    />
  </div>
);
