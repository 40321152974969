import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { Message, MessageReplayPayload, Messages } from "@/models/chat";
import { HttpClient } from "@/services/client";
import { IMessagesApi } from "./IMessagesApi";

@injectable()
export class MessagesApi implements IMessagesApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/message";

  async getMessages(conversationId: string): Promise<Message[]> {
    if (!conversationId) return [];

    const fullUrl = `${this.baseUrl}/${conversationId}`;
    const {
      data: { messages }
    } = await this.client.get<Messages>(fullUrl);

    return messages;
  }

  async sendReplay(conversationId: string, payload: MessageReplayPayload): Promise<void> {
    const fullUrl = `${this.baseUrl}/${conversationId}`;

    await this.client.post(fullUrl, payload);
  }

  async getPositiveFeeds(): Promise<number[]> {
    const fullUrl = `${this.baseUrl}/positive/feed`;
    const { data } = await this.client.get<number[]>(fullUrl);
    return data;
  }
}
