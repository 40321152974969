import { useFormContext } from "react-hook-form";
import { SelectField, TextField } from "@/components/Forms";
import { AnswerTypes, InputQuestionTypes, UserInputNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const UserInputNodeForm = () => {
  const { register, watch } = useFormContext<UserInputNodeData>();
  const questionType = watch("questionType");

  return (
    <NodeFormWrapper description="This prompts the customer for some input.">
      <TextField name="questionText" register={register} label="Prompt Message" />
      <TextField name="questionTextAR" register={register} label="رسالة الاستفسار" isRtl />
      <SelectField
        name="questionType"
        register={register}
        label="Input Type"
        options={Object.entries(InputQuestionTypes).map(([label, value]) => ({
          label,
          value
        }))}
      />
      <SelectField
        name="answerType"
        register={register}
        label="Answer Type"
        options={Object.entries(AnswerTypes).map(([label, value]) => ({
          label,
          value
        }))}
      />
      {questionType === InputQuestionTypes.Custom && (
        <TextField name="fieldName" register={register} label="Field Name" />
      )}
    </NodeFormWrapper>
  );
};
