import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { Activity } from "@/models/customers";
import { parseISO } from "date-fns";

type CategoryEditeProps = {
  show: boolean;
  activities: Activity[];
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const Activites = ({ activities, show, onClose, ...rest }: CategoryEditeProps) => {
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };
  return (
    <BaseDialog
      title="Customer Activities"
      show={visible}
      body={
        <ol className="relative  border-l-2 border-blue-700 ">
          {activities.map((activity) => (
            <li key={activity._id} className="mb-10 ml-6">
              <span className="absolute flex items-center justify-center w-10 h-10 bg-blue-100 rounded-full -left-6 ring-4 ring-blue-700 ">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-blue-800 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>

              <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                {parseISO(activity.time).toLocaleString()}
              </time>
              <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                {activity.activity}
              </p>
            </li>
          ))}
        </ol>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const activities = ({
  activities,
  cancel
}: {
  activities: Activity[];
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "category-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <Activites
          show
          activities={activities}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
