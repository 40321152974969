import styles from "./converstations.module.css";

export const AnimatedConversationCard = () => (
  <div className={`flex items-center space-x-4 ${styles.conversation_card} hover:bg-transparent`}>
    <div className="skeleton w-[42px] h-[42px] rounded-full" />
    <div>
      <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      <p className="skeleton w-[150px] h-[10px] rounded-[2px] my-1" />
    </div>
  </div>
);
