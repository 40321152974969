/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  clearAllItemsFromLocalStorage,
  getItemFromLocalStorage,
  storeItemInLocalStorage
} from "@/utils/localStorageHelper";
import { FacebookUser, InstagramUser, WhatsappUser, SignedInUser, User } from "@/models/auth";

export interface AuthState {
  token: string | null;
  user: User | null;
  isAuthenticated: boolean;
}

export type AuthActionPayload = Omit<AuthState, "isLoggedIn">;

const initialState: AuthState = {
  token: getItemFromLocalStorage("token") as string | null,
  user: getItemFromLocalStorage("user") as User | null,
  isAuthenticated: (getItemFromLocalStorage("isAuthenticated") as boolean) ?? false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate(state, action: PayloadAction<SignedInUser>) {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;

      storeItemInLocalStorage("user", user);
      storeItemInLocalStorage("token", token);
      storeItemInLocalStorage("isAuthenticated", true);
    },
    updateToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      storeItemInLocalStorage("token", action.payload);
    },
    updateUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      storeItemInLocalStorage("user", action.payload);
    },
    updateFacebookUser(state, action: PayloadAction<FacebookUser | null>) {
      const updatedUser = { ...state.user, facebook: action.payload } as User;
      state.user = updatedUser;
      storeItemInLocalStorage("user", updatedUser);
    },
    updateInstagramUser(state, action: PayloadAction<InstagramUser | null>) {
      const updatedUser = { ...state.user, instagram: action.payload } as User;
      state.user = updatedUser;
      storeItemInLocalStorage("user", updatedUser);
    },
    updateWhatsappUser(state, action: PayloadAction<WhatsappUser | null>) {
      // eslint-disable-next-line
      console.log(action.payload);
      const updatedUser = { ...state.user, whatsapp: action.payload } as User;
      state.user = updatedUser;
      storeItemInLocalStorage("user", updatedUser);
    },
    signOut() {
      clearAllItemsFromLocalStorage();
      sessionStorage.clear();

      return {
        token: null,
        user: null,
        isAuthenticated: false
      };
    }
  }
});
export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;
