import { TextField } from "@/components/Forms";
import { WAPAFlowData } from "@/models/flow";
import { useFormContext } from "react-hook-form";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const WAPAFlowForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<WAPAFlowData>();

  return (
    <NodeFormWrapper description="This node send WhatsApp flows to the user">
      <TextField
        name="screenId"
        register={register}
        label="Arabic Flow First screen"
        error={errors.screenId?.message}
      />
      <TextField name="flowId" register={register} label="Flow Id" error={errors.flowId?.message} />
      <TextField
        name="ctaButtonlabel"
        register={register}
        label="Call to Action button"
        error={errors.ctaButtonlabel?.message}
      />
      <TextField
        name="messageBody"
        register={register}
        label="Flow Message Body"
        error={errors.messageBody?.message}
      />
      <TextField
        name="messageFooter"
        register={register}
        label="Flow Message Footer"
        error={errors.messageFooter?.message}
      />
      <TextField
        name="endpoint"
        register={register}
        label="Custom Webhook"
        error={errors.endpoint?.message}
      />
    </NodeFormWrapper>
  );
};
