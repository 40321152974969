import { Position } from "react-flow-renderer";
import { NodeType } from "../../types";
import { BaseHandle } from "../BaseNode";
import styles from "../BaseNode/node.module.css";

export const RootNode: Omit<NodeType, "dragElement"> = {
  type: "root",
  component: () => (
    <div className={`${styles.node}`} style={{ backgroundColor: "#757687" }}>
      <p style={{ fontSize: 14 }}>Start</p>
      <BaseHandle type="source" position={Position.Bottom} />
    </div>
  )
};
