import { Line } from "react-chartjs-2";
import styles from "./charts.module.css";

export const TrendChart = ({
  title,
  isLoading,
  data
}: {
  title: string;
  isLoading?: boolean;
  data: Array<number>;
}) => {
  const [previous, current] = data.slice(-2);
  const difference = current - previous;
  const percentage = ((Math.abs(difference) * 100) / previous).toFixed(1);

  const resolveTrendColor = () => {
    if (difference === 0) return "#757687";
    if (difference > 0) return "#046c4e";
    return "#c81e1e";
  };

  return (
    <div
      className={`flex flex-col justify-between ${styles.trend_card_container}`}
      style={{ height: 150, width: 300 }}
    >
      <div className="flex justify-between items-center space-x-2 pt-[20px]">
        <h1 className="font-semibold text-xl">{title}</h1>
        {isLoading && (
          <>
            <p className="skeleton w-[80px] h-[20px] rounded-[2px]" />
            <p className="skeleton w-[80px] h-[20px] rounded-[2px]" />
          </>
        )}
        {!isLoading && (
          <div className="flex items-center space-x-6">
            <p className="font-bold text-lg">{new Intl.NumberFormat().format(current)}</p>
          </div>
        )}
      </div>
      {!isLoading && (
        <div className="flex items-end justify-between mb-4" style={{ marginLeft: 0 }}>
          <Line
            height={60}
            width={160}
            className="my-auto"
            data={{
              datasets: [
                {
                  data,
                  borderWidth: 4,
                  backgroundColor: resolveTrendColor(),
                  borderColor: resolveTrendColor(),
                  pointRadius: Array.from({ length: data.length }, (_, idx) => {
                    if (idx === data.length - 1) return 3;
                    return 0;
                  })
                }
              ],
              labels: data
            }}
            options={{
              maintainAspectRatio: false,

              responsive: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  enabled: false
                }
              },
              scales: {
                x: {
                  grid: { display: false },
                  display: false,
                  ticks: {
                    display: false
                  }
                },
                y: {
                  grid: { display: false },
                  display: false,
                  suggestedMax: 10,
                  suggestedMin: 0,
                  ticks: {
                    display: false
                  }
                }
              }
            }}
          />
          {difference !== 0 && (
            <div className="flex items-center">
              <img
                className="w-6"
                src={`/img/icons/${difference > 0 ? "upward" : "downward"}-trend.svg`}
                alt="trend"
              />
              <p
                className="font-semibold"
                style={{
                  color: resolveTrendColor()
                }}
              >
                {previous > 0 ? `${percentage}%` : Math.abs(difference)}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
