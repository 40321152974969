/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { Product } from "@/models/Products";
import { ShopProducts } from "./ShopProducts";

type _ImagesPreviewDialogProps = {
  show: boolean;
  prods: Product[];
  checkout: { product: Product; amount: number }[];
  onAccept: (selected: any) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};
function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export const SelectItemsModal = ({
  show,
  prods,
  checkout,
  onAccept,
  onCancel,
  onClose,
  ...rest
}: _ImagesPreviewDialogProps) => {
  const [animate, setAnimate] = useState(false);
  const [visible, setVisible] = useState(show);
  const [products, setProducts] = useState(prods);
  const [prodbools, setProdbools] = useState<boolean[]>(
    prods?.map((p) => {
      const c = checkout.map((x) => x.product);
      return c.includes(p);
    })
  );

  const [selected, setSelected] = useState<{ product: Product; amount: number }[]>(checkout);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  return (
    <BaseDialog
      title="Select Products"
      show={visible}
      body={
        <div>
          <div className="flex  justify-center">
            <div className="relative w-96">
              <span className="  h-full absolute inset-y-0 left-0 flex items-center pl-2">
                <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
                  <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
                </svg>
              </span>
              <input
                placeholder="Product Name"
                className="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                onChange={(e: any) => {
                  setProducts(
                    prods.filter(
                      (p) =>
                        p.name.includes(e.target.value) || p.arabicName.includes(e.target.value)
                    )
                  );
                }}
              />
            </div>
            <div className={` ml-10 ${animate ? " animate-bounce" : ""}`}>
              <span className="absolute ml-5 text-sm  bg-red-800 rounded-full px-1 text-white  w-5 h-5 text-center items-center">
                {selected ? selected.length : 0}
              </span>
              <img className=" w-8" src="/img/order-icons/cart.svg" alt="." />
            </div>
          </div>

          <div className="flex flex-1 flex-wrap w-[799px] h-[500px] overflow-auto mt-10 ">
            {products.map((product, index) => (
              <div key={product._id} className="w-52 z-0 ml-10 h-fit ">
                <ShopProducts
                  clicked={async () => {
                    const newProdbools = [...prodbools];
                    newProdbools[index] = !newProdbools[index];
                    setProdbools(newProdbools);
                    const prod = prods?.filter((_, ind) => newProdbools[ind]);
                    setSelected(prod.map((product) => ({ product, amount: 1 })));
                    setAnimate(true);
                    await timeout(2000).then(() => setAnimate(false));
                  }}
                  onChange={(amount, id) => {
                    setSelected(
                      selected?.map((s) => {
                        if (s.product._id === id) return { ...s, amount };
                        return s;
                      })
                    );
                  }}
                  showInput={prodbools[index]}
                  product={product}
                  amount={
                    checkout.filter((c) => c.product === product).length
                      ? checkout.filter((c) => c.product === product)[0].amount
                      : 1
                  }
                />
              </div>
            ))}
          </div>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => {
              onAccept(selected);
              close();
            }}
          >
            <p className="font-semibold ">Confirm</p>
          </div>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const selectItemsModal = ({
  products,
  checkout,
  accept,
  cancel
}: {
  products: Product[];
  checkout: { product: Product; amount: number }[];
  accept: (selcted: any) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "images-preview-dialog";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <SelectItemsModal
          show
          prods={products}
          checkout={checkout}
          onAccept={(selected) => {
            accept(selected);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
