/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { useSocketIOEvent } from "@/services/socket-io";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Order } from "@/models/orders";
import { BreadCrumb } from "@/components/BreadCrumb";
import { useOrders } from "./hooks/order-hooks";
import {
  AnimatedOrdersTable,
  CustomerItem,
  DeliveryItem,
  Filter,
  ManualOrder,
  OrderStatus,
  OrderTime,
  PaymentItem
} from "./components";

export const Orders = () => {
  // const { socket } = useSocketIO();
  const [skip, setSkip] = useState(0);
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [query, setQuery] = useState<any>();
  const [platform, setPlatform] = useState<any>();
  const [status, setStatus] = useState<any>();
  const { data, isLoading, refetch } = useOrders(skip, status, platform, query);

  useEffect(() => {
    setOrders(data?.orders!);
  }, [data]);

  const skipper = () => {
    const newSkip = skip + 12;
    if (data?.count) {
      if (skip + 12 < data.count) setSkip(newSkip);
    }
  };

  const prev = () => {
    const newSkip = skip - 12;
    if (data?.count) {
      if (skip > 0) setSkip(newSkip);
    }
  };

  useSocketIOEvent("order:new", () => {
    refetch();
  });

  return (
    <div className=" container">
      <BreadCrumb items={[{ text: "Orders", isActive: true }]} />
      <div className="flex flex-row justify-center items-center">
        <Filter
          onChange={(q, s, p) => {
            setSkip(0);
            setQuery(q);
            setStatus(s);
            setPlatform(p);
          }}
        />
        <div className="mr-10">
          <ManualOrder />
        </div>
      </div>

      {isLoading ? (
        <AnimatedOrdersTable />
      ) : orders?.length === 0 ? (
        <div className=" mt-[200px] max-w-fit flex justify-center items-center bg-[#757687] p-4 rounded-3xl text-white m-auto h-8 font-medium ">
          No Orders!
        </div>
      ) : (
        <div className=" p-10">
          <div className="flex flex-col space-y-5 ">
            {orders?.map((order, index) => (
              <div
                key={order._id}
                className={`rounded-md p-3 mt-8 shadow-lg w-full hover:bg-gray-100 ${
                  index % 2 !== 0 ? "bg-gray-50" : "bg-white"
                }`}
              >
                <div className="flex flex-wrap sm:space-y-0 space-y-4 justify-between sm:space-x-8 space-x-0 items-center">
                  <OrderStatus order={order} />
                  <OrderTime order={order} />
                  {/* order.notes && <OrderNotes order={order} /> */} <CustomerItem order={order} />
                  <PaymentItem order={order} />
                  <DeliveryItem onChange={() => refetch()} order={order} />
                  <div className="flex">
                    <div
                      onClick={() => {
                        navigate(`/orders/${order._id}`);
                      }}
                      className="flex justify-between p-2 border-2 border-gray-400 rounded-md text-xs mt-5 hover:bg-gray-200"
                      role="button"
                    >
                      <span>View Details</span>
                      <img className=" w-4" src="img/icons/arrow-right.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5 py-5 border-t flex flex-col xs:flex-row items-center xs:justify-between">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {skip + 1} to {skip + Number(data?.orders?.length!)} of {data?.count} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={prev}
                type="button"
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
              >
                Prev
              </button>
              <button
                onClick={skipper}
                type="button"
                className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
