/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import { Card } from "@/components/Cards";
import { AvatarPlaceHolder } from "@/components/AvatarPlaceHolder";
import { Customer, CustomerData } from "@/models/customers";
import { Button } from "@/components/Buttons";
import { CustomerStatus } from "./CustomerStatus";
import { CustomerActivities } from "./CustomerActivities";
import { message } from "./Message";
import { customerEdite } from "./EditCustomerModal";
import { ContactSections } from "./ContactSections";
import { CustomerOrders } from "./CustomerOrders";

type Props = {
  customer: Customer;
  onUpdate: (data: CustomerData) => void;
};

export const CustomerCard = ({ customer, onUpdate }: Props) => (
  <Card>
    <div className="absolute  ml-56">
      <CustomerStatus nOfOrders={customer.nOfOrders} />
    </div>
    <div className=" flex flex-col py-4">
      <div className=" flex flex-col px-8  items-center">
        <div className=" rounded-full p-1 border border-spacing-1 border-blue-400 ">
          <AvatarPlaceHolder username={customer.name} width={56} />
        </div>
        <div className=" flex flex-row space-x-2">
          <div>{customer.name} </div>
          <span
            onClick={() => {
              customerEdite({
                customer,
                update: (data) => {
                  onUpdate(data);
                }
              });
            }}
          >
            <img src="/img/icons/edit.svg" alt="" />
          </span>
        </div>
        <ContactSections customer={customer} />
        <div className="py-3 w-full">
          <Button variant="btn-block" onClick={() => message({ customer })}>
            Message
          </Button>
        </div>
      </div>
      <div className=" flex flex-row pt-2 ">
        <div className="border-r border-gray-300 p-1">
          <CustomerActivities activity={customer.activities} />
        </div>
        <div className="p-1">
          <CustomerOrders customer={customer} />
        </div>
      </div>
    </div>
  </Card>
);
