import { useState } from "react";
import * as uuid from "uuid";
import { useSocketIOEvent } from "@/services/socket-io";
import { useForm } from "react-hook-form";
import { useInfiniteScroll } from "@/hooks";
import { TextFieldWithIcon } from "@/components/Forms";
import { FlashingDotsLoader } from "@/components/Loaders";
import { ConversationCard } from "./ConversationCard";
import { AnimatedConversationCard } from "./AnimatedConversationCard";
import { useConversations } from "../../hooks/chat-hooks";
import styles from "./converstations.module.css";

export const ConversationsList = () => {
  const [query, setQuery] = useState("");
  const { data, isLoading, fetchNextPage, isFetchingNextPage, refetch } = useConversations(query);
  const { scrollRef } = useInfiniteScroll(fetchNextPage);

  const { register } = useForm<{ query: string }>({});

  useSocketIOEvent("message:new", () => {
    refetch();
    // eslint-disable-next-line no-console
    console.log("new conversation");
  });
  return (
    <div className={`${styles.conversations_container}`}>
      <TextFieldWithIcon
        onChange={(input) => {
          setQuery(input.value);
          // refetch();
        }}
        name="query"
        placeholder="Search"
        icon="search-icon.svg"
        register={register}
      />
      <div className={`${styles.conversation_list_container}`} ref={scrollRef}>
        {isLoading && Array.from(Array(10)).map(() => <AnimatedConversationCard key={uuid.v1()} />)}
        {data?.pages?.map((page) =>
          page.map((c) => <ConversationCard key={c._id} converstation={c} />)
        )}
        {isFetchingNextPage && <FlashingDotsLoader />}
      </div>
    </div>
  );
};
