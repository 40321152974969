import { useTab } from "../context/TabContext";
import TabHeader from "./TabHeader";
import styles from "./tabs.module.css";

export const TabPanel = () => {
  const { handleTabChange, items, activeTab } = useTab();
  return (
    <div className={styles.tabs_header}>
      {items
        .sort((n1, n2) => n1.order - n2.order)
        .map((item) => (
          <TabHeader
            key={item.order}
            label={item.label}
            disabled={item.disabled || false}
            isActive={activeTab.order === item.order}
            onTabChanged={() => handleTabChange(item.order)}
          />
        ))}
    </div>
  );
};
