export type FileMaybe = File | null;

export class FileValidator {
  file: FileMaybe;

  constructor(file: FileMaybe) {
    this.file = file;
  }

  // eslint-disable-next-line no-unused-vars
  chain(func: (f: FileMaybe) => FileMaybe): FileValidator | null {
    const result: FileMaybe = func(this.file);
    if (result instanceof File) {
      return new FileValidator(result);
    }
    return null;
  }
}
