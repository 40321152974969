import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./reducers/auth.reducer";
import { flowReducer } from "./reducers/flow.reducer";
import { langReducer } from "./reducers/lang.reducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    flow: flowReducer,
    lang: langReducer
  }
});

export type AppStore = typeof store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
