import { NodeType } from "../../types";

const NodeComponent = () => (
  <div
    className="flex justify-center bg-white w-[100px] text-center"
    style={{ padding: "10px 14px", fontSize: 10, fontWeight: 500, borderRadius: "0.3rem" }}
  >
    <div className="spinner-loader w-6 h-6" />
  </div>
);

export const LoadingNode: Omit<NodeType, "dragElement"> = {
  type: "loading",
  component: NodeComponent
};
