import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";

type _BaseProps<T extends FieldValues> = {
  label?: string;
  name: Path<T>;
  register: UseFormRegister<T>;
  required?: boolean;
  // error?: ValidationError | string;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps<T>>;

export const Checkbox = <T extends FieldValues>({
  name,
  register,
  label,
  ...others
}: _InputProps<T>) => {
  const id = useId();
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          type="checkbox"
          {...register(name)}
          className="w-4 h-4 border bg-white border-gray-300 rounded focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800"
          {...others}
        />
      </div>
      <div className="ml-3 text-sm">
        {label && (
          <label htmlFor={id} className="block text-sm font-medium text-gray-900">
            {label}
          </label>
        )}
      </div>
    </div>
  );
};
