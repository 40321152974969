import { RecorderLoader } from "@/components/Loaders";
import { useChat } from "@/pages/LiveChat/context";

type _ActionButtonsProps = {
  condition: boolean;
  icon: string;
  width?: number;
  action?: () => void;
};

const ActionButton = ({ condition, icon, width = 24, action }: _ActionButtonsProps) => (
  // eslint-disable-next-line
  <>
    {condition && (
      // eslint-disable-next-line
      <img
        // eslint-disable-next-line
        role="button"
        className="mx-2"
        src={`/img/chat-icons/${icon}.svg`}
        alt="play-audio"
        style={{ width }}
        onClick={() => {
          // eslint-disable-next-line
          action && action();
        }}
      />
    )}
  </>
);

export const ChatRecorder = () => {
  const {
    recorder: { status, pauseRecording, resumeRecording },
    timer: { seconds, minutes }
  } = useChat();

  return (
    <div className="flex justify-between items-center w-full p-2 bg-white border border-gray-300 rounded-full">
      <div className="flex justify-start items-center">
        <p className="text-gray-900 text-sm mx-4 font-semibold">{`${minutes}:${seconds}`}</p>
        <RecorderLoader />
        <p className="text-gray-900 text-sm mx-2 font-semibold">Recording.....</p>
      </div>
      <div className="flex">
        <ActionButton
          condition={status === "recording" || status === "paused"}
          icon="red-trash"
          width={20}
        />
        <ActionButton
          condition={status === "recording"}
          icon="circle-pause-regular"
          action={pauseRecording}
        />
        <ActionButton
          condition={status === "paused"}
          icon="circle-play-regular"
          action={resumeRecording}
        />
      </div>
    </div>
  );
};
