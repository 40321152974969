import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { Customer } from "@/models/customers";

type CategoryEditeProps = {
  show: boolean;
  customer: Customer;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const Message = ({ customer, show, onClose, ...rest }: CategoryEditeProps) => {
  const [visible, setVisible] = useState(show);
  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };
  return (
    <BaseDialog
      title={`Send Message to ${customer.name}`}
      show={visible}
      body={<div className="p-10 items-center">Comming Soon...</div>}
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const message = ({ customer, cancel }: { customer: Customer; cancel?: () => void }) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "category-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <Message
          show
          customer={customer}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
