const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const base64 = reader!.result!.toString();
      resolve(base64);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

export const getBase64FromFile = async (media: string): Promise<string> => {
  const result = await fetch(media);
  const blob = await result.blob();
  const base64 = await convertBlobToBase64(blob);

  return base64;
};
