import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordField, TextFieldWithIcon } from "@/components/Forms";
import { UserCredentials } from "@/models/auth";
import { AuthFormWrapper } from "./AuthFormWrapper";
import { useSignUp, useCheckRegistration } from "../hooks/auth-hooks";
import { SignUpFormValidationSchema } from "../utils/validationSchema";
import { AuthWrapper } from "./AuthWrapper";

type _SignUpFormType = UserCredentials & {
  confirmPassword: string;
};

export const SignUpForm = () => {
  const { isLoading: isSigningUp, mutate: signUp } = useSignUp();
  const { isLoading: isChecking, mutate: checkRegistration } = useCheckRegistration();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<_SignUpFormType>({
    resolver: yupResolver(SignUpFormValidationSchema())
  });

  const onSignUp = (values: UserCredentials) => {
    checkRegistration(values.email, {
      onSuccess(userExists) {
        if (!userExists) signUp(values);
      }
    });
  };

  const isLoading = isSigningUp || isChecking;

  return (
    <AuthWrapper>
      <AuthFormWrapper
        title="Sign Up!"
        submitText="Create an account"
        isLoading={isLoading}
        onSubmit={() => handleSubmit(onSignUp)()}
        linkDesc="Already have an account ?"
        linkText="Sign in"
        link="/auth/signin"
      >
        <TextFieldWithIcon
          name="email"
          icon="envelope.svg"
          label="Email"
          placeholder="user@example.com"
          register={register}
          error={errors.email?.message}
        />
        <TextFieldWithIcon
          name="name"
          icon="user.svg"
          label="Name"
          register={register}
          placeholder="Your name"
          error={errors.name?.message}
        />
        <TextFieldWithIcon
          name="organization"
          icon="office-building.svg"
          label="Organization name"
          register={register}
          placeholder="Your organization name"
          error={errors.organization?.message}
        />
        <PasswordField
          name="password"
          label="Password"
          placeholder="••••••••"
          register={register}
          error={errors.password?.message}
        />
        <TextFieldWithIcon
          type="password"
          name="confirmPassword"
          icon="key.svg"
          label="Confirm password"
          placeholder="••••••••"
          register={register}
          error={errors.confirmPassword?.message}
        />
        {/* // TODO: (Asim) implement term of conditions checkbox */}
      </AuthFormWrapper>
    </AuthWrapper>
  );
};
