import React, { useEffect, useState } from "react";

type Props = {
  skiper: (skip: number) => void;
  length: number;
  count: number;
};

export const Pagination = ({ skiper, length, count }: Props) => {
  const [skip, setSkip] = useState(0);
  const skipper = () => {
    const newSkip = skip + 12;
    if (count) {
      if (skip + 12 < count) setSkip(newSkip);
    }
  };

  const prev = () => {
    const newSkip = skip - 12;
    if (count) {
      if (skip > 0) setSkip(newSkip);
    }
  };

  useEffect(() => {
    skiper(skip);
  }, [skip]);

  return (
    <div className="px-5 py-5  border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
      <span className="text-xs xs:text-sm text-gray-900">
        Showing {skip + 1} to {skip + Number(length!)} of {count} Entries
      </span>
      <div className="inline-flex mt-2 xs:mt-0">
        <button
          onClick={prev}
          type="button"
          className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"
        >
          Prev
        </button>
        <button
          onClick={skipper}
          type="button"
          className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"
        >
          Next
        </button>
      </div>
    </div>
  );
};
