import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { Notification, NotificationChannel, Notifications } from "@/models/notifications";
import { INotificationsApi } from "./INotificationsApi";

@injectable()
export class NotificationsApi implements INotificationsApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/notification";

  async getNotifications(
    channel: NotificationChannel,
    skip: number = 0
  ): Promise<Array<Notification>> {
    const {
      data: { notifications }
    } = await this.client.get<Notifications>(this.baseUrl, {
      params: { channel, skip }
    });
    return notifications;
  }

  async markChannelAsRead(channel: NotificationChannel): Promise<void> {
    const fullUrl = `${this.baseUrl}/read/channel/${channel}`;
    await this.client.post(fullUrl);
  }

  async markNotificationAsRead(notificationId: string): Promise<Notification> {
    const fullUrl = `${this.baseUrl}/read/id/${notificationId}`;
    const { data } = await this.client.post<Notification>(fullUrl);
    return data;
  }
}
