/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from "@/components/Buttons";
import { TextField, Toggle } from "@/components/Forms";
import { TableItem } from "@/components/Tables";
import { DeliveryArea, DeliveryData } from "@/models/DeliveryArea";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEditArea } from "../../hooks/useDeliveryConfig";

type Props = {
  area: DeliveryArea;
  onDelete: (locationId: string) => void;
  onEdit: () => void;
};

export const Area = ({ area, onDelete, onEdit }: Props) => {
  const [form, setForm] = useState(false);
  const { mutate: update } = useEditArea();
  const { register, handleSubmit } = useForm<DeliveryData>({
    defaultValues: {
      name: area.name,
      nameAR: area.nameAR,
      price: area.price,
      isAvailable: area.isAvailable
    }
  });

  const openForm = () => {
    setForm(true);
  };

  const onSubmit = (data: DeliveryData) => {
    update(
      { locationId: area._id, deliveryAreaData: data },
      {
        onSuccess() {
          setForm(false);
          onEdit();
        }
      }
    );
  };
  return (
    <tr>
      {form ? (
        <>
          <td>
            <TextField placeholder="Area" name="name" register={register} />
          </td>
          <td>
            <TextField placeholder="المنطقة" name="nameAR" register={register} />
          </td>
          <td>
            <TextField placeholder="price?" name="price" type="number" register={register} />
          </td>
          <td>
            <div className=" ml-6">
              <Toggle name="isAvailable" register={register} />
            </div>
          </td>
          <td>
            <Button variant="btn-primary" onClick={() => handleSubmit(onSubmit)()}>
              SAVE
            </Button>
          </td>
        </>
      ) : (
        <>
          <TableItem>{area.name}</TableItem>
          <TableItem>{area.nameAR}</TableItem>
          <TableItem>{area.price}</TableItem>
          <TableItem>
            <div className="flex ml-3 items-center ">
              <div className="flex items-center">
                <label htmlFor={area.owner} className="relative cursor-pointer">
                  <input
                    id={area.owner}
                    type="checkbox"
                    className="sr-only peer"
                    checked={area.isAvailable}
                    disabled
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600" />
                </label>
              </div>
            </div>
          </TableItem>
          <TableItem>
            <div className="flex item-center justify-center">
              <div
                onClick={() => {
                  openForm();
                }}
                className="w-6 mr-2 transform hover:text-purple-500 hover:scale-110"
              >
                <img className=" cursor-pointer" src="/img/icons/edit.svg" alt="delete" />
              </div>
              <div
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  const confirm = window.confirm("Delete Area?");
                  if (confirm) {
                    onDelete(area._id);
                  }
                }}
                className="w-6 ml-6 transform hover:text-purple-500 hover:scale-110"
              >
                <img src="/img/product-icons/delete.svg" alt="." />
              </div>
            </div>
          </TableItem>
        </>
      )}
    </tr>
  );
};
