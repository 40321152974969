type _Props = {
  text: string;
  colors: string;
};

export const ActiveStatusBadge = ({ text, colors }: _Props) => (
  <div className="tooltip max-w-24" data-tip="Made his first order">
    <div className={`py-1 px-2 text-xs rounded-full ${colors}`}>{text}</div>
  </div>
);
