import React from "react";
import { ActiveStatusBadge } from "./ActiveStatusBadge";

type _Props = {
  ordersCount: number;
};

export const ActiveStatus = ({ ordersCount }: _Props) => (
  <>
    {ordersCount === 1 && (
      <ActiveStatusBadge text="First Order" colors="bg-green-300 text-green-700" />
    )}
    {ordersCount > 1 && ordersCount <= 3 && (
      <ActiveStatusBadge text="Active" colors="bg-blue-300 text-blue-700" />
    )}
    {ordersCount > 3 && (
      <ActiveStatusBadge text="Very Active" colors="bg-purple-300 text-purple-700" />
    )}
  </>
);
