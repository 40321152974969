export const generateImagesUrls = async (uploadedFiles: Array<File>) => {
  const imageUrls = (await Promise.all(
    uploadedFiles.map(
      (image) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (upload) => {
            resolve(upload.target!.result);
          };
          reader.readAsDataURL(image);
        })
    )
  )) as string[];
  return imageUrls;
};
