/* eslint-disable react/display-name */
import { OrganizationInfo } from "@/models/auth";
import { forwardRef } from "react";
import "./invoice.css";

type Print = {
  invoice: {
    company: OrganizationInfo;
    address: {
      name: string;
      phone: string;
      location: string;
      email: string;
    };
    invoice: {
      number: string;
      date: Date;
      total: string;
      status: string;
    };
    items: {
      title: string;
      amount: string;
      qty: string;
      total: string;
    }[];
  };
};

export const Printer = forwardRef(({ invoice }: Print, ref: any) => (
  <div ref={ref} className="invoice-box absolute m-48 ">
    <table cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr className="top">
          <td colSpan={3}>
            <table>
              <tbody>
                <tr className="flex flex-1 flex-wrap">
                  <td className="title">
                    <img
                      alt="."
                      src="https://www.enigma-ai.com/assets/img/enigma.png"
                      style={{ width: 100, height: 100 }}
                    />
                  </td>
                  <td> </td>
                  <td className="">
                    Invoice #: {invoice?.invoice.number}
                    <br />
                    Created at: {invoice?.invoice.date.toLocaleString()}
                    <br />
                    Total Payment: {invoice?.invoice.total}
                    <br />
                    Status: {invoice?.invoice.status}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className="information">
          <td colSpan={4}>
            <table className="flex justify-center">
              <tbody>
                <tr>
                  <td>
                    {invoice?.address.name}
                    <br />
                    {invoice?.address.location}
                    <br />
                    {invoice?.address.phone}
                    <br />
                    {invoice?.address.email}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr className="heading">
          <td>Item</td>
          <td>Price</td>
          <td>Amount</td>
          <td>Total</td>
        </tr>
        {invoice?.items.map((i) => (
          <tr key={i.qty} className="item items-center">
            <td>{i.title}</td>
            <td>{i.amount}</td>
            <td className="text-center">{i.qty}</td>
            <td>{i.total}</td>
          </tr>
        ))}
        <tr className="total">
          <td />

          <td>Total: {invoice?.invoice.total}</td>
        </tr>
      </tbody>
    </table>
  </div>
));
