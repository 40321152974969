import { IOrdersApi } from "@/api/orders";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { OrderStatus, Platform } from "@/models/orders";
import { useMutation, useQuery } from "react-query";

export const useOrders = (
  skip: number,
  status?: OrderStatus,
  platform?: Platform,
  query?: string
) => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useQuery(["orders", skip, status, platform, query], () =>
    ordersApi.getOrders(skip, status, platform, query)
  );
};
export const useOrderById = (id: any) => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useQuery([id], () => ordersApi.getOrderById(id));
};
export const useConfirmOrder = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation<any, any, string>((orderId) => ordersApi.confirmOrder(orderId));
};

export const useRejectOrder = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation<any, any, string>((orderId) => ordersApi.rejectOrder(orderId));
};

export const useHideOrder = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation<any, any, string>((orderId) => ordersApi.toggleHideOrder(orderId));
};

export const useAddOrder = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation((order: any) => ordersApi.addOrder(order));
};

export const useAddDeliveryDate = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation((data: { orderId: string; deliveryDate: any }) =>
    ordersApi.setDeliveryDate(data)
  );
};

export const useDeleteDeliveryDate = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation((orderId: string) => ordersApi.deleteDeliveryDate(orderId));
};

export const useUpdateOrder = () => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useMutation((data: { orderId: string; updates: any }) => ordersApi.updateOrder(data));
};
