import { PropsWithChildren } from "react";
import { TableHeader } from "./components/Header";

type Props = {
  headers: string[];
};

export const Table = ({ headers, children }: PropsWithChildren<Props>) => (
  <div className=" flex  justify-center font-sans overflow-scroll">
    <div className="w-full  h-fit">
      <div className="bg-white shadow-md rounded ">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              {headers.map((h) => (
                <TableHeader key={h} header={h} />
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  </div>
);
