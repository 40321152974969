/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { showImagesCarousel } from "@/components/Carousel";
import { Order } from "@/models/orders";
import React from "react";

type Props = {
  order: Order;
};

export const PaymentCard = ({ order }: Props) => (
  <div className="flex flex-col  py-6 md:pt-6 xl:pt-8 w-full bg-gray-50 space-y-6 ">
    <h3 className="text-xl font-semibold leading-5 text-gray-800">Payment</h3>
    <div className="flex justify-between items-start w-full">
      <div className="flex justify-center items-center space-x-4">
        <div className="w-8 h-8">
          <img src="/img/order-icons/payment.svg" alt="." />
        </div>
        <div className="flex flex-col justify-start">
          <p className="text-lg leading-6 font-semibold text-gray-800">{order?.paymentMethod}</p>
          <p className="text-lg rounded-full leading-6 py-2">{order?.paymentStatus}</p>
          <p className="text-lg leading-6  text-gray-800">{Number(order?.totalPrice)} (SDG)</p>
        </div>
      </div>
    </div>
    <div className="w-full flex justify-center items-center">
      {order?.paymentMethod === "mbokDirect" || order?.paymentMethod === "mbokGateway" ? (
        <img
          src={order.mbokImages[0]}
          alt="mbok"
          onClick={() => showImagesCarousel({ images: order?.mbokImages })}
        />
      ) : (
        <div className="bg-gray-400 p-3 rounded-full text-xs text-white">cash</div>
      )}
    </div>
  </div>
);
