import React, { useEffect, useState } from "react";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { AppConfig } from "@/services/config";
import { initFacebookSDK } from "../utils";

interface FacebookContextType {
  // eslint-disable-next-line
  facebookSDK?: fb.FacebookStatic;
}

const FacebookContext = React.createContext<FacebookContextType>({} as FacebookContextType);

export const FacebookProvider = ({ children }: { children: React.ReactNode }) => {
  // eslint-disable-next-line
  const [facebookSDK, setFacebookSDK] = useState<fb.FacebookStatic>();
  const { facebookAppID } = useInjection<AppConfig>(SERVICES_TYPES.CONFIGURATIONS);

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const FBSdk = await initFacebookSDK(facebookAppID, "v2.6");
        setFacebookSDK(FBSdk);
      } catch (error) {
        // eslint-disable-next-line
        console.error(error);
      }
    };
    initializeSDK();
  }, [facebookAppID]);

  const value = React.useMemo(
    () => ({
      facebookSDK
    }),
    [facebookSDK]
  );

  return <FacebookContext.Provider value={value}>{children}</FacebookContext.Provider>;
};

export const useFacebookSDK = () => {
  const c = React.useContext(FacebookContext);
  if (c === undefined) {
    throw new Error("useFacebookSDK must be inside FacebookContext provider");
  }
  return c;
};
