import { Container } from "inversify";
import { AuthApi, IAuthApi } from "@/api/auth";
import { IUserApi, UserApi } from "@/api/user";
import { IMetricApi, MetricApi } from "@/api/metric";
import { FlowElementApi, IFlowElementApi } from "@/api/flow-element";
import { ExampleSetApi, IExampleSetApi } from "@/api/example-set";
import { IConversationsApi, ConversationsApi, IMessagesApi, MessagesApi } from "@/api/chat";
import { INotificationsApi, NotificationsApi } from "@/api/notifications";
import { IConfigService, ConfigService, AppConfig } from "@/services/config";
import { IStorageService, SessionStorageService } from "@/services/storage";
import { HttpClient, HttpClientFactory } from "@/services/client";
import { AppStore, store } from "@/store/root";
import { ICategoriesApi, CategoriesApi } from "@/api/category";
import { IOrdersApi, OrdersApi } from "@/api/orders";
import { IProductsApi, ProductsApi } from "@/api/Products";
import { IVerifyApi, verifyApi } from "@/api/account-verification";
import { CustomersApi, ICustomersApi } from "@/api/customer";

import { SERVICES_TYPES } from "./SERVICES_TYPES";

const container = new Container();

// Config
const configService: IConfigService = new ConfigService();
const configs = configService.bind();
container.bind<AppConfig>(SERVICES_TYPES.CONFIGURATIONS).toConstantValue(configs);

// Http Client
const httpFactory = new HttpClientFactory(configs);
const httpClient = httpFactory.create();
container.bind<HttpClient>(SERVICES_TYPES.HTTP_CLIENT).toConstantValue(httpClient);

// Storage
container.bind<IStorageService>(SERVICES_TYPES.STORAGE).to(SessionStorageService);

// Store
container.bind<AppStore>(SERVICES_TYPES.STORE).toConstantValue(store);

// Auth
container.bind<IAuthApi>(SERVICES_TYPES.AUTH).to(AuthApi);

// User
container.bind<IUserApi>(SERVICES_TYPES.USER).to(UserApi);

// User
container.bind<INotificationsApi>(SERVICES_TYPES.NOTIFICATIONS).to(NotificationsApi);

// Metric
container.bind<IMetricApi>(SERVICES_TYPES.METRIC).to(MetricApi);

// Categories
container.bind<ICategoriesApi>(SERVICES_TYPES.CATEGORIES).to(CategoriesApi);

// Orders
container.bind<IOrdersApi>(SERVICES_TYPES.ORDERS).to(OrdersApi);

// Bot-Flow
container.bind<IFlowElementApi>(SERVICES_TYPES.FLOW_ELEMENT).to(FlowElementApi);

// Example-Set
container.bind<IExampleSetApi>(SERVICES_TYPES.EXAMPLE_SET).to(ExampleSetApi);

// Conversations
container.bind<IConversationsApi>(SERVICES_TYPES.CONVERSATIONS).to(ConversationsApi);

// Messages
container.bind<IMessagesApi>(SERVICES_TYPES.MESSAGES).to(MessagesApi);

// Product
container.bind<IProductsApi>(SERVICES_TYPES.PRODUCTS).to(ProductsApi);

// Verify
container.bind<IVerifyApi>(SERVICES_TYPES.VERIFY).to(verifyApi);
// Customer
container.bind<ICustomersApi>(SERVICES_TYPES.CUSTOMERS).to(CustomersApi);

export { container };
