const SCRIPT_ID = "facebook-js-sdk";

type FacebookSDKVersion = "v2.7" | "v2.6" | "v2.5" | "v2.4" | "v2.3";

// eslint-disable-next-line
export const createFacebookScript = (onerror: OnErrorEventHandler) => {
  const sdkScript = window.document.createElement("script");
  sdkScript.id = SCRIPT_ID;
  sdkScript.async = true;
  sdkScript.defer = true;
  sdkScript.src = "https://connect.facebook.net/en_US/sdk.js";
  sdkScript.onerror = onerror;

  window.document.body.appendChild(sdkScript);
};

export const initFacebookSDK = async (
  appId: string,
  version?: FacebookSDKVersion
  // eslint-disable-next-line
): Promise<fb.FacebookStatic> => {
  // eslint-disable-next-line
  const FBSdk: fb.FacebookStatic = await new Promise((resolve, reject) => {
    if (window.document.getElementById(SCRIPT_ID)) {
      if (window.FB != null) {
        // eslint-disable-next-line
        return resolve(window.FB);
      }
    }
    window.fbAsyncInit = () => {
      window.FB.init({
        appId,
        version: version ?? "",
        status: false,
        cookie: false,
        xfbml: false,
        frictionlessRequests: false
      });
      return resolve(window.FB);
    };

    createFacebookScript(() => {
      reject(new Error("Could not load the Facebook JavaScript SDK"));
    });
  });

  return FBSdk;
};
