import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  options: string[];
  error?: string;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"select">, keyof _BaseProps<T>>;

export const Select = <T extends FieldValues>({
  name,
  register,
  label,
  placeholder,
  options
}: _InputProps<T>) => {
  const id = useId();
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </label>
      )}
      <select
        defaultValue=""
        {...register(name)}
        className=" select select-bordered max-w-xs bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800 "
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option className=" " key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
