import { NodeProps, Position } from "react-flow-renderer";
import { PauseNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { PauseNodeForm } from "../DialogForms";

const DragElement = <BaseNode title="Pause" color="#B7002D" isDragElement />;

const NodeComponent = ({ data, ...rest }: NodeProps<PauseNodeData>) => {
  const { label } = data;
  return (
    <>
      <BaseHandle type="target" position={Position.Top} />
      <BaseNode
        title={label}
        color="#B7002D"
        icon="circle-pause-white.svg"
        data={data}
        form={<PauseNodeForm />}
        {...rest}
      />
      <BaseHandle type="source" position={Position.Bottom} />
    </>
  );
};

export const PauseNode: NodeType = {
  type: "pause",
  component: NodeComponent,
  dragElement: DragElement
};
