import { RefObject, useEffect, useState } from "react";

export const useOutsideAlerter = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  visible: boolean
) => {
  const [show, setShow] = useState(visible);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref!.current.contains(event.target as Node)) {
        // hide the component only if it is present in the screen
        if (show) setShow(false);
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, show]);
  return { show, setShow };
};
