import { MessageDirection } from "@/models/chat";
import React, { PropsWithChildren } from "react";

type _ChatBubbleProps = {
  direction: MessageDirection;
};

export const ChatBubble = ({ direction, children }: PropsWithChildren<_ChatBubbleProps>) => (
  <div
    className={`relative chat-bubble ${
      direction === "incoming" ? "text-white" : "bg-[#FAFAFA] text-gray-900"
    }`}
  >
    {children}
  </div>
);
