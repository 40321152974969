import { useMutation } from "react-query";
import { IUserApi } from "@/api/user";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { User } from "@/models/auth";
import { useAppDispatch } from "@/hooks";
import { authActions } from "@/store/reducers/auth.reducer";
import { exposeToast } from "@/components/Toasts";

export const useUpdateMe = () => {
  const dispatch = useAppDispatch();
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<User, any, any>("update-me", (values) => userApi.updateMe(values), {
    onSuccess: (result) => {
      dispatch(authActions.updateUser(result));
      exposeToast({ type: "success", message: "User account has been updated successfully" });
    }
  });
};

export const useVerifyMe = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<User, any, any>("user", (data: { email: string; newEmail: string }) =>
    userApi.verifyMe(data)
  );
};
