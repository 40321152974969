import { PropsWithChildren, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ReactPortal } from "@/components/ReactPortal";

type Props = {
  show?: boolean;
  wrapperId?: string;
};

export const CarouselWrapper = ({ wrapperId, show, children }: PropsWithChildren<Props>) => {
  const nodeRef = useRef(null);

  return (
    <ReactPortal wrapperId={wrapperId || "react-wrapper-portal"}>
      {" "}
      <CSSTransition in={show} appear timeout={300} unmountOnExit nodeRef={nodeRef}>
        <div className={`fixed z-[99999] bg-[#0000002e] inset-0 ${show ? "block" : "hidden"}`}>
          {children}
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};
