type _ControlButtonProps = {
  icon: string;
  action?: () => void;
};

export const ControlButton = ({ icon, action }: _ControlButtonProps) => (
  // eslint-disable-next-line
  <img
    className="w-5 cursor-pointer"
    src={`/img/flow-icons/${icon}`}
    alt={icon}
    onClick={(e) => {
      e.preventDefault();
      if (action) action();
    }}
  />
);
