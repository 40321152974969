import React from "react";
import styles from "./tabs.module.css";

type Props = {
  label: string;
  isActive: boolean;
  onTabChanged: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
};

const TabHeader = ({ label, isActive, onTabChanged, disabled = false }: Props) => (
  <button
    className={`${styles.tab_title} ${isActive ? styles.active : ""}`}
    id="nav-suite-tab"
    data-bs-toggle="tab"
    data-bs-target="#nav-suite"
    type="button"
    role="tab"
    onClick={onTabChanged}
    disabled={disabled}
  >
    {label}
  </button>
);

export default TabHeader;
