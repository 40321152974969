import React from "react";

type _Props = {
  icon: string;
  text: string;
  extend?: JSX.Element;
};

export const ProfileItem = ({ text, icon, extend }: _Props) => (
  <div className="flex items-center py-1 justify-between">
    <div className="flex space-x-2 items-center">
      <img className="w-5" src={icon} alt={text} />
      <p className="text-xs font-light text-gray-900 truncate">{text}</p>
    </div>
    {extend && extend}
  </div>
);
