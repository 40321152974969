/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TextField, Toggle } from "@/components/Forms";
import { Table } from "@/components/Tables";
import { useAppSelector } from "@/hooks";
import { DeliveryData } from "@/models/DeliveryArea";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateMe } from "../../hooks";
import {
  useDeleteArea,
  useDeleveryAreas,
  useEnableDelivery,
  useNewArea
} from "../../hooks/useDeliveryConfig";
import { Area } from "./Area";
import { DeliveryAreaValidation } from "./utils/AreaValidation";

export const Delivery = () => {
  const { data: areas, isLoading, refetch } = useDeleveryAreas();
  const { mutate: updateMe } = useUpdateMe();
  const { mutate: enableDelivery } = useEnableDelivery();
  const { mutate: addDeliverArea } = useNewArea();
  const { mutate: deleteArea } = useDeleteArea();
  const user = useAppSelector((state) => state.auth.user);
  const { register, setValue } = useForm<{ deliveryEnabled: boolean }>({
    defaultValues: { deliveryEnabled: user?.configOptions.deliveryEnabled }
  });
  const {
    register: delivery,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<DeliveryData>({
    defaultValues: {
      name: "",
      nameAR: "",
      price: 0,
      isAvailable: false
    },
    resolver: yupResolver(DeliveryAreaValidation())
  });

  // if (user?.configOptions.deliveryEnabled)
  //  setValue("deliveryEnabled", user?.configOptions.deliveryEnabled);
  const onSubmit = (data: DeliveryData) => {
    addDeliverArea(data, {
      onSuccess() {
        refetch();
        reset();
      }
    });
  };
  useEffect(() => {
    setValue("deliveryEnabled", user?.configOptions.deliveryEnabled!);
  }, [user]);
  if (isLoading) return <div>isLoading...</div>;
  return (
    <div className="container sm:p-10  w-fit max-w-[900px]">
      <p
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: "#044CAC"
        }}
      >
        Delivery
      </p>
      <div className="flex flex-row justify-center">
        <div>Enable Delivery?</div>
        <div className="ml-10">
          <Toggle
            name="deliveryEnabled"
            register={register}
            onClick={(e: any) => {
              updateMe({
                ...user!,
                configOptions: {
                  ...user?.configOptions,
                  deliveryEnabled: e.target.checked
                }
              });
              enableDelivery({ enabled: e.target.checked });
              // console.log(e);
            }}
          />
        </div>
      </div>
      <div className="sm:w-full  w-72 border-2 border-gray-200 mt-5">
        <Table headers={["Area", "المنطقه", "Price", "Available?", "Actions"]}>
          {areas?.map((area) => (
            <Area
              key={area._id}
              area={area}
              onDelete={(locationId) => {
                deleteArea(locationId, {
                  onSuccess() {
                    refetch();
                  }
                });
              }}
              onEdit={() => {
                refetch();
              }}
            />
          ))}
          <tr>
            <td>
              <TextField
                placeholder="Area"
                name="name"
                register={delivery}
                error={errors.name?.message}
              />
            </td>
            <td>
              <TextField
                placeholder="المنطقة"
                name="nameAR"
                register={delivery}
                error={errors.nameAR?.message}
              />
            </td>
            <td>
              <TextField
                placeholder="price?"
                name="price"
                type="number"
                register={delivery}
                error={errors.price?.message}
              />
            </td>
            <td>
              <div className=" ml-6">
                <Toggle name="isAvailable" register={delivery} />
              </div>
            </td>
            <td>
              <div
                role="button"
                className="cursor-pointer  flex text-white bg-primary-700 rounded-md p-1 items-center w-20"
                onClick={() => handleSubmit(onSubmit)()}
              >
                <span>Add</span>
                <img className="w-6 ml-2" src="img/icons/circle-plus-icon.svg" alt="" />
              </div>
              {/* <Button variant="btn-primary" onClick={() => handleSubmit(onSubmit)()}>
                  SAVE
                </Button> */}
            </td>
          </tr>
        </Table>
      </div>
    </div>
  );
};
