import React, { useId } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  value: any;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
};

type _RadioProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps<T>>;

export const RadioButton = <T extends FieldValues>({
  label,
  name,
  value,
  register,
  disabled = false,
  error,
  ...rest
}: _RadioProps<T>) => {
  const id = useId();

  return (
    <div>
      <div className="flex items-center">
        <input
          id={id}
          type="radio"
          value={value}
          {...register(name)}
          disabled={disabled}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
          {...rest}
        />
        {label && (
          <label
            htmlFor="default-radio-2"
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            {label}
          </label>
        )}
      </div>
      <FormErrorMessage error={error} />
    </div>
  );
};
