import * as uuid from "uuid";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { SERVICES_TYPES, container } from "@/ioc";
import { IExampleSetApi } from "@/api/example-set";
import { useEffectOnce } from "@/hooks";
import { Button } from "@/components/Buttons";
import { RadioButton, SelectField, TextField } from "@/components/Forms";
import { SpinnerLoader } from "@/components/Loaders";
import { INodesDataTypes } from "@/models/flow";
import { ExampleSet } from "@/models/example-set";
import { NodeFormWrapper } from "../NodeFormWrapper";
import { SetSentenceWrapper } from "./SetSentenceField";

type _ExamlpeSetFormType = Pick<
  INodesDataTypes,
  "exampleSet" | "exampleSetData" | "exampleSetMethod"
>;

export const ExampleSetForm = ({ sets }: { sets: ExampleSet[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { control, register, watch, setValue } = useFormContext<_ExamlpeSetFormType>();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    // @ts-ignore
    name: "exampleSetData.sentences"
  });

  const exampleSet = watch("exampleSet");
  const method = watch("exampleSetMethod");

  const fetchExampleSet = useCallback(async (exampleSetId: string) => {
    setIsLoading(true);
    try {
      const exampleSetApi = container.get<IExampleSetApi>(SERVICES_TYPES.EXAMPLE_SET);
      const data = await exampleSetApi.getExampleSetById(exampleSetId);
      if (data) {
        setValue("exampleSetData", data);
        replace(data.sentences);
      }
    } catch (_) {
      // TODO:(Asim) show error toast
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffectOnce(() => {
    setValue("exampleSetMethod", "keep");
    setValue("exampleSetData", { _id: "", name: "", sentences: [] });
  });

  useEffect(() => {
    if (method === "existing" && exampleSet) {
      fetchExampleSet(exampleSet);
    }
  }, [exampleSet, method, fetchExampleSet]);

  useEffect(() => {
    if (method === "new") {
      setValue("exampleSetData", { _id: "", name: "", sentences: [""] });
      replace([""]);
    }
  }, [method]);

  return (
    <NodeFormWrapper description="Add examples of customer messages and comments.">
      <div className="flex space-x-8">
        <RadioButton
          label="Change set"
          name="exampleSetMethod"
          register={register}
          value="existing"
        />
        <RadioButton label="New set" name="exampleSetMethod" register={register} value="new" />
        <RadioButton className="hidden" name="exampleSetMethod" register={register} value="keep" />
      </div>
      {method === "new" && (
        <>
          <TextField label="Example set name" name="exampleSetData.name" register={register} />
          <div
            className="flex items-center space-x-2 w-auto bg-[#fdcb81] p-[5px] rounded-2xl"
            style={{
              fontSize: 12,
              fontWeight: 500,
              borderRadius: 8,
              margin: "10px 0px 15px 0px"
            }}
          >
            <img className="w-4" src="/img/icons/warning-icon.svg" alt="toast-warning" />
            <p>Don`t write an existing set name.</p>
          </div>
        </>
      )}
      {["existing", "keep"].indexOf(method) !== -1 && (
        <SelectField
          disabled={method === "keep"}
          label="Example Set"
          name="exampleSet"
          register={register}
          options={sets.map(({ name, _id }) => ({
            label: name,
            value: _id
          }))}
        />
      )}
      {isLoading && (
        <div style={{ margin: "30px auto" }}>
          <SpinnerLoader />
        </div>
      )}
      {!isLoading &&
        fields.map((field, index) => (
          <SetSentenceWrapper
            key={field.id}
            handleRemove={() => {
              remove(index);
            }}
          >
            <TextField
              key={uuid.v4()}
              name={`exampleSetData.sentences.${index}`}
              register={register}
            />
          </SetSentenceWrapper>
        ))}
      <Button
        variant="btn-block"
        disabled={!method || method === "keep"}
        onClick={() => append("")}
      >
        Add new Sentence
      </Button>
    </NodeFormWrapper>
  );
};
