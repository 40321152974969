import { BreadCrumb } from "@/components/BreadCrumb";
import { useAppSelector } from "@/hooks";
import { Delivery, Account, Organization, Alert, ResetPasswordForm } from "./components";

export const Configuration = () => {
  const user = useAppSelector((state) => state.auth.user);
  return (
    <div>
      <BreadCrumb
        items={[
          {
            text: "Configuration",
            isActive: true
          }
        ]}
      />
      <div className="p-10">
        <div className="flex flex-col w-full pt-10 justify-center sm:bg-white bg-transparent rounded-md sm:shadow-md">
          <div>{!user?.isValid && <Alert user={user!} />}</div>
          <div className=" flex flex-wrap">
            <div className="sm:p-8 p-1  ">
              <Account user={user!} />
              <Organization user={user!} />
            </div>
            <div className="flex flex-col space-y-8">
              <Delivery />
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
