import { BreadCrumb } from "@/components/BreadCrumb";
import { Category } from "@/models/categories";
import { useEffect, useState } from "react";
import { AddNewCategory, CategoryCard } from "./components";
import { AnimatedCatalog } from "./components/AnimatedCatalog";
import { useRootCategories, useDeleteCategory } from "./hooks/useCategories";

// const DragDropContext = require("react-beautiful-dnd")

export const Catalog = () => {
  const { isLoading, data: cats, refetch } = useRootCategories();
  const [categories, setCategories] = useState<Category[]>([]);
  const { mutate: deleteCategory } = useDeleteCategory();
  useEffect(() => {
    if (!isLoading) {
      setCategories(
        cats!.sort((a, b) => {
          if (a.ordering > b.ordering) {
            return 1;
          }
          if (b.ordering > a.ordering) {
            return -1;
          }
          return 0;
        })
      );
    }
  }, [cats]);

  const handleUpdat = () => (newCat: Category) => {
    setCategories(categories.concat([newCat]));
  };

  const handleDelete = () => (categoryId: string) => {
    deleteCategory(categoryId, {
      onSuccess() {
        setCategories(categories?.filter((cat: Category) => cat._id !== categoryId));
      }
    });
  };

  return (
    <>
      <BreadCrumb
        items={[
          {
            text: "Catalog",
            isActive: true
          }
        ]}
      />
      <div className="flex flex-1 flex-wrap justify-center gap-10 ">
        {isLoading ? (
          <AnimatedCatalog />
        ) : (
          <div className="flex flex-1 flex-wrap gap-5 w-4/5 h-fit mt-10 ">
            {categories.length === 0 ? (
              <div className=" mt-[200px] flex justify-center items-center bg-[#757687] p-4 rounded-3xl text-white m-auto h-8 font-medium ">
                Your Catalog is Empty!
              </div>
            ) : (
              categories?.map((cat: any) => (
                <div key={cat._id}>
                  <CategoryCard category={cat} onDelete={handleDelete()} update={() => refetch()} />
                </div>
              ))
            )}
          </div>
        )}
        <div className="w-80 ">
          <AddNewCategory update={handleUpdat()} />
        </div>
      </div>
    </>
  );
};
