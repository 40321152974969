import { SpinnerLoader } from "@/components/Loaders";
import { useCustomer } from "@/pages/LiveChat/hooks/useCustomer";
import { Profile, Activities, Notes } from "./components";
import { useChat } from "../../context";
import styles from "./customer.module.css";

export const Customer = () => {
  const { chat, showProfile, toggleCustomerProfile } = useChat();
  if (!chat) return null;

  const { data: customer, isLoading, refetch } = useCustomer(chat.user.id);

  if (!showProfile) return null;

  return (
    <div className={`${styles.customer_container}`}>
      <div className="flex justify-between items-center mb-5">
        <p className="font-light">Customer Info</p>
        <button
          type="button"
          className="text-gray-400 bg-transparent hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center"
          onClick={() => toggleCustomerProfile()}
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isLoading || !customer ? (
        <SpinnerLoader />
      ) : (
        <div className="divide-y-2">
          <Profile customer={customer} />
          <Activities customer={customer} />
          <Notes customer={customer} onUpdate={() => refetch()} />
        </div>
      )}
    </div>
  );
};
