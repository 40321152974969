import { useEffect, useRef } from "react";

export const useInfiniteScroll = (onScroll: Function) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const { scrollTop, offsetHeight, scrollHeight } = scrollElement;

      if (scrollTop + offsetHeight >= scrollHeight) {
        onScroll();
      }
    }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) scrollElement.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { scrollRef };
};
