import * as yup from "yup";

const EmailSchema = yup.string().required("Please enter your email").email("Invalid email");

export const LoginFormValidationSchema = () =>
  yup.object().shape({
    email: EmailSchema,
    password: yup.string().required("Please enter your password")
  });

export const SignUpFormValidationSchema = () =>
  yup.object().shape({
    name: yup.string().required("Please enter your name"),
    email: EmailSchema,
    organization: yup.string().required("Please enter your organization's name"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Minimum password length is 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).*$/,
        "Must contain at least one lowercase letter, one uppercase letter, one digit, and one symbol (!@#$%^&*)"
      ),
    confirmPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords do not match")
  });
