import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useInfiniteScroll, useOutsideAlerter } from "@/hooks";
import { useSocketIOEvent } from "@/services/socket-io";
import { NotificationChannel } from "@/models/notifications";
import { FlashingDotsLoader } from "@/components/Loaders";
import { useMarkNotificationAsRead, useNotifications } from "../../hooks/notifications-hooks";
import { NotificationCard } from "./NotificationCard";
import styles from "./notifications.module.css";
import { NotificationTitle } from "./NotificationsTitle";

type _NotificationsProps = {
  channel: NotificationChannel;
  icon: React.ReactElement;
};

export const Notifications = ({ channel, icon }: _NotificationsProps) => {
  const notificationRef = useRef(null);
  const { show, setShow } = useOutsideAlerter<HTMLDivElement>(notificationRef, false);
  const navigate = useNavigate();

  const { data, fetchNextPage, isFetchingNextPage, refetch } = useNotifications(channel, 0);
  const { scrollRef } = useInfiniteScroll(fetchNextPage);

  const unreadCount = data?.pages[0].filter((n) => !n.read).length;
  const { mutate: readNotification } = useMarkNotificationAsRead(channel);

  useSocketIOEvent("notification:new", () => {
    refetch();
  });

  return (
    // eslint-disable-next-line
    <div className="relative cursor-pointer" ref={notificationRef!} onClick={() => setShow(!show)}>
      <div className="relative cursor-pointer">{icon}</div>
      {unreadCount! > 0 && (
        <div className={`${styles.unread_notifications_counter}`}>{unreadCount}</div>
      )}

      <div
        className={`${show ? "block" : "hidden"} ${styles.notification_container}`}
        ref={scrollRef}
      >
        <NotificationTitle channel={channel} unreadCount={unreadCount} />
        {data?.pages?.map((page) =>
          page.map((notification) => (
            <NotificationCard
              key={notification._id}
              notification={notification}
              onNotificationClicked={() => {
                if (!notification.read) readNotification(notification._id);

                if (channel === "messaging") navigate(`/live-chat/${notification.payload.id}`);
                if (channel === "general") navigate(`/orders/${notification.payload.id}`);
                // Hide the popup after navigation
                setShow(!show);
              }}
            />
          ))
        )}
        {isFetchingNextPage && <FlashingDotsLoader />}
      </div>
    </div>
  );
};
