import React, { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { useAppSelector } from "@/hooks";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { HttpClient } from "@/services/client";

interface SocketContextType {
  isConnected: boolean;
  socket?: Socket;
}

const SocketContext = React.createContext<SocketContextType>({} as SocketContextType);

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const httpClient = useInjection<HttpClient>(SERVICES_TYPES.HTTP_CLIENT);
  const [socket, setSocket] = useState<Socket>();
  const [isConnected, serConnection] = useState(false);
  const { token } = useAppSelector((state) => state.auth);

  const onConnect = () => {
    serConnection(true);
  };
  const onDisconnect = () => {
    serConnection(false);
  };

  // eslint-disable-next-line
  useEffect(() => {
    const { origin, pathname } = new URL(httpClient.defaults.baseURL ?? "", window.location.origin);
    if (token != null && !socket) {
      const socket = io(`${origin}/`, {
        auth: { token },
        reconnectionAttempts: 5,
        autoConnect: false,
        transports: ["websocket"],
        path: pathname === "/" ? `${pathname}socket.io` : `${pathname}/socket.io`,
        forceNew: true
      });

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      setSocket(socket);

      socket.connect();

      return () => {
        socket.close();
      };
    }
  }, [token]);

  const value = React.useMemo(() => ({ isConnected, socket }), [isConnected, socket]);

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

export const useSocketIO = () => {
  const c = React.useContext(SocketContext);
  if (c === undefined) {
    throw new Error("useSocketIO must be inside SocketContext provider");
  }
  return c;
};
