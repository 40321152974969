import React, { PropsWithChildren } from "react";

export const BreadCrumbItem = ({
  children,
  isActive
}: PropsWithChildren<{ isActive?: boolean }>) => (
  <p className={`text-sm text-[#444655] font-medium ${!isActive ? "hover:text-[#A9AABC]" : ""}`}>
    {children}
  </p>
);
