/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Customer } from "@/models/customers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { orders } from "./Orders";

type Props = {
  customer: Customer;
};

export const CustomerOrders = ({ customer }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="ml-5 text-xs flex flex-col text-center">
      <div className="text center text-xs">Last Order</div>
      <div className="text-xs text-center">
        {customer.orders.length ? (
          <div>{customer.orders[customer.orders.length - 1].totalPrice} SDG</div>
        ) : (
          <div className="text-white text-[10px] bg-gray-500 p-1 rounded-full">No Orders Yet</div>
        )}
      </div>
      <div className="text-blue-700 text-center cursor-pointer">
        {customer.orders.length !== 0 && (
          <div
            onClick={() => {
              orders({
                orders: customer.orders,
                onClick: (id) => {
                  navigate(`/orders/${id}`);
                }
              });
            }}
          >
            View All Orders
          </div>
        )}
      </div>
    </div>
  );
};
