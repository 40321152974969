import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { Customer } from "@/models/customers";
import { ICustomersApi } from "./ICustomersApi";

@injectable()
export class CustomersApi implements ICustomersApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/customer";

  async getAllCustomers(
    skip: number,
    query: string
  ): Promise<{ count: number; customers: Customer[] }> {
    const { data } = await this.client.get<{ count: number; customers: Customer[] }>(this.baseUrl, {
      params: { skip, query }
    });
    return data;
  }

  async getCustomerById(customerId: string): Promise<Customer> {
    const fullUrl = `${this.baseUrl}/${customerId}`;
    const { data } = await this.client.get<Customer>(fullUrl);
    return data;
  }

  async stickNote({ customerId, note }: { customerId: string; note: string }): Promise<Customer> {
    const fullUrl = `${this.baseUrl}/note/${customerId}`;
    const { data } = await this.client.post<Customer>(fullUrl, { note });
    return data;
  }

  async deleteNote({
    customerId,
    noteId
  }: {
    customerId: string;
    noteId: string;
  }): Promise<Customer> {
    const fullUrl = `${this.baseUrl}/note/${customerId}/${noteId}`;
    const { data } = await this.client.delete<Customer>(fullUrl);
    return data;
  }

  async stickLabel({
    customerId,
    label
  }: {
    customerId: string;
    label: string;
  }): Promise<Customer> {
    const fullUrl = `${this.baseUrl}/${customerId}`;
    const { data } = await this.client.post<Customer>(fullUrl, { label });
    return data;
  }

  async updateCustomer({
    customerId,
    updates
  }: {
    customerId: string;
    updates: any;
  }): Promise<Customer> {
    const fullUrl = `${this.baseUrl}/${customerId}`;
    const { data } = await this.client.put<Customer>(fullUrl, updates);
    return data;
  }
}
