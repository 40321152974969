/* eslint-disable */
import { inject, injectable } from "inversify";
import type { CustomAxiosRequestConfig } from "axios-auth-refresh/dist/utils";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { IAuthApi } from "./IAuthApi";
import { UserCredentials, SignedInUser } from "@/models/auth";

@injectable()
export class AuthApi implements IAuthApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/auth";

  // @ts-ignore
  private readonly configs: CustomAxiosRequestConfig = {
    skipAuthRefresh: true,
    withCredentials: true
  };

  async signIn({ email, password }: UserCredentials): Promise<SignedInUser> {
    const fullUrl = `${this.baseUrl}/signin`;
    const { data } = await this.client.post<SignedInUser>(
      fullUrl,
      { username: email, password },
      this.configs
    );
    return data;
  }

  async checkRegistration(email: string): Promise<boolean> {
    // @ts-ignore
    const configs: CustomAxiosRequestConfig = {
      skipAuthRefresh: true
    };
    const fullUrl = `${this.baseUrl}/check`;
    const { data } = await this.client.post<{ exists: boolean }>(fullUrl, { email }, configs);
    return data.exists;
  }

  async signUp(credentials: UserCredentials): Promise<SignedInUser> {
    const fullUrl = `${this.baseUrl}/signup`;
    const { data } = await this.client.post<SignedInUser>(fullUrl, credentials, this.configs);
    return data;
  }

  async revokeToken(): Promise<void> {
    const fullUrl = `${this.baseUrl}/token`;
    const { data } = await this.client.delete(fullUrl, this.configs);
  }
}
