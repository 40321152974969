import { Activity } from "@/models/customers";
import { parseISO } from "date-fns";
import React from "react";

type _Props = {
  activity: Activity;
  customerName: string;
};

export const ActivityItem = ({ customerName, activity: { activity, time } }: _Props) => (
  <div className="flex flex-col space-y-2 py-4">
    <div className="flex space-x-2">
      <img className="w-4" src="/img/icons/clock.svg" alt="clock" />
      <div className="text-xs text-gray-400">{`${parseISO(time).toLocaleDateString()} - ${parseISO(
        time
      ).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
      })}`}</div>
    </div>
    <div className="flex justify-start items-start w-full space-x-2">
      <img className="w-4" src="/img/chat-icons/activity.svg" alt={activity} />
      <h5 className="text-xs">
        <p>{customerName}</p>
        <span className="text-green-600 capitalize">{activity}</span>
      </h5>
    </div>
  </div>
);
