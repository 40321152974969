import { Bar } from "react-chartjs-2";
import formatDate from "date-fns/fp/format";
import parseISO from "date-fns/fp/parseISO";
import { SocialPlatforms } from "@/models/constants";
import styles from "./charts.module.css";

type _StackedBarChartProps = { platform: SocialPlatforms; values: number[] };

export const StackedBarChart = ({
  title,
  days,
  isLoading,
  data
}: {
  title: string;
  days: string[];
  isLoading?: boolean;
  data: Array<_StackedBarChartProps>;
}) => {
  const formatXLabels = formatDate("MMM");
  return (
    <div className={`w-[750px] ${styles.chart_card_container}`}>
      <h1 className="sm:py-5 text-xl font-semibold">{title}</h1>
      {isLoading && (
        <div className="flex m-[100px]">
          <div className="spinner-loader m-auto w-14 h-14" />
        </div>
      )}
      {!isLoading && (
        <Bar
          data={{
            datasets: [
              {
                label: SocialPlatforms.Facebook,
                data: data.find((d) => d.platform === SocialPlatforms.Facebook)?.values,
                borderColor: "rgb(59, 89, 162)",
                backgroundColor: "rgba(59, 89, 162, 0.7)",
                borderWidth: 2,
                barThickness: 8
              },
              {
                label: SocialPlatforms.Instagram,
                data: data.find((d) => d.platform === SocialPlatforms.Instagram)?.values,
                borderColor: "rgb(255, 130, 111)",
                backgroundColor: "rgba(255, 130, 111, 0.7)",
                borderWidth: 2,
                barThickness: 8
              },
              {
                label: SocialPlatforms.Whatsapp,
                data: data.find((d) => d.platform === SocialPlatforms.Whatsapp)?.values,
                borderColor: "rgb(34, 193, 151)",
                backgroundColor: "rgba(34, 193, 151, 0.7)",
                borderWidth: 2,
                barThickness: 8
              }
            ],
            labels: days.map((day) => formatXLabels(parseISO(day)))
          }}
          options={{
            plugins: { legend: { position: "right" } },
            responsive: true
          }}
        />
      )}
    </div>
  );
};
