import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@/components/Cards";
import { Button } from "@/components/Buttons";
import { exposeToast } from "@/components/Toasts";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordField } from "@/components/Forms";
import { usePasswordReset } from "./hooks/useVerify";
import { ResetPasswordFormValidationSchema } from "./utils/ValidationSchema";

type _ResetPassword = {
  password: string;
  confirmPassword: string;
};
export const PasswordReset = () => {
  const { key } = useParams();
  const { isLoading, mutate: reset } = usePasswordReset();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<_ResetPassword>({
    defaultValues: { password: "", confirmPassword: "" },
    resolver: yupResolver(ResetPasswordFormValidationSchema())
  });

  const onSubmit = (values: { password: string; confirmPassword: string }) => {
    reset(
      { password: values.password, key: key! },
      {
        onSuccess() {
          exposeToast({
            type: "success",
            message: "Your Password Has Been Changed Successfully"
          });
          navigate("/auth/signin");
        },
        onError() {
          exposeToast({ type: "error", message: "This link is expired or invalid" });
          navigate("/account/reset-password");
        }
      }
    );
  };
  if (isLoading)
    return (
      <div className="flex m-[100px]">
        <div className="spinner-loader m-auto w-14 h-14" />
      </div>
    );
  return (
    <div className=" flex flex-col gap-5 items-center justify-center p-32 ">
      <Card>
        <div className=" flex flex-col  items-center">
          <div className="w-32">
            <img src="/img/enigma.png" alt="enigma-ai" />
          </div>
          <div>Enter New Password</div>
          <div className=" p-8 w-full space-y-4">
            <PasswordField
              name="password"
              label="Password"
              placeholder="••••••••"
              register={register}
              error={errors.password?.message}
            />
            <PasswordField
              type="password"
              name="confirmPassword"
              label="Confirm password"
              placeholder="••••••••"
              register={register}
              error={errors.confirmPassword?.message}
            />
            <div>
              <Button variant="btn-block" onClick={() => handleSubmit(onSubmit)()}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
