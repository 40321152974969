import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "@/components/Modals";

type _DeleteNodeDialogProps = {
  show: boolean;
  nodeTitle: string;
  type?: string;
  onAccept: () => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const DeleteNodeDialog = ({
  show,
  type,
  nodeTitle,
  onAccept,
  onCancel,
  onClose,
  ...rest
}: _DeleteNodeDialogProps) => {
  const [visible, setVisible] = useState(show);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  return (
    <BaseDialog
      title="Delete Node"
      show={visible}
      body={
        <div className="p-[24px]">
          <div className="flex w-[500px]">
            <img
              className="w-14 mx-6 text-center"
              src="/img/flow-icons/trash-can-red.svg"
              alt="trash_node"
            />
            <p className="text-md">
              Are you sure you want to delete the node{" "}
              <span className="font-semibold">{`${nodeTitle}`}</span> of type{" "}
              <span className="font-semibold">{`<<${type}>>`}</span>?
            </p>
          </div>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-gray-500 bg-white hover:bg-gray-100 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            No, cancel
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="text-white bg-[#c01812] hover:bg-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => {
              onAccept();
              close();
            }}
          >
            Yes, I’m sure
          </div>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const deleteNodeDialog = ({
  nodeTitle,
  type,
  accept,
  cancel
}: {
  nodeTitle: string;
  type?: string;
  accept: () => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "delete-node-dialog";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <DeleteNodeDialog
          show
          type={type}
          nodeTitle={nodeTitle}
          onAccept={() => {
            accept();
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
