import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BaseDialog } from "./BaseDialog";
import styles from "./styles.module.css";

type _ImagesPreviewDialogProps = {
  show: boolean;
  images: string[];
  onAccept: () => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const ImagesPreviewDialog = ({
  show,
  images,
  onAccept,
  onCancel,
  onClose,
  ...rest
}: _ImagesPreviewDialogProps) => {
  const [visible, setVisible] = useState(show);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };

  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  return (
    <BaseDialog
      title={`${images.length} images selected`}
      show={visible}
      body={
        <div className={`${styles.selected_images_container} space-y-4`}>
          {images.map((image) => (
            <img
              key={image}
              className={`${styles.selected_image} border-2 border-gray-50`}
              src={image}
              alt={image}
            />
          ))}
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => {
              onAccept();
              close();
            }}
          >
            <p className="font-semibold ">Send</p>
          </div>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const imagesPreviewDialog = ({
  images,
  accept,
  cancel
}: {
  images: string[];
  accept: () => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "images-preview-dialog";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <ImagesPreviewDialog
          show
          images={images}
          onAccept={() => {
            accept();
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
