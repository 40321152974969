import { useEffect } from "react";
import Cookies from "js-cookie";
import { Button } from "@/components/Buttons";
import { useParams, useNavigate } from "react-router-dom";
import { useVerifyUser } from "./hooks/useVerify";

export const EmailVerification = () => {
  const { key } = useParams();
  const { data: user, isLoading } = useVerifyUser(key!);
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("SESSION_ID");
  }, []);

  if (isLoading)
    return (
      <div className="flex m-[100px]">
        <div className="spinner-loader m-auto w-14 h-14" />
      </div>
    );

  return user?.isValid ? (
    <div className="flex flex-col items-center p-36 gap-8">
      <div className="w-32">
        <img src="/img/enigma.png " alt="enigma-ai" />
      </div>
      <div className="bg-green-400 p-2 rounded-full">
        <h1 className="text-xl text-white">Your Email Has Been Verified Successfully.</h1>
      </div>
      <span>
        <Button variant="btn-block" onClick={() => navigate("/auth/signin")}>
          Login
        </Button>
      </span>
    </div>
  ) : (
    <div className=" flex flex-col items-center p-36 gap-8">
      <div className="w-32">
        <img src="https://www.enigma-ai.com/assets/img/enigma.png" alt="enigma-ai" />
      </div>
      <div className="bg-red-400 p-2 rounded-full">
        <h1 className=" text-xl text-white">The Key is Invalid Or Expired.</h1>
      </div>
      <div>Login and try again!</div>
      <div>
        <Button variant="btn-block" onClick={() => navigate("/auth/signin")}>
          Login
        </Button>
      </div>
    </div>
  );
};
