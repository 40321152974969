import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";

type _AuthFormWrapperProps = {
  title: string;
  isLoading: boolean;
  submitText: string;
  linkDesc: string;
  linkText: string;
  link: string;
  onSubmit: Function;
};

export const AuthFormWrapper = ({
  title,
  submitText,
  isLoading,
  linkDesc,
  linkText,
  link,
  onSubmit,
  children
}: PropsWithChildren<_AuthFormWrapperProps>) => (
  <Card classes="animate__animated animate__fadeIn">
    <div className="p-6 space-y-4">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
        {title}
      </h1>
      <h2 className="text-lg">Catch up with your customers</h2>
      {children}

      {/* TODO: (Asim) remember me checkbox */}
      {isLoading ? (
        <div className="flex justify-center">
          <div className="spinner-loader w-7 h-7" />
        </div>
      ) : (
        <Button variant="btn-block" onClick={() => onSubmit()}>
          {submitText}
        </Button>
      )}
      <p className="text-sm font-light text-gray-500">
        {linkDesc}
        <Link to={link} className="btn btn-link px-1">
          {linkText}
        </Link>
      </p>
      <div className="text-center">
        <Link to="/account/reset-password" className="btn btn-link text-xs">
          I FORGOT MY PASSWORD!
        </Link>
      </div>
    </div>
  </Card>
);
