import { Note } from "@/models/customers";
import { useDeleteNote } from "@/pages/LiveChat/hooks/useCustomer";
import { parseISO } from "date-fns";
import React from "react";

type _Props = {
  note: Note;
  customerId: string;
  onUpdate: () => void;
};

export const SingleNote = ({ note: { _id, note, time }, customerId, onUpdate }: _Props) => {
  const { mutate: deletNote } = useDeleteNote();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between space-x-2 items-center text-gray-400 text-xs pt-2">
        <div className="flex space-x-2">
          <img className="w-4" src="/img/icons/clock.svg" alt="clock" />
          <p>
            {`${parseISO(time).toLocaleDateString()} - ${parseISO(time).toLocaleTimeString(
              "en-US",
              {
                hour: "2-digit",
                minute: "2-digit"
              }
            )}`}
          </p>
        </div>
        {/* eslint-disable-next-line */}
        <p
          className=" cursor-pointer hover:text-[#d15151]"
          onClick={() => {
            deletNote(
              { customerId, noteId: _id },
              {
                onSuccess() {
                  onUpdate();
                }
              }
            );
          }}
        >
          Delete
        </p>
      </div>
      <div className="flex space-x-2 justify-start items-center text-sm text-gray-900 py-2">
        <img className="w-4" src="/img/chat-icons/bookmark.svg" alt="bookmark" />
        <p>{note}</p>
      </div>
    </div>
  );
};
