import { User } from "@/models/auth";
import React from "react";

type Props = {
  user: User;
};

export const OrganizationDetails = ({ user }: Props) => (
  <div className="flex flex-col py-8 space-y-5 text-sm">
    <div className="flex flex-row space-x-3  ">
      <div className="text-xs text-gray-500">Organization :</div>
      <div>{user.organization}</div>
    </div>
    <div className="flex flex-row space-x-3  p-1">
      <div className=" text-xs text-gray-500">Email :</div>
      <div>{user?.organizationInfo?.email}</div>
    </div>
    <div className="flex flex-row space-x-3  p-1">
      <div className="text-xs text-gray-500">Website :</div>
      <div>{user?.organizationInfo?.website}</div>
    </div>
    <div className="flex flex-row space-x-3  p-1">
      <div className="text-xs text-gray-500">Phone :</div>
      <div>{user?.organizationInfo?.phone}</div>
    </div>
    <div className="flex flex-row space-x-3  p-1">
      <div className="text-xs text-gray-500">Address :</div>
      <div>{user?.organizationInfo?.address}</div>
    </div>
  </div>
);
