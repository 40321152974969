import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { OrderStatus, OrderPage, Order, Platform } from "@/models/orders";
import { HttpClient } from "@/services/client";
import { inject, injectable } from "inversify";
import { IOrdersApi } from "./IOrdersApi";

@injectable()
export class OrdersApi implements IOrdersApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/order";

  async getOrders(
    skip: number,
    status?: OrderStatus,
    platform?: Platform,
    query?: string
  ): Promise<OrderPage> {
    const { data } = await this.client.get<OrderPage>(this.baseUrl, {
      params: { skip, status, platform, query }
    });
    return data;
  }

  async getOrderById(id: string): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${id}`;
    const { data } = await this.client.get<Order>(fullUrl);
    return data;
  }

  async confirmOrder(id: string): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${id}/confirm`;
    const { data } = await this.client.post<Order>(fullUrl);
    return data;
  }

  async rejectOrder(id: string): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${id}/reject`;
    const { data } = await this.client.post<Order>(fullUrl);
    return data;
  }

  async toggleHideOrder(id: string): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${id}/toggle-hide`;
    const { data } = await this.client.post<Order>(fullUrl);
    return data;
  }

  async addOrder(order: any): Promise<Order> {
    const fullUrl = `${this.baseUrl}/create`;
    const { data } = await this.client.post<Order>(fullUrl, order);
    return data;
  }

  async setDeliveryDate({
    orderId,
    deliveryDate
  }: {
    orderId: string;
    deliveryDate: any;
  }): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${orderId}/delivery`;
    const { data } = await this.client.post<Order>(fullUrl, deliveryDate);
    return data;
  }

  async deleteDeliveryDate(orderId: string): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${orderId}/delivery`;
    const { data } = await this.client.delete<Order>(fullUrl);
    return data;
  }

  async updateOrder({ orderId, updates }: { orderId: string; updates: any }): Promise<Order> {
    const fullUrl = `${this.baseUrl}/${orderId}/update`;
    const { data } = await this.client.post<Order>(fullUrl, updates);
    return data;
  }
}
