import { NodeProps, Position } from "react-flow-renderer";
import { CatalogNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { CatalogNodeForm } from "../DialogForms";

const DragElement = <BaseNode title="Catalog" color="#0045A4" isDragElement />;

const NodeComponent = ({ ...rest }: NodeProps<CatalogNodeData>) => (
  <>
    <BaseHandle type="target" position={Position.Top} />
    <BaseNode
      title="Catalog"
      color="#0045A4"
      icon="four-squares.svg"
      form={<CatalogNodeForm />}
      {...rest}
    />
    <BaseHandle type="source" position={Position.Bottom} />
  </>
);

export const CatalogNode: NodeType = {
  type: "catalog",
  component: NodeComponent,
  dragElement: DragElement
};
