import { useForm } from "react-hook-form";
import { TextFieldWithIcon } from "@/components/Forms";
import { SettingsFormWrapper } from "@/components/Settings";
import { User } from "@/models/auth";

type Props = {
  user: User;
};
export const OrganizationDetailsForms = ({ user }: Props) => {
  const { register } = useForm<User>({
    defaultValues: user ?? {
      organization: "",
      organizationInfo: {
        email: "",
        phone: "",
        address: ""
      }
    }
  });
  return (
    <div className="p-5 flex flex-col w-full  ">
      <SettingsFormWrapper onSubmit={() => {}}>
        <TextFieldWithIcon
          name="organization"
          icon="user.svg"
          label="Organization Name"
          placeholder="Your official business name"
          register={register}
        />
        <TextFieldWithIcon
          name="organizationInfo.website"
          icon="envelope.svg"
          label="Website"
          placeholder="The business web site URL"
          register={register}
        />
        <TextFieldWithIcon
          name="organizationInfo.email"
          icon="user.svg"
          label="Email Address"
          placeholder="Your customer support e-mail address"
          register={register}
        />
        <TextFieldWithIcon
          name="organizationInfo.phone"
          icon="envelope.svg"
          label="Telephone Number"
          placeholder="Your customer support number"
          register={register}
        />
        <TextFieldWithIcon
          name="organizationInfo.address"
          icon="envelope.svg"
          label="Street Address"
          placeholder="The street address of your office"
          register={register}
        />{" "}
      </SettingsFormWrapper>
    </div>
  );
};
