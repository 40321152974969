import { useFormContext } from "react-hook-form";
import { SelectField } from "@/components/Forms";
import { Category } from "@/models/categories";
import { CategoryNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const CategoryNodeForm = ({ categories }: { categories: Category[] }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext<CategoryNodeData>();

  return (
    <NodeFormWrapper description=" This will show the customer the products of one category from your catalog.">
      <SelectField
        name="categoryId"
        register={register}
        label="Category"
        options={categories.map(({ name, _id }) => ({
          label: name,
          value: _id
        }))}
        error={errors.categoryId?.message}
      />
    </NodeFormWrapper>
  );
};
