export const AnimatedOrdersTable = () => (
  <div className=" container p-10">
    <div className="flex flex-col space-y-8 justify-center p-10">
      <div className=" flex flex-row  justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
      <div className=" flex flex-row justify-between">
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <div className="skeleton w-[42px] h-[42px] rounded-full" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
      </div>
    </div>
  </div>
  /* div
  <Table
    headers={["Status", "Customer Name", "Platform", "delivery", "Total(SDG)", "Payment Method"]}
  >
    {Array.from([1, 2, 3, 4, 5, 6, 7]).map((n, index) => (
      <tr
        className={`border-b border-gray-200  hover:bg-gray-100 ${
          index % 2 === 0 ? "bg-gray-50" : ""
        } cursor-pointer`}
        key={n}
      >
        <TableItem>
          <div className="skeleton w-[42px] h-[42px] rounded-full" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <div className="skeleton w-[42px] h-[42px] rounded-full" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
        <TableItem>
          <p className="skeleton w-[100px] h-[10px] rounded-[2px]" />
        </TableItem>
      </tr>
    ))}
  </Table> */
);
