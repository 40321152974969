import styles from "./profile-menu.module.css";

type _ProfileItem = {
  text: string;
  icon: string;
  onClick: Function;
};

export const ProfileMenuItem = ({ text, icon, onClick }: _ProfileItem) => (
  // eslint-disable-next-line
  <div
    className={`flex items-center cursor-pointer ${styles.profile_menu_item}`}
    onClick={() => onClick()}
  >
    <img className="w-4 mr-2" src={`/img/icons/${icon}`} alt={text} />
    <p className=" text-sm">{text}</p>
  </div>
);
