/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from "@/components/Buttons";
import { Card } from "@/components/Cards";
import { FileUploader, TextArea, TextField } from "@/components/Forms";
import { AddCategoryValidationSchema } from "@/pages/Catalogs/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useForm } from "react-hook-form";
import { BaseDialog } from "@/components/Modals/components/BaseDialog";
import { imagesPreviewDialog } from "@/components/Modals";
import { Category, CategoryData } from "@/models/categories";

type CategoryEditeProps = {
  show: boolean;
  category: Category;
  onUpdate: (data: any) => void;
  onCancel: () => void;
  onClose?: () => void;
  wrapperId?: string;
};

export const CategoryEditeModal = ({
  category,
  show,
  onClose,
  onUpdate,
  onCancel,
  ...rest
}: CategoryEditeProps) => {
  const [visible, setVisible] = useState(show);
  const [images, setImages] = useState<string[]>([]);
  const [imgbools, setImgbools] = useState(category.images?.map(() => false));

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CategoryData>({
    defaultValues: {
      name: category?.name,
      arabicName: category.arabicName,
      desc: category.arabicDesc,
      arabicDesc: category.arabicDesc
    },
    resolver: yupResolver(AddCategoryValidationSchema())
  });
  useEffect(() => {
    if (show && !visible) setVisible(true);
  }, [show]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    setVisible(false);
  };
  const onImgClick = (index: number) => {
    const newImgbools = [...imgbools];
    newImgbools[index] = !newImgbools[index];
    setImgbools(newImgbools);
  };

  const onImageChange = async (event: any) => {
    const files = [...event.target.files];
    if (files.length > 0) {
      imagesPreviewDialog({
        images: files.map((image) => URL.createObjectURL(image)),
        accept: async () => {
          const imageUrls: Array<string> = await Promise.all<string>(
            files.map(
              (image) =>
                new Promise<any>((resolve) => {
                  const reader = new FileReader();
                  reader.onload = function _(upload): void {
                    resolve(upload?.target?.result);
                  };
                  reader.readAsDataURL(image);
                })
            )
          );
          setImages(imageUrls);
        }
      });
    }
  };
  const onSubmit = (values: CategoryData) => {
    const remainingImages = category.images?.filter((img, ind) => !imgbools[ind]);
    const data: { cat: any; categoryId: string } = {
      categoryId: category._id,
      cat: {
        ...values,
        newImages: images,
        remainingImages
      }
    };
    onUpdate(data);
  };
  return (
    <BaseDialog
      title={`Edite ${category.name} `}
      show={visible}
      body={
        <div className="overflow-auto">
          <Card>
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8 sm:w-fit w-96 ">
              <div className="flex flex-wrap flex-1 sm:space-x-10">
                <TextField
                  name="name"
                  label="Category Name"
                  placeholder="Category Name"
                  register={register}
                  error={errors.name?.message}
                />
                <TextField
                  name="arabicName"
                  label="إسم الصنف"
                  placeholder="أدخل اسم الصنف"
                  register={register}
                  isRtl
                  error={errors.arabicName?.message}
                />
              </div>

              <TextArea
                name="desc"
                rows={2}
                placeholder="Description"
                label="Description"
                register={register}
                error={errors.desc?.message}
              />
              <TextArea
                name="arabicDesc"
                rows={2}
                placeholder="أدخل وصف الصنف"
                label="وصف الصنف"
                register={register}
                isRtl
                error={errors.arabicDesc?.message}
              />
              <div className="mt-8 block mb-2 text-sm font-medium text-gray-900">
                Select Images to delete:
                <div className=" flex flex-wrap  flex-1 content-between mt-5">
                  {category.images.map((img, index) => (
                    <div key={img}>
                      <img
                        className={` w-14 ml-3   rounded-lg ${
                          imgbools[index] ? " border-4 border-red-500" : "border-0 "
                        }`}
                        onClick={() => onImgClick(index)}
                        src={img}
                        alt={img}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <FileUploader label="Upload New Images" onChange={onImageChange} accept="/images.*" />
            </div>
          </Card>
        </div>
      }
      action={
        <div className="flex justify-end items-center p-[15px] space-x-2 rounded-b border-t-2">
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="px-3 py-2 hover:bg-[#f87272] rounded-lg"
            onClick={() => {
              onCancel();
              close();
            }}
          >
            <p className="text-[#500606] font-medium">Cancel</p>
          </div>
          {/* eslint-disable-next-line */}
          <Button
            variant="btn-wide"
            //  isLoading={isLoading}
            role="button"
            className="px-4 py-2 text-[#044CAC] hover:bg-[#044CAC] hover:text-white rounded-lg"
            onClick={() => handleSubmit(onSubmit)()}
          >
            <p className="font-semibold ">Submit</p>
          </Button>
        </div>
      }
      onClose={() => {
        close();
      }}
      {...rest}
    />
  );
};

export const categoryEditeModal = ({
  category,
  update,
  cancel
}: {
  category: Category;
  update: (data: any) => void;
  cancel?: () => void;
}) => {
  const wrapper = document.body.appendChild(document.createElement("div"));
  wrapper.id = "category-edite-modal";

  const root = createRoot(wrapper);

  const promise = new Promise<Boolean>((resolve) => {
    try {
      root.render(
        <CategoryEditeModal
          show
          category={category}
          onUpdate={(data: any) => {
            update(data);
            resolve(true);
          }}
          onCancel={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          onClose={() => {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            resolve(false);
          }}
          wrapperId={wrapper.id}
        />
      );
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      root.unmount();
      setTimeout(() => {
        if (wrapper && wrapper.parentNode) {
          wrapper.parentNode.removeChild(wrapper);
        }
      });
    }, 600);
  }

  return promise.then(
    (result) => {
      dispose();
      return result;
    },
    (result) => {
      dispose();
      return Promise.reject(result);
    }
  );
};
