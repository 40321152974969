import { Connection, Handle, HandleProps, useReactFlow } from "react-flow-renderer";

export const BaseHandle = ({ isValidConnection, ...rest }: HandleProps) => {
  const reactFlow = useReactFlow();
  const validateConnection = (connection: Connection) => {
    const { source, target } = connection;
    const sourceNode = reactFlow.getNode(source!);
    const connectionsExhausted =
      sourceNode?.type !== "choices" && reactFlow.getEdges().some((edge) => edge.source === source);
    const targetNode = reactFlow.getNode(target!);
    const compatible = targetNode?.type !== "faq" || sourceNode?.type === "choices";
    return (
      source !== target &&
      !connectionsExhausted &&
      compatible &&
      (isValidConnection?.(connection) ?? true)
    );
  };
  return <Handle isValidConnection={validateConnection} {...rest} />;
};
