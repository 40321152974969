import React, { useId, useState } from "react";
import { Path, UseFormRegister, FieldValues } from "react-hook-form";
import { FormErrorMessage } from "../FormErrorMessage";

type _BaseProps<T extends FieldValues> = {
  name: Path<T>;
  register: UseFormRegister<T>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
};

type _InputProps<T extends FieldValues> = _BaseProps<T> &
  Omit<React.ComponentPropsWithoutRef<"input">, keyof _BaseProps<T>>;

export const PasswordField = <T extends FieldValues>({
  name,
  register,
  label,
  placeholder,
  disabled,
  error,
  ...others
}: _InputProps<T>) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const id = useId();

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium mb-2 text-gray-900">
        {label}
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <img className="w-5 h-5 fill-gray-500" src="/img/icons/key.svg" alt={name} />
        </div>
        <input
          type={isRevealed ? "text" : "password"}
          {...register(name)}
          id={id}
          className={`bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800 block w-full px-10 p-2.5 ${
            disabled ? "bg-gray-100 cursor-not-allowed" : ""
          } ${
            error
              ? "bg-red-50  border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
              : ""
          }
          `}
          placeholder={placeholder}
          {...others}
        />

        {/* eslint-disable-next-line */}
        <div
          role="button"
          className="absolute inset-y-0 right-0 flex items-center pr-3"
          onClick={() => setIsRevealed(!isRevealed)}
        >
          <img
            className="w-5 h-5 fill-gray-500"
            src={`/img/icons/${isRevealed ? "closed-eye" : "opened-eye"}.svg`}
            alt={name}
          />
        </div>
      </div>
      <FormErrorMessage error={error} />
    </div>
  );
};
