import { useForm } from "react-hook-form";
import { ChatAttachments } from "@/components/Attachments/ChatAttachments";
import { imagesPreviewDialog } from "@/components/Modals";
import { useMessageReplay } from "@/pages/LiveChat/hooks/chat-hooks";
import { useChat } from "@/pages/LiveChat/context";
import styles from "../chat.module.css";
import { generateImagesUrls } from "../utils/generateImagesUrls";
import { ChatMicButton } from "./ChatMicButton";
import { ChatRecorder } from "./ChatRecorder";

type MessageText = { message: string };

export const MessageInput = () => {
  const {
    recorder: { status }
  } = useChat();

  const recorderIsActive = status === "recording" || status === "paused" || status === "stopping";

  const { register, handleSubmit, reset, watch } = useForm<MessageText>({
    defaultValues: {
      message: ""
    }
  });
  const message = watch("message");

  const { mutate } = useMessageReplay();

  const onSend = ({ message: text }: MessageText) => {
    mutate({
      text,
      images: [],
      audio: "",
      sender: { name: "MTN" }
    });

    reset();
  };

  return (
    <div className={`flex justify-between ${styles.chat_message_input} space-x-2`}>
      {recorderIsActive ? (
        <ChatRecorder />
      ) : (
        <div className="relative w-full">
          <div className="absolute flex items-center inset-y-0 right-0 mr-3">
            <ChatAttachments
              onImagesChanged={(images) => {
                if (images.length > 0) {
                  imagesPreviewDialog({
                    images: images.map((image) => URL.createObjectURL(image)),
                    accept: async () => {
                      const urls = await generateImagesUrls(images);
                      mutate({
                        text: message,
                        images: urls,
                        audio: "",
                        sender: { name: "MTN" }
                      });
                      reset();
                    }
                  });
                }
              }}
            />
          </div>

          <input
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSend)();
              }
            }}
            type="text"
            placeholder="Type your message..."
            {...register("message")}
            className="bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-full block w-full p-2.5 focus:outline-none focus:border-2 focus:ring-primary-800 focus:border-primary-800 pl-5 pr-10"
          />
        </div>
      )}

      <ChatMicButton />
      {/* eslint-disable */}
      <button
        role="button"
        disabled={recorderIsActive}
        className="bg-[#044cac] p-[11px] rounded-full"
        onClick={() => handleSubmit(onSend)()}
      >
        <img className="w-5" src="/img/chat-icons/send-icon.svg" alt="send_icon" />
      </button>
    </div>
  );
};
