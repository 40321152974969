import { formatDistanceToNowStrict } from "date-fns";

export const toTimeSince = (date: Date) => {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = seconds / 31536000;

  interval = seconds / 86400;
  if (interval > 1) {
    return date.toLocaleDateString("en-UK");
  }
  return `${formatDistanceToNowStrict(date)} ago`;
};
