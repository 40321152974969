import { ICategoriesApi } from "@/api/category";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { CategoryData } from "@/models/categories";
import { useMutation, useQuery } from "react-query";

export const useCategories = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useQuery("categories", () => categoriesApi.getAllCategories());
};

export const useRootCategories = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useQuery("root-category", () => categoriesApi.getRootCategories());
};

export const useCategoryById = (categoryId: string) => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useQuery(`category${categoryId}`, () => categoriesApi.getCategoryById(categoryId));
};

export const useDeleteCategory = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useMutation("deleted-cat", (categoryId: string) =>
    categoriesApi.deleteCategory(categoryId)
  );
};

export const useHideCategory = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useMutation("hidden-cat", (categoryId: string) => categoriesApi.hideCategory(categoryId));
};

export const useAddCategory = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useMutation<any, any, CategoryData>("new-category", (category: CategoryData) =>
    categoriesApi.addCategory(category)
  );
};

export const useEditCategoryWoImg = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useMutation("cat", (data: any) => categoriesApi.editCategoryWoImg(data));
};

export const useUpdateCategory = () => {
  const categoriesApi = useInjection<ICategoriesApi>(SERVICES_TYPES.CATEGORIES);
  return useMutation("u-cat", (data: any) => categoriesApi.updateCategory(data));
};
