import { Order } from "@/models/orders";
import { DeliveryItem, OrderTime } from "../orders-table";
import { OrderNote } from "./OrderNote";

type Props = {
  order: Order;
};
export const OrderHeader = ({ order }: Props) => (
  <div className="flex flex-row justify-between p-8 flex-wrap">
    <DeliveryItem order={order} onChange={() => {}} />
    <OrderNote order={order} />
    <OrderTime order={order} />
  </div>
);
