import { NodeProps, Position } from "react-flow-renderer";
import { MediaNodeData } from "@/models/flow";
import { BaseHandle, BaseNode } from "../BaseNode";
import { NodeType } from "../../types";
import { MediaNodeForm } from "../DialogForms";
import { MediaFormValidationObject } from "../DialogForms/validators";

const DragElement = <BaseNode title="Media" color="#DE5063" isDragElement />;

const NodeComponent = ({ data, ...rest }: NodeProps<MediaNodeData>) => {
  const { label, mediaType } = data;
  return (
    <>
      <BaseHandle type="target" position={Position.Top} />
      <BaseNode
        title={`${label ?? "Media"} [${mediaType}]`}
        icon="media-gallery.svg"
        color="#DE5063"
        data={data}
        form={<MediaNodeForm />}
        validationObject={MediaFormValidationObject}
        {...rest}
      />
      <BaseHandle type="source" position={Position.Bottom} />
    </>
  );
};

export const MediaNode: NodeType = {
  type: "media",
  component: NodeComponent,
  dragElement: DragElement
};
