import { Button } from "@/components/Buttons";
import { useState } from "react";

type Props = {
  onQChange: (query: string) => void;
};

export const Filter = ({ onQChange }: Props) => {
  const [query, setQuery] = useState<string>("");

  return (
    <div className="w-full flex flex-col  items-center ">
      <div className=" w-80 flex flex-row items-center justify-center flex-wrap self-center">
        <div className=" relative">
          <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
            </svg>
          </span>
          <input
            onChange={(e) => {
              const v = e.target.value;
              setQuery(v);
            }}
            placeholder="Customer Name"
            className="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
          />
        </div>
        <div className="items-center mx-3 my-3">
          <Button
            variant="btn-block"
            onClick={() => {
              onQChange(query);
            }}
          >
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
};
