import { useMutation, useQuery } from "react-query";
import { IUserApi } from "@/api/user";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { DeliveryData } from "@/models/DeliveryArea";

export const useDeleveryAreas = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useQuery("areas", () => userApi.getDeliveryAreas(), {
    refetchOnMount: true
  });
};

export const useEnableDelivery = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<any, any, any>((enabled) => userApi.setDeliveryEnabled(enabled));
};

export const useNewArea = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<any, any, DeliveryData>((data: DeliveryData) => userApi.addDeliveryArea(data));
};

export const useDeleteArea = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<any, any, string>((locationId: string) =>
    userApi.deleteDeliveryArea(locationId)
  );
};

export const useEditArea = () => {
  const userApi = useInjection<IUserApi>(SERVICES_TYPES.USER);
  return useMutation<any, any, any>(
    (data: { locationId: string; deliveryAreaData: DeliveryData }) =>
      userApi.updateDeliveryArea(data)
  );
};
