import { useFormContext } from "react-hook-form";
import { TextArea, TextField } from "@/components/Forms";
import { PauseNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const PauseNodeForm = () => {
  const { register } = useFormContext<PauseNodeData>();

  return (
    <NodeFormWrapper description=" When a user selects this node, the chatbot will stop handling their messages.">
      <TextField name="label" register={register} label="Button Title" />
      <TextField name="labelAR" register={register} label="اسم الزر" isRtl />
      <TextArea rows={4} name="text" register={register} label="Message" />
      <TextArea rows={4} name="textAR" register={register} label="الرسالة" isRtl />
    </NodeFormWrapper>
  );
};
