import { PropsWithChildren } from "react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { LayoutProvider } from "./context/LayoutContext";
import styles from "./layout.module.css";

export const Layout = ({ children }: PropsWithChildren) => (
  <LayoutProvider>
    <Navbar />
    <div className="relative bg-[#fcfcff]">
      <Sidebar />
      <div className={`${styles.page_container}`}>{children}</div>
    </div>
  </LayoutProvider>
);
