import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@/components/Forms";
import { SettingsFormWrapper } from "@/components/Settings";
import { UserCredentials } from "@/models/auth";
import { ResetPasswordFormValidationSchema } from "../AccountSettings/utils/validationSchema";
import { useUpdateMe } from "../../hooks";

type _ResetPasswordFormType = Pick<UserCredentials, "password"> & {
  oldPassword: string;
  confirmPassword: string;
};

export const ResetPasswordForm = () => {
  const { mutate: updatePassword } = useUpdateMe();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<_ResetPasswordFormType>({
    resolver: yupResolver(ResetPasswordFormValidationSchema())
  });

  const onReset = (data: Omit<_ResetPasswordFormType, "confirmPassword">) => {
    updatePassword(data);
  };
  return (
    <div className="sm:p-8  ">
      <p
        style={{
          fontSize: 20,
          fontWeight: 500,
          color: "#044CAC"
        }}
      >
        Reset Password
      </p>
      <SettingsFormWrapper onSubmit={() => handleSubmit(onReset)()}>
        <div className="flex flex-col items-center">
          <div className="w-full max-w-sm">
            <TextField
              type="password"
              name="oldPassword"
              label="Old password"
              register={register}
              error={errors.oldPassword?.message}
            />
            <TextField
              type="password"
              name="password"
              label="New password"
              register={register}
              error={errors.password?.message}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Confirm password"
              register={register}
              error={errors.confirmPassword?.message}
            />
          </div>
        </div>
      </SettingsFormWrapper>
    </div>
  );
};
