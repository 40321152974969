import { PropsWithChildren } from "react";

export const NodeFormWrapper = ({
  description,
  children
}: PropsWithChildren<{ description: string }>) => (
  <>
    <p className="text-sm text-gray-600 mb-4">{description}</p>
    <div className="flex flex-col space-y-3">{children}</div>
  </>
);
