import { AvatarPlaceHolder } from "@/components/AvatarPlaceHolder";
import { Conversation } from "@/models/chat";
import { useChat } from "@/pages/LiveChat/context";
import { toTimeSince } from "../../../utils/date-format";
import { BotStatusSwitcher } from "./BotStatusSwitcher";
import styles from "../chat.module.css";

export const ChatHeader = ({ chat }: { chat: Conversation }) => {
  const { toggleCustomerProfile } = useChat();

  return (
    <div className={`${styles.chat_container_header}`}>
      <div className="flex justify-start items-center space-x-4">
        <AvatarPlaceHolder username={chat.user.name} width={40} />
        <div className="flex flex-col">
          <h2>{chat.user.name}</h2>
          {chat.lastActiveAt && (
            <p className="text-gray-500 text-xs">{`Last active ${toTimeSince(
              new Date(chat.lastActiveAt)
            )}`}</p>
          )}
        </div>
      </div>
      <div className="flex">
        {/* eslint-disable-next-line */}
        <img
          className="w-5 cursor-pointer"
          src="/img/chat-icons/screen-split.svg"
          alt="scree_split"
          onClick={() => toggleCustomerProfile()}
        />
        <BotStatusSwitcher chat={chat} />
      </div>
    </div>
  );
};
