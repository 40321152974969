import { useFormContext } from "react-hook-form";
import { TextField } from "@/components/Forms";
import { DeliveryNodeData } from "@/models/flow";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const DeliveryNodeForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<DeliveryNodeData>();

  return (
    <NodeFormWrapper description="Allows the user to select their preferred delivery area.">
      <TextField name="text" register={register} label="Message" error={errors.text?.message} />
      <TextField
        isRtl
        name="textAR"
        register={register}
        label="الرسالة"
        error={errors.textAR?.message}
      />
    </NodeFormWrapper>
  );
};
