import { Customer } from "@/models/customers";
import { ActivityItem } from "./ActivityItem";
import { CollapsableCustomerSection } from "../CollapsableCustomerSection";

type Props = {
  customer: Customer;
};

export const Activities = ({ customer }: Props) => {
  const activities = customer.activities.slice(-2);

  return (
    <div className="py-2">
      <CollapsableCustomerSection text="Activities">
        <div className="divide-y-2">
          {activities.map((activity) => (
            <ActivityItem key={activity._id} activity={activity} customerName={customer.name} />
          ))}
        </div>
      </CollapsableCustomerSection>
    </div>
  );
};
