/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import DtPicker from "react-calendar-datetime-picker";
import { Order } from "@/models/orders";
import { useEffect, useState } from "react";
import "react-calendar-datetime-picker/dist/index.css";
import { useAddDeliveryDate, useDeleteDeliveryDate } from "../../hooks/order-hooks";
import "./calender.css";

type Props = {
  order: Order;
  onChange: () => void;
};

export const Calender = ({ order, onChange }: Props) => {
  // eslint-disable-next-line
  const [now, setNow] = useState<any>();
  const [date, setDate] = useState<any>(null);
  const [delivery, setDelivery] = useState<string>();
  const { mutate: setDeliveryDate } = useAddDeliveryDate();
  const { mutate: clearDeleveryTime } = useDeleteDeliveryDate();

  const logDate = (deliveryDate: any) => {
    if (deliveryDate)
      setDelivery(
        `${deliveryDate.year}/${deliveryDate.month}/${deliveryDate.day} ${deliveryDate.hour}:${deliveryDate.minute} GMT+0200`
      );
  };

  const submit = () => {
    const dDate = new Date(delivery!).toLocaleString();
    setDeliveryDate(
      { orderId: order._id, deliveryDate: { deliveryDate: dDate } },
      {
        onSuccess() {
          onChange();
        }
      }
    );
  };

  useEffect(() => {
    if (order.deliveryDate !== undefined) {
      setDate({
        year: new Date(order?.deliveryDate).getUTCFullYear(),
        month: new Date(order?.deliveryDate).getUTCMonth() + 1,
        day: new Date(order.deliveryDate).getUTCDate(),
        hour: new Date(order.deliveryDate).getUTCHours() + 2,
        minute: new Date(order.deliveryDate).getUTCMinutes()
      });
    } else {
      setNow({
        year: new Date().getUTCFullYear(),
        month: new Date().getUTCMonth() + 1,
        day: new Date().getUTCDay(),
        hour: new Date().getUTCHours() + 2,
        minute: new Date().getUTCMinutes()
      });
    }
  }, [setDate, setNow]);
  return (
    <div className="flex">
      <DtPicker
        onCalenderHide={() => {
          submit();
        }}
        initValue={date}
        isRequired
        showTimeInput
        withTime
        onChange={(v) => logDate(v)}
        type="single"
        placeholder="Set Delivery Time"
        autoClose={false}
        inputClass="custom-input"
        headerClass="custom-header"
        calenderModalClass="calender-class"
      />
      <img
        className="w-5"
        src="img/icons/cross.svg"
        alt=""
        onClick={() => {
          setDate(null);
          clearDeleveryTime(order._id, {
            onSuccess() {
              onChange();
            }
          });
        }}
      />
    </div>
  );
};
