import { Order } from "@/models/orders";
import { parseISO } from "date-fns";

type Props = {
  order: Order;
};

export const OrderTime = ({ order }: Props) => (
  <div className="flex flex-col text-center justify-center items-center">
    <div className="w-10 rounded-full flex">
      <img src="/img/order-icons/calender.svg" alt="." />
    </div>
    <p className="text-base font-medium leading-6 text-gray-500">
      {parseISO(order?.createdAt!).toLocaleString()}
    </p>
  </div>
);
