import { Order } from "@/models/orders";
import React from "react";

type Props = {
  order: Order;
};

export const PaymentItem = ({ order }: Props) => (
  <div className="flex items-center">
    <img className="w-10" src="/img/order-icons/payment.svg" alt="" />
    <div>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="flex flex-row">
          {order.totalPrice} <span>(SDG)</span>{" "}
        </div>
      </div>

      <div className="text-xs text-gray-600">{order.paymentMethod}</div>
    </div>
  </div>
);
