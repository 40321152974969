import { AssistantData } from "@/models/flow";
import { NodeProps, Position } from "react-flow-renderer";
import { BaseHandle, BaseNode } from "../BaseNode";
import { AssistantNodeForm } from "../DialogForms";
import { NodeType } from "../../types";

const DragElement = <BaseNode title="Assistant" color="rgb(0 45 107)" isDragElement />;

const NodeComponent = ({ ...rest }: NodeProps<AssistantData>) => (
  <>
    <BaseHandle type="target" position={Position.Top} />
    <BaseNode
      title="Assistant"
      color="rgb(0 45 107)"
      icon="assistant.svg"
      form={<AssistantNodeForm />}
      {...rest}
    />
    <BaseHandle type="source" position={Position.Bottom} />
  </>
);

export const Assistant: NodeType = {
  type: "assistant",
  component: NodeComponent,
  dragElement: DragElement
};
