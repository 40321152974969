import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { ExampleSet, ExampleSets } from "@/models/example-set";
import { IExampleSetApi } from "./IExampleSetApi";

@injectable()
export class ExampleSetApi implements IExampleSetApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  client: HttpClient;

  private readonly baseUrl = "/example-set";

  async getAllExampleSets(): Promise<ExampleSet[]> {
    const {
      data: { exampleSets }
    } = await this.client.get<ExampleSets>(this.baseUrl);
    return exampleSets;
  }

  async getExampleSetById(id: string): Promise<ExampleSet> {
    const { data } = await this.client.get<ExampleSet>(`${this.baseUrl}/${id}`);
    return data;
  }
}
