import * as yup from "yup";

export const CustomerValidationSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(20, "Maximum lenght 20 chars")
      .min(5, "Minimum length 5 chars"),
    phone: yup.string().max(12, "maximum length 12 digits").min(9, "Minimum length 9 digits"),
    email: yup.string().email("Invalid email"),
    locatopn: yup.string().max(20, "Maximum length 20 chars").min(5, "Minimum length 5 chars")
  });
