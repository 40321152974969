function isJsonString(val: any) {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
}

function isBooleanString(val: any) {
  return val === "true" || val === "false";
}

export function getItemFromLocalStorage(key: string) {
  let value = window.localStorage.getItem(key);

  if (isBooleanString(value)) {
    return value === "true";
  }

  if (isJsonString(value)) {
    value = JSON.parse(value as string);
  }

  return value;
}

export function storeItemInLocalStorage(key: string, item: any) {
  let itemValue = item;
  if (item instanceof Object) {
    itemValue = JSON.stringify(item);
  }
  window.localStorage.setItem(key, itemValue);
}

export function removeItemFromLocalStorage(key: string) {
  window.localStorage.removeItem(key);
}

export function clearAllItemsFromLocalStorage() {
  window.localStorage.clear();
}
