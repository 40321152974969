/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { User } from "@/models/auth";
import { useState } from "react";
import { AccountDetails, AccountSettingsForm } from "./index";

type Props = {
  user: User;
};

export const Account = ({ user }: Props) => {
  const [edit, setEdit] = useState(false);

  return (
    <div className=" flex flex-col">
      <div className="flex flex-row">
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: "#044CAC"
          }}
        >
          Account
        </p>
        <div className="ml-auto">
          <img onClick={() => setEdit(!edit)} src="img/icons/edit.svg" alt="" />
        </div>
      </div>
      {edit ? <AccountSettingsForm user={user!} /> : <AccountDetails user={user!} />}
    </div>
  );
};
