import { FacebookProvider } from "@/services/facebook-sdk";
import { Notifications } from "./components/Notifications";
import { PlatformsMenu } from "./components/PlatformsMenu";
import { ProfileMenu } from "./components/ProfileMenu";

export const Navbar = () => (
  <nav className="relative w-full flex h-20 items-center justify-between z-20 shadow-md bg-primary-900">
    <a href="https://www.enigma-ai.com/">
      <img className=" w-16 bg-primary-900" src="/img/white-strok.png" alt="enigma-logo" />
    </a>
    <div className="pr-10 grow flex justify-end h-full items-center space-x-6 bg-primary-900">
      <FacebookProvider>
        <PlatformsMenu />
      </FacebookProvider>

      <Notifications
        channel="messaging"
        icon={<img className="w-8" src="/img/icons/mailbox-icon.svg" alt="messages" />}
      />
      <Notifications
        channel="general"
        icon={<img className="w-8" src="/img/icons/notification.svg" alt="general_messages" />}
      />

      <div className="mx-7">
        <ProfileMenu />
      </div>
    </div>
  </nav>
);
