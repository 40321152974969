import { inject, injectable } from "inversify";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { User } from "@/models/auth";
import { IVerifyApi } from "./IVerifyApi";

@injectable()
export class verifyApi implements IVerifyApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/conf";

  private readonly key = process.env.REACT_APP_API_SECRET;

  async verifyUser(key: string): Promise<User> {
    const fullUrl = `${this.baseUrl}/verify/${key}?api_secret=${this.key}`;
    const { data } = await this.client.get<User>(fullUrl);
    return data;
  }

  async resetPassword(payload: { password: string; key: string }): Promise<User> {
    const fullUrl = `${this.baseUrl}/reset/${payload.key}?api_secret=${this.key}`;
    const { data } = await this.client.post<User>(fullUrl, payload);
    return data;
  }

  async resetRequest(payload: { email: string }): Promise<User> {
    const fullUrl = `${this.baseUrl}/resetRequest?api_secret=${this.key}`;
    const { data } = await this.client.post<User>(fullUrl, payload);
    return data;
  }
}
