import { TabProvider } from "../context/TabContext";
import { TabBody } from "./TabBody";
import { TabPanel } from "./TabPanel";
import { TabItem } from "../types";
import styles from "./tabs.module.css";

export const TabController = ({ items }: { items: TabItem[] }) => (
  <TabProvider items={items}>
    <div className={styles.tab_container}>
      <TabPanel />

      {items.map((item) => (
        <TabBody key={item.order} tab={item} />
      ))}
    </div>
  </TabProvider>
);
