import { Conversation } from "@/models/chat";
import { MessageInput } from "./MessageInput";
import { ChatWarningToast } from "./ChatWarningToast";
import { checkIfAllowedToSend } from "../utils/send-ability";

export const ChatInput = ({ chat }: { chat?: Conversation }) => {
  const allowSending = checkIfAllowedToSend(chat);
  if (allowSending) return <MessageInput />;
  return <ChatWarningToast />;
};
