import { IOrdersApi } from "@/api/orders";
import { IProductsApi } from "@/api/Products";
import { SERVICES_TYPES, useInjection } from "@/ioc";
import { OrderStatus } from "@/models/orders";
import { Product } from "@/models/Products";
import { useMutation, useQuery } from "react-query";

export const useAllProducts = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useQuery("products", () => ProductsApi.getAllProducts());
};
export const useHideProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("hidden-prod", (productId: string) => ProductsApi.hideProduct(productId));
};
export const useOrders = (skip: number, status?: OrderStatus) => {
  const ordersApi = useInjection<IOrdersApi>(SERVICES_TYPES.ORDERS);
  return useQuery(["orders", skip], () => ordersApi.getOrders(skip, status));
};

export const useDeleteProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("deleted-prod", (productId: string) => ProductsApi.deleteProduct(productId));
};

export const useUpdateProduct = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("u-product", (data: { prod: Product; productId: string }) =>
    ProductsApi.updateProduct(data)
  );
};

export const useUpdateProdWoImg = () => {
  const ProductsApi = useInjection<IProductsApi>(SERVICES_TYPES.PRODUCTS);
  return useMutation("e-product", (data: { prod: any; productId: string }) =>
    ProductsApi.updateProdWoImg(data)
  );
};
